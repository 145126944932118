import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ColumnContentType } from "../../../../types.d";
import moment from "moment";
import { useContext } from "react";
import { useKind } from "../../../windows/thisNode";

interface OpenNewWindowFormatterProps {
  row: ColumnContentType;
}

function OpenNewWindowFormatter({ row }: OpenNewWindowFormatterProps) {
  const date = moment(row.recorded).format("YYYY-MM-DD");
  const kind = useKind();
  const queryUrl = `/${kind}/${row.key}/${date}`;

  return (
    <a
      href={queryUrl}
      target="_blank"
      rel="noreferrer"
      id="open-order-details-css"
    >
      <OpenInNewIcon fontSize="small" sx={{ fontSize: 16 }} />
    </a>
  );
}

OpenNewWindowFormatter.displayName = "standalone:OpenNewWindowFormatter";

export default OpenNewWindowFormatter;
