import { Close } from "@mui/icons-material";
import {
  Stack,
  Alert,
  AlertTitle,
  AlertColor,
  IconButton,
} from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

type AlertMessageProps = {
  title: string;
  severity: AlertColor | undefined;
  children: ReactNode;
  onClose: () => void;
};
const AlertMessage = (props: AlertMessageProps) => {
  return (
    <Alert
      action={
        <IconButton
          disableRipple
          aria-label="close"
          color="inherit"
          size="small"
          onClick={props.onClose}
        >
          <Close fontSize="inherit" />
        </IconButton>
      }
      severity={props.severity}
    >
      <AlertTitle>{props.title}</AlertTitle>
      {props.children}
    </Alert>
  );
};

export default React.memo(AlertMessage);
