import React from "react";
import { ColDefContext, useColDef } from "./utils";
import {CustomChoiceField} from "./CustomChoiceField";

export function CustomBooleanField() {
  const def = useColDef();

  return (
    <ColDefContext.Provider value={{
      ...def,
      type: "choice",
      choices: {
        "true": "true",
        "false": "false",
      },
    }}>
      <CustomChoiceField singleSelect />
    </ColDefContext.Provider>
  );
}
