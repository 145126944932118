type DataType = {
  fixMsgIn?: string;
  fixMsgOut?: string;
  efixReceived?: string;
};
export type CleanDataType = {
  [k: string]: {
    tag: string;
    fixMsgIn?: string;
    fixMsgOut?: string;
    efixReceived?: string;
  };
};

const convertToMap = (key: string, data: string, cleanData: CleanDataType) => {
  if (data !== null && data !== "") {
    const convertToArr = data.split("|");

    convertToArr.forEach((element) => {
      const [k, value] = element.split("=");
      cleanData = {
        ...cleanData,
        [k]: { ...cleanData[k], [key]: value, tag: k },
      };
    });

    return cleanData;
  }
};
const cleanFixMsgData = (obj: DataType) => {
  let cleanData: CleanDataType = {};
  Object.keys(obj).map((key) => {
    cleanData = { ...cleanData, ...convertToMap(key, obj[key], cleanData) };
  });
  return cleanData;
};

export default cleanFixMsgData;
