import React, {
  ChangeEvent,
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { alpha, useTheme } from "@mui/material/styles";
import { Box, Typography, Backdrop, CircularProgress } from "@mui/material";
import DataGrid, { RowsChangeData, SortColumn } from "react-data-grid";
import {
  useFetcher,
  useSetResult,
  useArgs,
  useSetTotalCount,
  useTotalCount,
  UseFetcherBaseUrls,
  ArgumentType,
} from "../../hooks/useQuery";
import { useToken } from "../../hooks/useToken";
import CheckboxFormatter from "./formatter/CheckboxFormatter";
import NoRowsFallBack from "./NoRowsFallBack";
import {
  useSelectedRows,
  useSetSelectedRows,
} from "../../hooks/useSelectedRows";
import CustomRowRenderer from "./CustomRowRenderer";
import { useColumnsForDatagrid } from "../../hooks/useColumns";
import SortIcon from "./formatter/SortIcon";
import useFetchByTradeDetailsData from "../../hooks/order-details-hooks/useFetchTradeDetailsData";
import useFetchRiskData from "../../hooks/order-details-hooks/useFetchRiskData";
import { CreateRunnerGroup } from "../../hooks/useRunner";
import moment from "moment";
import { AllDefs } from "../../hooks/columndefs";
import { ShowHeadersFilterProvider } from "./CustomHeaderRenderer";
import { useKind } from "../../windows/thisNode";
import { RowCount } from "../common/ControlRow";
import { useLocalState } from "../../hooks/useLocalState";

const ROW_HEIGHT = 28;

interface SummaryRow {
  id: string;
  totalCount: number;
}

type ColumnContentType = Record<string, any>;

export type CustomDataGridProps = {
  path: string;
  showHeaderFilters: boolean;
  parseList: (obj: object) => ColumnContentType[];
  parseTotal: (obj: object) => number;
  enableColumnPrefix: boolean;
  transformArgs?: (
    x: Record<string, ArgumentType>
  ) => Record<string, ArgumentType>;
  baseUrl?: UseFetcherBaseUrls;
};

const [FetchThisGridProvider, useRegisterThisGrid, useFetchThisGrid] =
  CreateRunnerGroup("datagrid_fetch");

const [ResetThisGridProvider, useRegisterResetThisGrid, useResetThisGrid] =
  CreateRunnerGroup("datagrid_reset");

export {
  ResetThisGridProvider,
  useResetThisGrid,
  FetchThisGridProvider,
  useFetchThisGrid,
  useRegisterResetThisGrid,
};

type Comparator = (a: ColumnContentType, b: ColumnContentType) => number;

const getComparator = (sortColumn: string): Comparator => {
  const def = AllDefs.find((x) => x.rdgColumn.key === sortColumn);

  const comp =
    (handle: (x: string, y: string) => number): Comparator =>
    (a: ColumnContentType, b: ColumnContentType) => {
      if (
        a[sortColumn] != (undefined || null) &&
        b[sortColumn] != (undefined || null)
      ) {
        return handle(a[sortColumn], b[sortColumn]);
      }
      return 0;
    };

  if (def) {
    switch (def.type) {
      case "number":
      case "money":
        return comp((a, b) => parseFloat(a) - parseFloat(b));

      case "autocomplete":
      case "choice":
      case "text":
        return comp((a, b) => a.localeCompare(b));

      case "datetime":
      case "time":
      case "date":
        return comp((a, b) => {
          const da = moment(a);
          const db = moment(b);

          return da.diff(db);
        });

      case "boolean":
        return comp((a, b) => {
          return a ? 1 : -1;
        });

      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  } else {
    throw new Error(`column does not exist: "${sortColumn}"`);
  }
};

function CustomDataGrid(props: CustomDataGridProps) {
  const [mdLoader, setMdLoader] = useState(false); // md = master details
  const [data, setData] = useState<Array<ColumnContentType>>([]);
  const contextArgs = useArgs();
  const selectedRows = useSelectedRows();
  const setSelectedRows = useSetSelectedRows();
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const totalCount = useTotalCount();
  const setTotalCount = useSetTotalCount();
  const setResult = useSetResult();
  const kind = useKind();

  const offset = useRef(0);

  const [lastTimeUpdated, setLastTimeUpdated] = useLocalState(
    "Not available",
    "lastTimeUpdated"
  );

  useEffect(() => {
    offset.current = 0;
  }, [contextArgs]);

  const fetcher = useFetcher(props.baseUrl);

  const fetchData = useCallback(
    async (more = false) => {
      if (fetcher) {
        if (!more) {
          offset.current = 0;
        }

        setMdLoader(true);

        var goodArgs: Record<string, ArgumentType> = {
          offset: [`${offset.current}`],
          ...contextArgs,
        };

        if (props.transformArgs) {
          goodArgs = props.transformArgs(goodArgs);
        }

        goodArgs = Object.fromEntries(
          Object.entries(goodArgs).map(([key, value]) => {
            const overrideDef = AllDefs.find((x) => x.rdgColumn.key === key);

            if (!overrideDef) {
              return [key, value];
            }
            return [overrideDef?.apiKeyOverride?.[kind] ?? key, value];
          })
        );

        try {
          const resp: object | undefined = await fetcher(props.path, goodArgs);
          if (resp) {
            const data = props.parseList(resp);
            offset.current += data.length;

            const change = (
              prev: ColumnContentType[] | undefined
            ): ColumnContentType[] => {
              if (prev && more) {
                var deleted: ColumnContentType[] = [];
                if (offset.current < prev.length) {
                  deleted = prev.splice(offset.current, data.length, data);
                }
                if (deleted.length > 0) {
                  prev = prev.concat(data.slice(0, deleted.length));
                } else {
                  prev = prev.concat(data);
                }
                return prev;
              }
              return data;
            };

            setData(change);
            setResult(change);
            setTotalCount(props.parseTotal(resp));
          }
        } catch (e) {
          console.error("UNABLE TO FETCH", e);

          setData([]);
          setResult([]);
          setTotalCount(0);
        }

        setLastTimeUpdated(moment().format("YYYY/MM/DD hh:mm:ss A"));
        setMdLoader(false);
      }
    },
    [
      kind,
      setData,
      setMdLoader,
      setTotalCount,
      fetcher,
      props,
      offset,
      contextArgs,
      setLastTimeUpdated,
      setResult,
    ]
  );

  useRegisterThisGrid(() => fetchData());
  useRegisterResetThisGrid(() => {
    setData([]);
    setSelectedRows(new Set<React.Key>());
  });

  const columns = useColumnsForDatagrid(props.enableColumnPrefix);

  const fetchTD = useFetchByTradeDetailsData();
  const fetchRD = useFetchRiskData();
  // Handle Expend Master Details Row When Clicked On THe Arrow Button
  const handleExpendedMasterDetailRow = useCallback(
    async (
      indexes: number[],
      currentRow: ColumnContentType,
      rows: ColumnContentType[]
    ) => {
      if (!currentRow.expanded) {
        rows.splice(indexes[0] + 1, 1);
        setData(rows);
      } else {
        setMdLoader(true);
        const data = await Promise.all([
          fetchTD({
            key: currentRow.key,
            timestamp: currentRow.lastChange,
          }),
          fetchRD({
            orderId: currentRow.id,
            timestamp: currentRow.lastChange,
          }),
        ]);

        if (data) {
          const cRow = Object.assign(
            {},
            ...columns.map(({ key }) => ({ [key]: currentRow[key] }))
          );

          rows.splice(indexes[0] + 1, 0, {
            ...cRow,
            type: "DETAIL",
            expanded: true,
            riskData: data[1].values,
            tradeDetailsData: data[0].values,
          });
          setData(rows);
          setMdLoader(false);
        }
      }
    },
    [setMdLoader, setData, columns, fetchRD, fetchTD]
  );

  // Used for Master Details Row. Add a new row.
  const onRowsChange = useCallback(
    async (rows: ColumnContentType[], changes: RowsChangeData<any>) => {
      const { indexes } = changes;
      const currentRow = rows[indexes[0]];

      switch (currentRow.columnKey) {
        case "expanded":
          handleExpendedMasterDetailRow(indexes, currentRow, rows);
          break;
        default:
          console.error("Something wrong in onRowsChange function");
      }
    },
    [handleExpendedMasterDetailRow]
  );

  /* 
    Perform sorting
    When you click on the header section of the grid it call sortColumns 
    from which it will get the current column and sort it
  */
  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return data;
    return [...data].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [data, sortColumns]);

  // Grid Summary: Check Last Row(is pinned) of the grid
  const summaryRows = useMemo(() => {
    const summaryRow: SummaryRow = {
      id: "total_0",
      totalCount: data.length,
    };
    return [summaryRow];
  }, [data]);

  const lastRow = useRef<number>(-1);

  const handleScroll = useCallback(
    (e: ChangeEvent) => {
      const rowCurrentlyOnTop = Math.ceil(e.target.scrollTop / ROW_HEIGHT);
      if (
        data.length > 0 &&
        data.length < totalCount &&
        rowCurrentlyOnTop > data.length - 50 &&
        rowCurrentlyOnTop > lastRow.current &&
        !mdLoader
      ) {
        fetchData(true);
      }
      lastRow.current = rowCurrentlyOnTop;
    },
    [fetchData, data, lastRow, totalCount, mdLoader]
  );

  const theme = useTheme();
  const themeMode = theme.palette.mode;

  return (
    <>
      <Backdrop
        sx={{
          position: "absolute",
          color: theme.palette.primary.main,
          zIndex: 1,
        }}
        open={mdLoader}
      >
        <CircularProgress size={20} />
      </Backdrop>

      <ShowHeadersFilterProvider show={props.showHeaderFilters}>
        <DataGrid
          rowHeight={(args) =>
            args.type === "ROW" && args.row.type === "DETAIL" ? 180 : ROW_HEIGHT
          }
          headerRowHeight={props.showHeaderFilters ? 60 : ROW_HEIGHT}
          className={`rdg-${themeMode}`}
          rowKeyGetter={(row) => row.key}
          columns={columns}
          rows={sortedRows}
          selectedRows={selectedRows}
          onSelectedRowsChange={(selectedRows) => {
            setSelectedRows(selectedRows);
          }}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          style={{
            fontSize: "0.85rem",
            width: "inherit",
            height: "calc(100% - 20px)",
          }}
          enableVirtualization={true}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          onRowsChange={onRowsChange}
          bottomSummaryRows={summaryRows}
          renderers={{
            sortIcon: (props) => <SortIcon {...props} />,
            checkboxFormatter: () => <CheckboxFormatter />,
            rowRenderer: (key, props) => (
              <CustomRowRenderer key={key} {...props} />
            ),
            noRowsFallback: <NoRowsFallBack width="100vw" />,
          }}
          onScroll={handleScroll as any}
        />
      </ShowHeadersFilterProvider>
      <Box
        sx={{
          display: "flex",
          height: "20px",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 0 0 8px",
          background: theme.palette.mode === "dark" ? "#151718" : "#fff",
          borderTop: `1px solid ${alpha(theme.palette.success.main, 0.1)}`,
        }}
      >
        <RowCount />

        <Typography sx={{ padding: "0 8px", fontSize: "12px" }}>
          Last updated: {lastTimeUpdated}
        </Typography>
      </Box>
    </>
  );
}

CustomDataGrid.displayName = "standalone:CustomDataGrid";

export default CustomDataGrid;
