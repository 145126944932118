import React from "react";
import { QueryProvider } from "../hooks/useQuery";
import { FirmMapProvider } from "../hooks/useFirmMappings";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DataGridColumnsProvider } from "../hooks/useColumns";
import { SelectedRowsProvider } from "../hooks/useSelectedRows";
import { CancelModalProvider } from "../modal/cancelOrders";
import { ManageInputModalProvider } from "../modal/manageInput";
import {
  GlobalStateProvider,
  FetchAllGridsGroup,
} from "../hooks/useGlobalState";
import { FixMessageModalProvider } from "../modal/FixMessageModal";
import { ShowInputFieldProvider } from "../hooks/useExpanded";
import { DragNDropColsModalProvider } from "../modal/dnd-column-reorder/DragNDropColsModal";
import { ThemeProvider } from "@mui/material/styles";
import { UserProvider } from "@auth0/nextjs-auth0";
import { FixDictProvider } from "../hooks/useFixMsgDictData";
import { SymbolLookupProvider } from "../hooks/useSymbolLookup";
import { MassCancelModalProvider } from "../modal/massCancel";
import { SetOfColumnDefs } from "../../types.d";
import { ResetThisGridProvider } from "../body/grid/CustomDataGrid";
import { WorkspaceProvider } from "../hooks/useWorkspaces";
import { ClearingGroupsProvider } from "../hooks/useClearingGroups";
import { BuyingPowerGroupsProvider } from "../hooks/useBuyingPowerGroups";
import { AutoRefreshProvider } from "../hooks/datetimerangepicker-hook/useAutoRefresh";
import { AccountsProvider } from "../hooks/useAccounts";
import { RoutesMapProvider } from "../hooks/useRouteMappings";

export function UncontrolledProviders(
  props: React.PropsWithChildren<{ theme: any }>
) {
  return (
    <ThemeProvider theme={props.theme}>
      <UserProvider>
        <FixDictProvider>
          <RoutesMapProvider>
            <AccountsProvider>
              <MassCancelModalProvider>
                <FirmMapProvider>
                  <FetchAllGridsGroup>
                    <WorkspaceProvider>
                      <ClearingGroupsProvider>
                        <BuyingPowerGroupsProvider>
                          {props.children}
                        </BuyingPowerGroupsProvider>
                      </ClearingGroupsProvider>
                    </WorkspaceProvider>
                  </FetchAllGridsGroup>
                </FirmMapProvider>
              </MassCancelModalProvider>
            </AccountsProvider>
          </RoutesMapProvider>
        </FixDictProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export function ControlledProvider(
  props: React.PropsWithChildren<{ columnSet: SetOfColumnDefs }>
) {
  return (
    <QueryProvider>
      <DataGridColumnsProvider columnSet={props.columnSet}>
        <DndProvider backend={HTML5Backend}>
          <DragNDropColsModalProvider>
            <SymbolLookupProvider>
              <GlobalStateProvider>
                <ShowInputFieldProvider>
                  <SelectedRowsProvider>
                    <CancelModalProvider>
                      <FixMessageModalProvider>
                        <ManageInputModalProvider>
                          <ResetThisGridProvider>
                            <AutoRefreshProvider>
                              {props.children}
                            </AutoRefreshProvider>
                          </ResetThisGridProvider>
                        </ManageInputModalProvider>
                      </FixMessageModalProvider>
                    </CancelModalProvider>
                  </SelectedRowsProvider>
                </ShowInputFieldProvider>
              </GlobalStateProvider>
            </SymbolLookupProvider>
          </DragNDropColsModalProvider>
        </DndProvider>
      </DataGridColumnsProvider>
    </QueryProvider>
  );
}
