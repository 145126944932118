import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

type FlexWrapperProps = {
  title?: string;
  divider?: boolean;
  expanded?: boolean;
};

export function FlexWrapper(props: React.PropsWithChildren<FlexWrapperProps>) {
  const [expanded, setExpanded] = useState(props.expanded);
  return (
    <>
      {props.divider ? <Divider /> : null}
      {props.title ? (
        <Typography variant="h6" sx={{ textAlign: "left", fontSize: 14 }}>
          <IconButton onClick={() => setExpanded((x) => !x)}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          {props.title}
        </Typography>
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          flexWrap: "wrap",
          gap: 0.3,
          width: "100%",
          mx: 3,
          my: 1,
        }}
      >
        {expanded ? props.children : null}
      </Box>
    </>
  );
}
