import React, { useState, useMemo, createContext, useContext } from "react";
import * as FlexLayout from "flexlayout-react";
import { AvaliableTabs } from "../../types.d";
import { isValidKindOfTab } from "../../types";

const ThisNodeContext = createContext<FlexLayout.Node | undefined>(undefined);
const ThisKindContext = createContext<AvaliableTabs | undefined>(undefined);

export function useThisNode(): FlexLayout.Node | undefined {
  return useContext(ThisNodeContext);
}

export type ThisNodeProviderProps = { node: FlexLayout.Node };

export function ThisNodeProvider(
  props: React.PropsWithChildren<ThisNodeProviderProps>
) {
  return (
    <ThisNodeContext.Provider value={props.node}>
      {props.children}
    </ThisNodeContext.Provider>
  );
}

export function ThisKindProvider(
  props: React.PropsWithChildren<{ kind: AvaliableTabs }>
) {
  return (
    <ThisKindContext.Provider value={props.kind}>
      {props.children}
    </ThisKindContext.Provider>
  );
}

export function useKind(): AvaliableTabs {
  const node = useThisNode();
  const kind = useContext(ThisKindContext);

  const x = (node as any)?._attributes?.component ?? kind;

  if (!x) {
    throw "unknown kind";
  }

  if (isValidKindOfTab(x)) {
    return x;
  } else {
    throw "weird kind: " + x;
  }
}
