import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

interface CellExpanderFormatterProps {
  expanded: boolean;
  onCellExpand: () => void;
}

function CellExpanderFormatter({
  expanded,
  onCellExpand,
}: CellExpanderFormatterProps) {
  return (
    <button onClick={onCellExpand} id="btn-cell-expander">
      {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
    </button>
  );
}

CellExpanderFormatter.displayName = "standalone:CellExpanderFormatter";

export default CellExpanderFormatter;
