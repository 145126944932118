import {
  IconButton,
  Typography,
  Stack,
  Menu,
  MenuItem,
  Tooltip,
  alpha,
  useTheme,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import BlockIcon from "@mui/icons-material/Block";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  createRef,
} from "react";
import {
  ArgumentType,
  useArgs,
  UseQueryArgs,
  useResetArgs,
  useResult,
  useTotalCount,
} from "../../hooks/useQuery";
import Search from "@mui/icons-material/Search";
import { Autorenew, FileDownload, ReportOff } from "@mui/icons-material";
import { fieldBorderRadius } from "../../../styles/globalVar";
import { ExportToCSV, ExportToExcel } from "../../../utils/exportUtils";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useShowDragNDropColsModal } from "../../modal/dnd-column-reorder/DragNDropColsModal";
import { useShowCancelModal } from "../../modal/cancelOrders";
import {
  useSelectedResults,
  useSelectedRows,
} from "../../hooks/useSelectedRows";
import {
  defaultEndDate,
  defaultStartDate,
  useSetDateTimeRPData,
} from "../../hooks/datetimerangepicker-hook/useDateTimeRangePicker";
import { useFetchThisGrid, useResetThisGrid } from "../grid/CustomDataGrid";
import getConfig from "next/config";
import AutoRefreshGridBtn from "../grid/AutoRefreshGridBtn";
import { useLocalState } from "../../hooks/useLocalState";
import { useAddTab } from "../../windows/useAddTab";
import {
  DEFAULT_REFRESH_TIME,
  useAutoRefreshData,
  useSetAutorefreshData,
} from "../../hooks/datetimerangepicker-hook/useAutoRefresh";

type AutoRefreshButtonProps = {
  disabled?: (
    args: Partial<{
      [x: string]: ArgumentType;
    }>
  ) => boolean;
};

function CustomIconButton(props: {
  id: string;
  icon: React.FunctionComponent<any>;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  color: string;
  tooltip: string;
  disabled?: boolean;
}) {
  const theme = useTheme();
  const Icon = props.icon;
  return (
    <Tooltip title={props.tooltip}>
      <IconButton
        id={props.id}
        disableTouchRipple
        size="small"
        disabled={props.disabled}
        color={props.color as any}
        onClick={props.onClick}
        style={{
          height: 24,
          width: 28,
          borderRadius: 3,
          margin: 2,
          background: alpha(theme.palette[props.color].main, 0.1)
        }}
      >
        <Icon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

export function CancelSelectedOrdersButton() {
  const show = useShowCancelModal({});
  const selectedRows = useSelectedRows();

  const { publicRuntimeConfig } = getConfig();
  if (!publicRuntimeConfig.enable_cancel) {
    return <></>;
  }

  return (
    <CustomIconButton
      id="btn-cancel-selected-order"
      color="error"
      icon={BlockIcon}
      onClick={show}
      tooltip="Cancel selected"
      disabled={selectedRows.size == 0}
    />
  );
}

export function useResetAll() {
  const resetArgs = useResetArgs();
  const resetGrid = useResetThisGrid();
  const resetDateTimeRPData = useSetDateTimeRPData();
  const resetAutoRefreshData = useSetAutorefreshData();

  return () => {
    resetArgs();
    resetGrid();
    resetDateTimeRPData({
      defaultDateTimeLabel: "Today",
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    });
    resetAutoRefreshData({
      active: false,
      seconds: DEFAULT_REFRESH_TIME,
    });
  };
}

export function RestartButton() {
  const resetAll = useResetAll();

  return (
    <CustomIconButton
      id="btn-reset"
      tooltip="Reset"
      color="warning"
      icon={RestartAltIcon}
      onClick={resetAll}
    />
  );
}

type SearchButtonProps = {
  disabled?: (args: UseQueryArgs) => boolean;
};

export function SearchButton(props: SearchButtonProps) {
  const doFetch = useFetchThisGrid();
  const args = useArgs();

  const disabled = useMemo(() => {
    if (props.disabled) {
      return props.disabled(args);
    } else {
      return false;
    }
  }, [args, props.disabled]);

  return (
    <CustomIconButton
      id="btn-search"
      icon={Search}
      color="success"
      onClick={doFetch}
      tooltip="Search"
      disabled={disabled}
    />
  );
}

export function RowCount() {
  const totalCount = useTotalCount();
  const data = useResult();

  return (
    <Typography variant="caption">
      {data.length} / {totalCount} #Rows
    </Typography>
  );
}

export function ExportMenu() {
  const [anchorEl, setAnchorEl] = useState<any>();
  const handleOpenExportMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = anchorEl ? true : false;

  const handleExportToExel = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomIconButton
        id="btn-export"
        tooltip="Export"
        icon={FileDownload}
        color="info"
        onClick={handleOpenExportMenu}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          sx: {
            padding: 0,
          },
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 2,
          sx: { borderRadius: fieldBorderRadius },
        }}
      >
        <MenuItem
          sx={{ fontSize: 12, padding: "0 8px" }}
          dense={true}
          onClick={() => setAnchorEl(null)}
        >
          <ExportToCSV />
        </MenuItem>
        <MenuItem
          sx={{ fontSize: 12, padding: "0 8px" }}
          dense={true}
          onClick={handleExportToExel}
        >
          <ExportToExcel />
        </MenuItem>
      </Menu>
    </>
  );
}

export function ChooseColumns() {
  const showDropNDropColumnModal = useShowDragNDropColsModal({});

  return (
    <CustomIconButton
      id="btn-choose-column"
      color="success"
      onClick={showDropNDropColumnModal}
      icon={FilterListIcon}
      tooltip="Choose columns"
    />
  );
}

export function RowStacks(props: {
  leftRowStack: React.ReactNode;
  rightRowStack: React.ReactNode;
}) {
  const theme = useTheme();
  const ref = createRef<HTMLElement>();
  const [overflowActive, setOverflowActive] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);

  useEffect(() => {
    if (ref.current) {
      if (ref.current?.clientWidth < ref.current?.scrollWidth) {
        setOverflowActive(true);
        return;
      }
    }
    setOverflowActive(false);
  }, [ref]);

  return (
    <Stack
      ref={ref}
      sx={{
        background: theme.palette.mode === "dark" ? "#151718" : "#fff",
        borderBottom: `1px solid ${alpha(theme.palette.success.dark, 0.15)}`,
        height: "30px",
        overflowY: "hidden",
        overflowX: "overlay",
        // for chrome
        "::-webkit-scrollbar": {
          height: "4px",
          display: mouseHover && overflowActive ? "block" : "none",
        },
        "::-webkit-scrollbar-track": {
          display: "none",
        },
        // for firefox
        scrollbarWidth: "thin",
      }}
      direction="row"
      alignContent="center"
      alignItems="center"
      justifyContent="space-between"
      onMouseOver={() => setMouseHover(true)}
      onMouseOut={() => setMouseHover(false)}
    >
      <Stack alignItems="center" direction="row">
        {props.leftRowStack}
      </Stack>
      <Stack alignItems="center" direction="row">
        {props.rightRowStack}
      </Stack>
    </Stack>
  );
}

export function AutoRefreshButton({ disabled }: AutoRefreshButtonProps) {
  const doFetch = useFetchThisGrid();
  const autorefresh = useAutoRefreshData();
  const setAutoRefreshData = useSetAutorefreshData();

  const handleSelectSeconds = useCallback(
    (seconds: number) => {
      setAutoRefreshData((prev) => {
        if (prev) {
          return {
            ...prev,
            seconds,
          };
        }
      });
    },
    [autorefresh.seconds]
  );

  const handleSetAutoRefresh = useCallback(
    (active: boolean) => {
      if (active) {
        doFetch();
      }
      setAutoRefreshData((prev) => {
        if (prev) {
          return {
            ...prev,
            active,
          };
        }
      });
    },
    [doFetch, autorefresh.active]
  );

  return (
    <AutoRefreshGridBtn
      disabled={disabled}
      autorefresh={autorefresh}
      handleSetAutoRefresh={handleSetAutoRefresh}
      handleSelectSeconds={handleSelectSeconds}
    />
  );
}

export function CancelReplaceButton() {
  const selected = useSelectedResults();
  const addTab = useAddTab();

  const handleClick = useCallback(() => {
    if (selected.length === 1) {
      const order = selected[0];
      addTab("tradeEntry", "", {
        original: order,
        cancel_replace: true,
        arguments: {
          accountName: [order["account"]],
          symbol: [order["symbol"]],
          orderType: [order["orderType"].toString().toLowerCase()],
          side: [order["side"]],
          timeInForce: [order["timeInForce"].toString().toUpperCase()],
          route: [order["route"].toString().toUpperCase()],
          quantity: "eq:" + order["quantity"],
          price: "eq:" + order["price"],
        },
      });
    } else {
      throw "don't know what to replace";
    }
  }, [selected]);

  if (selected.length === 1) {
    return (
      <CustomIconButton
        id="btn-cancel-and-replace"
        color="warning"
        onClick={handleClick}
        icon={PublishedWithChangesIcon}
        tooltip="Cancel and replace"
      />
    );
  } else {
    return <></>;
  }
}
