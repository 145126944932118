import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import { SettingsRounded } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useAddTab } from "./useAddTab";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { AvaliableTabs, LookupTabName } from "../../types.d";
import { isValidKindOfTab } from "../../types";
import { useIsEmployee } from "../hooks/useAccounts";

const CreateNewTab = (props) => {
  const [anchorElSetting, setAnchorElSetting] = useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorElSetting);
  const addTab = useAddTab();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSetting(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElSetting(null);
  };

  const handleClickAddTab = (tabName: AvaliableTabs) => {
    addTab(tabName, props.tabSetId);
    setAnchorElSetting(null);
  };

  const isEmployee = useIsEmployee();
  const avaliableTabs: { [x: string]: string } = { ...LookupTabName };

  if (!isEmployee) {
    delete avaliableTabs.efixGrep;
  }

  return (
    <>
      <Tooltip title="Create">
        <IconButton
          id="tab-create"
          disableTouchRipple
          size="small"
          sx={{ borderRadius: 0 }}
          onClick={handleClick}
        >
          {props.type === "sticky" ? (
            <AddRoundedIcon fontSize="small" />
          ) : (
            <SettingsRounded fontSize="small" sx={{ fontSize: 15 }} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorElSetting}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { p: 0 },
        }}
        PaperProps={{
          elevation: 2,
          style: {
            padding: "0px",
            marginLeft: "16px",
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {Object.entries(avaliableTabs).map(([kind, name], i) => (
          <MenuItem
            id={`li-${name.split(" ").join("-").toLocaleLowerCase()}`}
            key={i}
            dense={true}
            disableGutters
            disableTouchRipple
            onClick={() =>
              isValidKindOfTab(kind) ? handleClickAddTab(kind) : null
            }
            sx={{ p: 0, pl: 1, pr: 1, fontSize: 12 }}
          >
            <AddIcon fontSize="small" sx={{ fontSize: 16 }} />
            &nbsp;{name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CreateNewTab;
