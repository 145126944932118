import React, { useEffect } from "react";
import { CreateMappingProvider } from "./autocompleteMapper";

const [LastMarketMapProvider, useLastMarketMap, LastMarketMappingContext] =
  CreateMappingProvider("/v2/venues", (body: string[]) => {
    const unique = Array.from(new Set(body).values());
    return unique.reduce(
      (obj, val) => ({
        ...obj,
        [val]: `${val}`,
      }),
      {}
    );
  });

export { LastMarketMapProvider, useLastMarketMap, LastMarketMappingContext };
