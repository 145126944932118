import React, { useState, useMemo, createContext, useContext } from "react";
import * as FlexLayout from "flexlayout-react";
import {
  BorderNode,
  ITabRenderValues,
  ITabSetRenderValues,
  Model,
  TabNode,
  TabSetNode,
} from "flexlayout-react";
import CreateNewTabButton from "./CreateNewTabButton";
import {
  CloseTabIconButton,
  CloseTabSetIconButton,
  FullScreenButton,
  EditNameButton,
} from "./LayoutIconButtons";
import { AvaliableTabs } from "../../types.d";
import { nanoid } from "nanoid";
import TabContextMenu from "./TabContextMenu";
import { CoreWrapper } from "./coreWrapper";
import { OrdersEventsCore } from "./OrdersEventsCore";
import { BuyingPowerCore } from "./BuyingPowerCore";
import {
  SetOfColumnsForEvents,
  SetOfColumnsForOrders,
  SetOfColumnDefsForBuyingPower,
  SetOfColumnDefsForPositions,
} from "../hooks/columndefs";
import { PositionsCore } from "./PositionsCore";
import { TradeEntryCore } from "./TradeEntryCore";
import {
  useSetWorkspaceModel,
  useWorkspaceModel,
} from "../hooks/useWorkspaces";
import { EfixGrepCore } from "./EfixGrepCore";
import ComplianceQueryTool from "./ComplianceQueryTool";

function componentFactory(node: FlexLayout.TabNode): React.ReactNode {
  const name = node.getComponent();
  console.log("componentFactory", name, node);

  switch (name as AvaliableTabs) {
    case "orders":
      return (
        <CoreWrapper
          mode="flexlayout"
          node={node}
          columnSet={SetOfColumnsForOrders}
        >
          <OrdersEventsCore mode="orders" />
        </CoreWrapper>
      );
    case "events":
      return (
        <CoreWrapper
          mode="flexlayout"
          node={node}
          columnSet={SetOfColumnsForEvents}
        >
          <OrdersEventsCore mode="events" />
        </CoreWrapper>
      );
    case "buyingpower":
      return (
        <CoreWrapper
          mode="flexlayout"
          node={node}
          columnSet={SetOfColumnDefsForBuyingPower}
        >
          <BuyingPowerCore />
        </CoreWrapper>
      );
    case "positions":
      return (
        <CoreWrapper
          mode="flexlayout"
          node={node}
          columnSet={SetOfColumnDefsForPositions}
        >
          <PositionsCore />
        </CoreWrapper>
      );
    case "tradeEntry":
      return (
        <CoreWrapper
          mode="flexlayout"
          node={node}
          columnSet={SetOfColumnsForOrders}
        >
          <TradeEntryCore />
        </CoreWrapper>
      );
    case "efixGrep":
      return (
        <CoreWrapper
          columnSet={SetOfColumnsForOrders}
          node={node}
          mode="flexlayout"
        >
          <EfixGrepCore />
        </CoreWrapper>
      );
    case "complianceQueryTool":
      return (
        <CoreWrapper mode="flexlayout"
          node={node}
          columnSet={SetOfColumnDefsForBuyingPower}
        >
          <ComplianceQueryTool />
        </CoreWrapper>
      );
    default:
      throw "weird name " + name;
  }
}

const LayoutContext = createContext<FlexLayout.Layout | undefined>(undefined);

export function useLayout() {
  return useContext(LayoutContext);
}

export function LayoutController(props: React.PropsWithChildren) {
  // if (!process.browser) {
  //   return <></>;
  // }

  const modelJson = useWorkspaceModel();
  const setModel = useSetWorkspaceModel();

  const model: FlexLayout.Model = useMemo(() => {
    return FlexLayout.Model.fromJson(modelJson);
  }, [modelJson]);

  const [layoutRef, setLayoutRef] = useState<FlexLayout.Layout>();

  const handleOnRenderTab = (node: TabNode, renderValues: ITabRenderValues) => {
    renderValues.content = <TabContextMenu node={node} />;

    renderValues.buttons = [
      <CloseTabIconButton key={node.getId()} node={node} />,
      <EditNameButton key={node.getId()} node={node} />,
    ];
  };

  const handleOnRenderTabSet = (
    node: TabSetNode | BorderNode,
    renderValues: ITabSetRenderValues
  ) => {
    renderValues.buttons.push(
      <CreateNewTabButton
        type="fixed"
        key={nanoid(8)}
        tabSetId={node.getId()}
      />
    );

    renderValues.stickyButtons.push(
      <CreateNewTabButton
        type="sticky"
        key={nanoid(8)}
        tabSetId={node.getId()}
      />
    );

    const singleNode = model.getRoot().getChildren().length === 1;
    let nodeType = model.getRoot().getChildren()[0].getType();

    if (
      model.getRoot().getChildren().length > 1 ||
      (singleNode && nodeType === "row")
    ) {
      renderValues.buttons.push(
        <FullScreenButton key={nanoid(8)} node={node} />
      );
      renderValues.buttons.push(
        <CloseTabSetIconButton key={nanoid(8)} node={node} />
      );
    }
  };

  const handleOnModelChange = (model: Model) => {
    setModel(model.toJson());
  };

  return (
    <LayoutContext.Provider value={layoutRef}>
      {layoutRef ? props.children : null}
      <FlexLayout.Layout
        ref={(r) => {
          if (r) {
            setLayoutRef(r);
          } else {
            console.error("No reference");
          }
        }}
        factory={componentFactory}
        model={model}
        font={{ size: "12px" }}
        onRenderTab={handleOnRenderTab}
        onRenderTabSet={handleOnRenderTabSet}
        onModelChange={handleOnModelChange}
        supportsPopout
        icons={{
          close: "",
        }}
      />
    </LayoutContext.Provider>
  );
}
