import { Snackbar, Alert, Grow, AlertColor } from "@mui/material";
import {
  useSetShowNotification,
  useShowNotification,
} from "./hooks/useGlobalState";

const CustomSnackbar: React.FC = () => {
  const notification = useShowNotification();
  const setShowNotification = useSetShowNotification();

  const handleOnClose = () => {
    setShowNotification({
      anchorOrigin: notification?.anchorOrigin,
      open: false,
      message: notification?.message as string,
      type: notification?.type as AlertColor,
    });
  };

  return (
    <Snackbar
      anchorOrigin={notification?.anchorOrigin}
      open={notification?.open}
      autoHideDuration={notification?.duration ?? 800}
      onClose={handleOnClose}
      sx={{ zIndex: 3000}}
      TransitionProps={{
        style: { padding: "0 8px" },
      }}
    >
      <Alert
        onClose={handleOnClose}
        variant="filled"
        severity={notification?.type}
      >
        {notification?.message}
      </Alert>
    </Snackbar>
  );
};

CustomSnackbar.displayName = "standalone:CustomSnackbar";

export default CustomSnackbar;
