import getConfig from "next/config";
import { useEffect } from "react";
import { CreateStateContext } from "./stateContext";
import { useToken } from "./useToken";
import { useFetcher } from "./useQuery";

const [FixMsgDictProvider, useFixMsgDict, useSetFixMsgDict] =
  CreateStateContext<{ [fix: number]: string }>("Fix Msg Dict", {});

export { useFixMsgDict };

export function FixMsgDictLoader() {
  const fetcher = useFetcher();
  const set = useSetFixMsgDict();

  useEffect(
    function () {
      (async () => {
        if (fetcher) {
          const body = await fetcher<{ [x: number]: string }>(
            `/v2/fix-dictionary`
          );
          set(body);
        }
      })();
    },
    [fetcher, set]
  );
  return <></>;
}

export function FixDictProvider(props: React.PropsWithChildren) {
  return (
    <FixMsgDictProvider>
      <FixMsgDictLoader />
      {props.children}
    </FixMsgDictProvider>
  );
}
