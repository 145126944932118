import { Chip, Avatar, Typography, useTheme } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import { useChangeColumns } from "../../hooks/useColumns";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useCallback } from "react";
// import usePinnedColumns from "../../hooks/grid-action-hooks/usePinnedColumns";
// import useReorderColumns from "../../hooks/grid-action-hooks/useReorderColumns";
import { LimeColumn } from "../../../types.d";

const DnDChip = ({ index, column }: { index: number; column: LimeColumn }) => {
  const theme = useTheme();
  const change = useChangeColumns();

  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN_DRAG",
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "COLUMN_DRAG",
    drop({ key }: { key: string }) {
      change({
        type: "swap",
        to: column.key,
        from: key,
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleOnClickPinnedColumn = useCallback(() => {
    change({
      type: "pinned",
      key: column.key,
    });
  }, [column, change]);

  return (
    <Chip
      draggable
      ref={(ref) => {
        drag(ref);
        drop(ref);
      }}
      avatar={
        <Avatar
          style={{
            background: theme.palette.background.paper,
          }}
        >
          {index}
        </Avatar>
      }
      label={
        <Typography sx={{ padding: "0 2px" }} variant="caption">
          {column.name}
        </Typography>
      }
      sx={{
        borderRadius: 2,
        background: isOver ? theme.palette.success.light : undefined,
        margin: 0.6,
        opacity: isDragging ? 0.3 : 1,
        height: 24,
      }}
      size="small"
      deleteIcon={
        <PushPinIcon
          id={`btn-pin-${column.key}`}
          style={{
            color: column.frozen ? theme.palette.success.main : "#aaa",
            transform: column.frozen ? "rotateZ(0deg)" : "rotateZ(45deg)",
          }}
          fontSize="small"
        />
      }
      onDelete={handleOnClickPinnedColumn}
    />
  );
};

export default DnDChip;
