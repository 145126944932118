import {
  alpha,
  PaletteColorOptions,
  PaletteMode,
  ThemeOptions,
} from "@mui/material";
import { root } from "postcss";
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    shadowClr: PaletteColorOptions;
  }
  interface PaletteOptions {
    shadowClr: PaletteColorOptions;
  }
}

const lightBorderColor = alpha("#2e7d32", 0.1);
const darkBorderColor = alpha("#ddd", 0.1);

const getUserChoiceTheme = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          background: {
            default: "#ECF1F5",
          },
          primary: {
            main: "#8ABF1A",
          },
          secondary: {
            main: "#152238",
          },
          success: {
            main: "#2e7d32",
          },
          info: {
            main: "#0288d1",
          },
          error: {
            main: "#d32f2f",
          },
          warning: {
            main: "#ed6c02",
          },
          shadowClr: "#aaa" as PaletteColorOptions,
        }
      : {
          background: {
            default: "#152238",
          },
          primary: {
            main: "#8ABF1A",
          },
          secondary: {
            main: "#344A6E",
          },
          success: {
            main: "#66bb6a",
          },
          info: {
            main: "#29b6f6",
          },
          error: {
            main: "#f44336",
          },
          warning: {
            main: "#ffa726",
          },
          shadowClr: "#555" as PaletteColorOptions,
        }),
  },
  components: {
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          fontSize: 14,
        },
        input: {
          padding: "2px 6px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          display: "none",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          paddingRight: 3,
          marginLeft: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          display: "flex",
          width: "100%",
          "& .MuiFilledInput-root": {
            margin: 0,
            overflow: "hidden",
          },
          "& .MuiFilledInput-root > .MuiFilledInput-input": {
            padding: "2px 6px",
          },
          "> .MuiAutocomplete-option": {
            padding: "0 8px",
          },
        },
        input: {
          padding: 0,
        },
        inputRoot: {
          display: "flex",
          flexWrap: "nowrap",
          ":hover": {
            overflow: "overlay",
          },
          padding: 0,
        },
        popper: {
          "& .MuiPaper-root > ul > span": {
            padding: "0 16px",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "filled",
      },
      styleOverrides: {
        filled: {
          padding: "2px 6px",
        },
      },
    },
    MuiMenu: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: 0,
        },
        list: {
          padding: 0,
          "& li": {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        labelSmall: {
          verticalAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },
        filled: {
          borderRadius: "3px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "2px 16px",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "thead > tr > th": {
            border: `1px solid ${
              mode === "light" ? lightBorderColor : darkBorderColor
            }`,
          },

          "tbody > tr > td": {
            border: `1px solid ${
              mode === "light" ? lightBorderColor : darkBorderColor
            }`,
          },
          "tbody > tr > th": {
            border: `1px solid ${
              mode === "light" ? lightBorderColor : darkBorderColor
            }`,
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "> .MuiBox-root": {
            padding: 0,
            background: mode === "dark" ? "#181D1F" : "#fff",
          },
        },
      },
    },
  },
});

export default getUserChoiceTheme;
