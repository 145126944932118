import { TextField, Select, styled } from "@mui/material";

const StyledTextField = styled(TextField);

export const FlatOnRightTextField = StyledTextField((props) => ({
  "& *": {
    borderTopRightRadius: "3px !important",
    borderTopLeftRadius: "0px !important",

    borderBottomRightRadius: "3px !important",
    borderBottomLeftRadius: "0px !important",
  },
}));

export const RoundedTextField = StyledTextField((props) => ({
  "& *": {
    borderTopRightRadius: "3px !important",
    borderBottomRightRadius: "3px !important",
    borderTopLeftRadius: "3px !important",
    borderBottomLeftRadius: "3px !important",
  },
}));

export const FlatOnLeftSelect = styled(Select)((props) => ({
  borderTopRightRadius: 0,
  borderTopLeftRadius: "3px !imporant",
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: "3px !important",
}));
