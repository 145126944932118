import React, { useCallback } from "react";

import { InputAdornment, IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useSetArgumentField } from "../../hooks/useQuery";

export function ClearInputAdornment(props: {
  id: string;
  visible: boolean;
  onClear?: () => void;
}) {
  const setValue = useSetArgumentField();

  const clear = useCallback(() => {
    props.onClear?.();
    setValue([]);
  }, [setValue, props]);

  if (props.visible) {
    return (
      <InputAdornment position="end">
        <IconButton sx={{ p: 0 }} onClick={clear} id={props.id}>
          <Close fontSize="small" />
        </IconButton>
      </InputAdornment>
    );
  } else {
    return <></>;
  }
}
