import { useEffect, useRef } from "react";
import { useFetchThisGrid } from "../../body/grid/CustomDataGrid";
import { CreateStateContext } from "../stateContext";

type AutoRefreshContextType = {
  active: boolean;
  seconds: number;
};

export const DEFAULT_REFRESH_TIME = 30000

const [AutoRefreshProviderCore, useAutoRefreshData, useSetAutorefreshData] =
  CreateStateContext<AutoRefreshContextType>(
    "autorefresh-local",
    {
      active: false,
      seconds: DEFAULT_REFRESH_TIME,
    },
    true
  );

const AutoRefreshComp = () => {
  const doFetch = useFetchThisGrid();
  const autorefresh = useAutoRefreshData();

  useEffect(() => {
    if (autorefresh.active) {
      const inter = setInterval(() => {
        doFetch();
      }, autorefresh.seconds);
      return () => clearInterval(inter);
    }
  }, [doFetch, autorefresh]);

  return <></>;
};

const AutoRefreshProvider = (props: React.PropsWithChildren) => {
  return (
    <AutoRefreshProviderCore>
      <AutoRefreshComp />
      {props.children}
    </AutoRefreshProviderCore>
  );
};

export { AutoRefreshProvider, useAutoRefreshData, useSetAutorefreshData };
