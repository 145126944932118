const ChipColorCodes = {
  close: "error",
  rejected: "error",
  closed: "error",
  sell: "error",
  open: "success",
  new_order: "success",
  filled: "success",
  buy: "success",
  cover: "success",
  canceled: "warning",
  short: "warning",
  "short exempt": "warning",
};

export default ChipColorCodes;
