import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import React from "react";
import { SortIconProps } from "react-data-grid";

// Custom Sort Icon for Data Grid Header
function SortIcon({ sortDirection }: SortIconProps) {
  return sortDirection !== undefined ? (
    <span style={{ position: "absolute" }}>
      {sortDirection === "ASC" ? (
        <KeyboardArrowDownRounded fontSize="small" />
      ) : (
        <KeyboardArrowUpRounded fontSize="small" />
      )}
    </span>
  ) : null;
}

SortIcon.displayName = "standalone:SortIcon";

export default SortIcon;
