import { Menu, MenuItem, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { RowRendererProps, Row, CalculatedColumn } from "react-data-grid";
import { ColumnContentType } from "../../../types.d";
import copy from "copy-to-clipboard";
import { fieldBorderRadius } from "../../../styles/globalVar";
import { useSetShowNotification } from "../../hooks/useGlobalState";
import { nanoid } from "nanoid";
import MasterDetailsRow from "./master-row/MasterDetailsRow";

function CustomRowRenderer(props: RowRendererProps<ColumnContentType>) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;

  const [cellDate, setCellDate] = useState("");
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const setShowNotification = useSetShowNotification();

  const handleClose = () => {
    setContextMenu(null);
  };

  const resolveEventConflict = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    resolveEventConflict(event);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleRightClickOnCellToSaveState = (
    row: ColumnContentType,
    column: CalculatedColumn<ColumnContentType, unknown>
  ) => {
    const d: string = row[column.key as keyof typeof row] as string;
    setCellDate(d);
  };

  const showNotification = (message: string) => {
    setShowNotification({
      open: true,
      message: message,
      type: "success",
    });
  };

  const handleOnClickCopyCell = () => {
    copy(cellDate);
    setContextMenu(null);
    showNotification("Cell Copied");
  };

  const handleOnClickCopyRow = () => {
    const data: string = JSON.stringify(props.row);
    copy(data);
    setContextMenu(null);
    showNotification("Row Copied");
  };
  // const rowExpand =
  //   props.row.expanded === true ? `row-expand-${themeMode}` : undefined;

  return (
    <div
      key={nanoid(5)}
      onContextMenu={handleContextMenu}
      style={{
        width: "inherit",
        cursor: "context-menu",
        display: "contents",
        // visibility: props.row.type === "DETAIL" ? "hidden" : "visible",
      }}
      id={props.row.type === "DETAIL" ? "detailsGrid" : ""}
    >
      <Row
        {...props}
        selectCell={handleRightClickOnCellToSaveState}
        className={`${props.className} ${/* rowExpand */ ""}`}
      />

      <Menu
        disableAutoFocus
        open={contextMenu !== null}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            padding: 0,
            background: theme.palette.background.paper,
          },
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            borderRadius: fieldBorderRadius,
          },
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          disableRipple
          onClick={handleOnClickCopyCell}
          sx={{ padding: "4px 8px" }}
        >
          <Typography variant="subtitle2">Copy Cell</Typography>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={handleOnClickCopyRow}
          sx={{ padding: "4px 8px" }}
        >
          <Typography variant="subtitle2">Copy Row</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

CustomRowRenderer.displayName = "standalone:CustomRowRenderer";

export default CustomRowRenderer;
