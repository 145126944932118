import { useCallback, useState, useEffect, useMemo } from "react";
import {
  useSelectedResults,
  useSelectedRows,
  useSetSelectedRows,
} from "./useSelectedRows";
import { useFetcher, useResult, useSetResult } from "./useQuery";
import { useFetchThisGrid } from "../body/grid/CustomDataGrid";
import { useFetchAllGrids } from "./useGlobalState";
import { AutocompleteContextValueType } from "../../types.d";
import { useViewableAccounts } from "./useAccounts";

const DELAY_INTERVAL = 100;
const REFRESH_DELAY = DELAY_INTERVAL * 10;

export type CancelResp = {
  clientOrderID: string;
  eventType: string | "OnCancelRejectEvent" | "OnValidationError";
  reason: string;
  validationErrors: {
    invalidValue: any;
    message: string;
  }[];
};

export type CancelAccountsResp = Record<number, CancelResp>;

const MAX_PARALLEL_FETCH = 10;

function useTradeFetcher() {
  return useFetcher("trade");
}

export function useCancelSelectedRows(
  progress?: (
    idx: number,
    total: number,
    resp: CancelAccountsResp
  ) => void | Promise<void>
) {
  const selectedRowsSet = useSelectedResults();
  const setSelected = useSetSelectedRows();
  const fetcher = useTradeFetcher();
  const refresh = useFetchThisGrid();

  const [delay, setDelay] = useState(-1);

  useEffect(() => {
    if (delay > 0) {
      setTimeout(() => {
        setDelay((x) => x - DELAY_INTERVAL);
      }, DELAY_INTERVAL);
    } else if (delay == 0) {
      refresh();
      setDelay(-1);
    }
  }, [delay, refresh]);

  const startRefresh = useCallback(() => setDelay(REFRESH_DELAY), [setDelay]);

  return useCallback(async () => {
    if (fetcher) {
      const selectedRows = Array.from(selectedRowsSet.values());
      const groupedByAccount = selectedRows.reduce((dict, row) => {
        const a = row.account;
        const id = `${row.clOrdId}`;
        const l = dict[a] ? dict[a].concat(id) : [id];
        return {
          ...dict,
          [a]: l,
        };
      }, {} as Record<string, string[]>);
      const tasks = Object.entries(groupedByAccount);

      var resps: CancelResp[] = [];

      for (const i in tasks) {
        const [account, ids] = tasks[i];
        const resp = await fetcher<CancelAccountsResp>(
          `/v2/trading/${account}/orders/cancel`,
          {},
          "delete",
          ids
        );

        if (resp) {
          resps = resps.concat(Object.values(resp));
          if (progress) {
            progress(parseInt(i) + 1, tasks.length, resp);
          }
        }
      }
      setSelected(new Set<React.Key>());
      startRefresh();
      return resps;
    }
  }, [fetcher, selectedRowsSet, progress, startRefresh, setSelected]);
}

export type AccountInfo = {
  name: string;
  number: number;
};

export function useMassCancel(
  accounts: AccountInfo[],
  progress?: (
    idx: number,
    total: number,
    resp: CancelResp
  ) => void | Promise<void>
) {
  const doFetchAll = useFetchAllGrids();
  const fetcher = useTradeFetcher();

  return useCallback(async () => {
    if (fetcher) {
      if (accounts) {
        var resps: CancelResp[] = [];
        for (const i in accounts) {
          const account = accounts[i];
          const resp: CancelResp | undefined = await fetcher(
            `/v2/trading/${account.number}/orders/cancel-all`,
            {},
            "DELETE"
          );
          if (resp) {
            resp.clientOrderID = account.name;
            resps.push(resp);
            if (progress) {
              progress(parseInt(i) + 1, accounts.length, resp);
            }
          }
        }

        await new Promise((r) => setTimeout(r, REFRESH_DELAY));
        doFetchAll();
        return resps;
      } else {
        throw "must provide account number";
      }
    }
  }, [fetcher, accounts, doFetchAll, progress]);
}

export function useMyAccountNumbers() {
  const accountMap: AutocompleteContextValueType = useViewableAccounts();

  return useMemo(() => {
    if (accountMap) {
      return Object.entries(accountMap)
        .map(([accountNumber, name]) => ({
          number: parseInt(accountNumber),
          name,
        }))
        .sort((i, j) => i.name.localeCompare(j.name));
    } else {
      return [];
    }
  }, [accountMap]);
}
