import React, {
  useState,
  useEffect,
  createContext,
  useRef,
} from "react";
import { CreateStateContext } from "./stateContext";
import {
  AutocompleteContextValueType,
  AutocompleteContextType,
} from "../../types.d";
import { useFetcher } from "./useQuery";
import { UseQueryArgs, UseFetcherBaseUrls } from "./useQuery";

export function CreateMappingProvider(
  endpoint: string,
  seralize: (obj: any) => AutocompleteContextValueType,
  baseUrl: UseFetcherBaseUrls = "search"
): [
  React.FC<React.PropsWithChildren>,
  () => AutocompleteContextValueType,
  AutocompleteContextType
] {
  const [MapProvider, useMap, useSetMap, MapContext] =
    CreateStateContext<AutocompleteContextValueType>(`mapping:${endpoint}`);

  function MapRunner() {
    const fetcher = useFetcher(baseUrl);
    const set = useSetMap();

    useEffect(() => {
      (async () => {
        if (fetcher) {
          const body = await fetcher(endpoint);
          console.warn("AUTOCOMPLETE DYNAMIC FETCH", endpoint, body)
          const map = seralize(body);
          set(map);
        }
      })();
    }, [fetcher, set]);

    return <></>;
  }

  MapRunner.displayName = "runner:MapRunner";

  function MapWrapper(props: React.PropsWithChildren) {
    return (
      <MapProvider>
        <MapRunner />
        {props.children}
      </MapProvider>
    );
  }

  MapWrapper.displayName = "wrapper:MapWrapper";

  return [MapWrapper, useMap, MapContext];
}

export function CreateDynamicMapping<T = any>(
  endpoint: string,
  searlize: (
    obj: T,
    curr?: AutocompleteContextValueType
  ) => AutocompleteContextValueType,
  getArguments: (args: UseQueryArgs) => UseQueryArgs = (x) => x,
  friendlyName?: string
) {
  const DynamicMappingContext = createContext<
    AutocompleteContextValueType | undefined
  >(undefined);

  const [RawArgsProvider, useRawArgs, useSetRawArgs] = CreateStateContext<
    Record<string, string | string[]>
  >("raw_args:" + friendlyName, {}, false);

  function DynamicProvider(props: React.PropsWithChildren) {
    const args = useRawArgs();
    const fetcher = useFetcher();
    const [value, setValue] = useState<AutocompleteContextValueType>({});

    const smallArgs = useRef<UseQueryArgs>();

    useEffect(() => {
      if (fetcher) {
        (async () => {
          const a = getArguments(args);
          if (JSON.stringify(a) !== JSON.stringify(smallArgs.current)) {
            console.log("Querying dynamic map", endpoint, a);
            const body = await fetcher<any>(endpoint, a);
            setValue((prev) => searlize(body, prev));
          }
          smallArgs.current = a;
        })();
      }
    }, [args, fetcher]);

    return (
      <DynamicMappingContext.Provider value={value}>
        {props.children}
      </DynamicMappingContext.Provider>
    );
  }
  DynamicProvider.displayValue = `dynamic_map_provider:${friendlyName}`;

  function DynamicWrapper(props: React.PropsWithChildren) {
    return (
      <RawArgsProvider>
        <DynamicProvider>{props.children}</DynamicProvider>
      </RawArgsProvider>
    );
  }
  DynamicWrapper.displayValue = `dynamic_map_wrapper:${friendlyName}`;

  return [DynamicWrapper, useSetRawArgs, DynamicMappingContext] as const;
}
