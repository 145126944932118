import { useCallback } from "react";
import moment from "moment";
import { useFetcher } from "../useQuery";

type QueryParams = {
  orderId: string;
  timestamp: string;
};

const useFetchRiskData = () => {
  const fetcher = useFetcher();

  return useCallback(
    async ({ orderId, timestamp }: QueryParams) => {
      const m = moment(timestamp).format("YYYY-MM-DD");
      if (fetcher) {
        const body = await fetcher(`/v2/risk`, {
          id: orderId,
          timestamp: `on:${m}`,
        });
        return body;
      }
    },
    [fetcher]
  );
};

export default useFetchRiskData;
