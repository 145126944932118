import React, {
  useCallback,
} from "react";
import { ColumnContentType } from "../../types.d";
import {
  CreateStateContext,
  CreateStateFence,
} from "./stateContext";
import getConfig from "next/config";
import { useToken } from "./useToken";
import * as Sentry from "@sentry/nextjs";
import { CreateRunnerGroup } from "./useRunner";
import { useUser } from "@auth0/nextjs-auth0";

export type ArgumentType = string | string[] | null | undefined;

export type UseQueryArgs<QueryArgs extends string = string> = Partial<{
  [k in QueryArgs]: ArgumentType;
}>;

export interface FetchBody {
  values: Array<ColumnContentType>;
  asOf: Date;
  total: number;
}

const ArgsStateContext = CreateStateContext<UseQueryArgs<string>>(
  "arguments",
  {},
  true,
  false
);

const [ArgsProvider, useArgs, useSetArgs] = ArgsStateContext;

const [ReadyProvider, useReady, useSetReady] = CreateStateContext<
  boolean | undefined
>("ready", false);

const [ResultProvider, useResult, useSetResult] = CreateStateContext<
  Array<ColumnContentType>
>("results", []);

const [LoadingProvider, useIsLoading, useSetLoading] =
  CreateStateContext<boolean>("loading", false);

const [TotalCountProvider, useTotalCount, useSetTotalCount] =
  CreateStateContext<number>("total count", 0);

const [ArgumentFence, useArgumentField, useSetArgumentField] = CreateStateFence<
  UseQueryArgs<string>
>(ArgsStateContext, "", "args");

const [FetchAllContextProvider, useAllFetches, useSetAllFetches] =
  CreateStateContext<Record<string, () => void>>("fetch_all", {}, false);

const [RunnerGroupProvider, useRegisterReset, useResetAll] =
  CreateRunnerGroup("reset");

export {
  ArgsProvider,
  ArgumentFence,
  FetchAllContextProvider,
  useArgs,
  useSetArgs,
  useArgumentField,
  useIsLoading,
  useReady,
  useRegisterReset,
  useResult,
  useSetArgumentField,
  useSetLoading,
  useSetResult,
  useSetTotalCount,
  useTotalCount,
};

export const QueryProvider: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <ArgsProvider>
      <ReadyProvider>
        <LoadingProvider>
          <ResultProvider>
            <TotalCountProvider>
              {/* <QueryRunner>{props.children}</QueryRunner> */}
              {props.children}
            </TotalCountProvider>
          </ResultProvider>
        </LoadingProvider>
      </ReadyProvider>
    </ArgsProvider>
  );
};

QueryProvider.displayName = "standalone:QueryProvider";

export function useResetArgs() {
  const setArgs = useSetArgs();
  const setData = useSetResult();
  const resetAll = useResetAll();

  return () => {
    setArgs({});
    setData([]);
    resetAll();
  };
}

const { publicRuntimeConfig } = getConfig();
const BaseUrls = {
  search: publicRuntimeConfig.search_domain as string,
  trade: publicRuntimeConfig.trade_domain as string,
  home: publicRuntimeConfig.home_domain as string,
} as const;
export type UseFetcherBaseUrls = keyof typeof BaseUrls;

export function useFetcher(baseUrl: UseFetcherBaseUrls = "search") {
  const token = useToken();

  const fetcher = useCallback(
    async function <T = any>(
      endpoint: string,
      args?: UseQueryArgs,
      method?: string,
      body?: object
    ): Promise<T | undefined> {
      if (endpoint.includes("v1")) {
        debugger;
        throw "NO UNDERSCORES";
      }

      if (!BaseUrls[baseUrl]) {
        debugger;
      }

      var u: URL;
      try {
        u = new URL(endpoint, BaseUrls[baseUrl]);
      } catch (e) {
        debugger;
        throw e;
      }

      Object.entries(args ?? {}).forEach(([key, values]) => {
        if (values) {
          if (Array.isArray(values)) {
            values.forEach((value) => {
              if (value) {
                u.searchParams.append(key, value);
              }
            });
          } else if (values) {
            u.searchParams.append(key, values);
          }
        }
      });

      const b = JSON.stringify(body);

      var resp: Response;

      while (true) {
        resp = await fetch(u.toString(), {
          method: method,
          headers: {
            Authorization: "Bearer " + token,
            ...(b ? { "Content-Type": "application/json" } : {}),
          },
          ...(b ? { body: b } : {}),
        });

        if (resp.status === 429) {
          await new Promise((r) => setTimeout(r, 6000)); // 5 seconds + 0.5 seconds
        } else {
          break;
        }
      }

      if (resp.ok) {
        const body: T = await resp.json();
        return body;
      } else {
        console.error("Unable to fetch", endpoint, resp);

        switch (resp.status) {
          case 400:
            console.error("BAD REQUEST", resp);
            window.location.href = window.location.href;
            break;
          case 401:
            window.location.href =
              "/api/auth/logout?federated&redirectTo=/api/auth/login";
            break;
          case 404:
            alert(
              "This information cannot be found. Please try again with different search parameters or contact trade support"
            );
            break;
          case 405:
            alert("Something went wrong, please contact trade support");
            break;
          // case 429:
          //   await new Promise((r) => setTimeout(r, 5500)); // 5 seconds + 0.5 seconds
          //   return fetcher(endpoint, args, method, body);
          case 500:
            alert(
              "The server has encountered an error. Please try again or contact trade support"
            );
            break;
          case 503:
            alert("The server is unavaliable. Please wait and try again");
            break;
          case 504:
            alert("Please try again");
            break;
          default:
            alert(`${resp.status} ${resp.statusText}: Unable to fetch`);
        }

        Sentry.captureException(new Error("failed to fetch"), (scope) => {
          scope.setExtra("request info", { endpoint, args, method, body });
          scope.setExtra("response info", {
            headers: resp.headers,
            status: resp.status,
          });
          return scope;
        });

        throw resp;
      }
    },
    [token, baseUrl]
  );

  return token ? fetcher : undefined;
}

export function useDoFetchAll() {
  const allFetches = useAllFetches();

  return useCallback(() => {
    Object.values(allFetches).forEach((x) => x());
  }, [allFetches]);
}
