import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { default as moment, Moment } from "moment";
import { useCallback, useMemo } from "react";
import { useAvaliableDates } from "../../hooks/useAvaliableDatesMap";

import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { DATETIME_TYPE } from "./DTRMain";
import {
  useDateTimeRPData,
  useSetDateTimeRPData,
} from "../../hooks/datetimerangepicker-hook/useDateTimeRangePicker";
import React from "react";

function DatePicker(props: { type: DATETIME_TYPE }) {
  const dateTimeRPData = useDateTimeRPData();
  const { startDate, endDate } = dateTimeRPData;
  const setDateTimeRPDate = useSetDateTimeRPData();

  const currentDate = moment(
    props.type === "START_DATETIME" ? startDate : endDate
  );

  const handleOnDateChange = useCallback(
    (d: Moment | null) => {
      if (d) {
        setDateTimeRPDate((prev) => {
          if (prev) {
            const tmp = { ...prev, defaultDateTimeLabel: null };

            if (props.type === "START_DATETIME") {
              d?.hour(4);
              tmp.startDate = d ?? moment();
            } else {
              d?.hour(20);
              tmp.endDate = d ?? moment();
            }
            return tmp;
          } else {
            throw "undefined";
          }
        });
      }
    },
    [setDateTimeRPDate, props]
  );

  const avaliableDates = useAvaliableDates();

  const minDate = useMemo(() => {
    return moment().subtract(4, "years");
  }, []);

  const maxDate = useMemo(() => {
    const maxAvailDate = moment(Math.max(...avaliableDates));
    return moment.max(maxAvailDate);
  }, [avaliableDates]);

  const shouldDisableDate = useCallback(
    function shouldDisableDateFunc(day: Moment) {
      if (avaliableDates) {
        return !avaliableDates.includes(day.valueOf());
      }
      return true;
    },
    [avaliableDates]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <span data-testid="calendar">
        <StaticDatePicker
          components={{
            SwitchViewButton: ({ children }) => {
              const viewType = children.props.ownerState.openView;
              return (
                <IconButton size="small" sx={{ p: 0 }} disableTouchRipple>
                  {viewType === "day" ? (
                    <ArrowDropDownRounded />
                  ) : (
                    <ArrowDropUpRounded />
                  )}
                </IconButton>
              );
            },
            LeftArrowButton: (props) => (
              <IconButton
                {...props}
                disableTouchRipple
                sx={{ p: 0 }}
                size="small"
              >
                <ChevronLeftRounded />
              </IconButton>
            ),
            RightArrowButton: (props) => (
              <IconButton
                {...props}
                disableTouchRipple
                sx={{ p: 0 }}
                size="small"
              >
                <ChevronRightRounded />
              </IconButton>
            ),
          }}
          dayOfWeekFormatter={(day) => day}
          displayStaticWrapperAs="desktop"
          disableFuture={true}
          reduceAnimations={true}
          openTo="day"
          value={currentDate}
          onChange={handleOnDateChange}
          renderInput={(params) => <></>}
          disableMaskedInput
          shouldDisableDate={shouldDisableDate}
          minDate={minDate}
          maxDate={maxDate}
        />
      </span>
    </LocalizationProvider>
  );
}

DatePicker.displayName = `standalone:${DatePicker}`;

export default React.memo(DatePicker);
