import {CreateMappingProvider} from "./autocompleteMapper";

const [ClearingGroupsProvider, useClearingGroups, ClearingGroupsContext] =
  CreateMappingProvider(
    "/v2/buying-power/mine/clearing-groups",
    (obj: string[]) => {
      return obj.reduce(
        (obj, val) => ({
          ...obj,
          [val]: `${val}`,
        }),
        {}
      );
    },
    "search"
  );

export {ClearingGroupsProvider, ClearingGroupsContext};
