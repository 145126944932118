import { Box } from "@mui/material";
import {
  RowStacks,
  ExportMenu,
  RestartButton,
  SearchButton,
  ChooseColumns,
  AutoRefreshButton,
} from "../../components/body/common/ControlRow";
import CustomDataGrid from "../../components/body/grid/CustomDataGrid";
import { ArgumentFence, ArgumentType } from "../../components/hooks/useQuery";
import { CustomChoiceField } from "../body/new-inputs/CustomChoiceField";
import { ColDefContext } from "../body/new-inputs/utils";
import { AutocompleteContextValueType, ColumnDef } from "../../types.d";
import { SingleDatePicker } from "../body/common/SingleDatePicker";
import { createContext } from "react";
import { AutoRefreshProvider as PositionAutoRefreshProvider } from "../hooks/datetimerangepicker-hook/useAutoRefresh";

const StubAutocompleteContext = createContext<AutocompleteContextValueType>(
  {} as any
);

function SelectPositionState() {
  const x: Partial<ColumnDef> = {
    choices: {
      OPEN: "OPEN",
      CLOSED: "CLOSED",
      ALL: "ALL",
    },
    type: "choice",
    rdgColumn: {
      key: "state",
      name: "State",
    },
    autocomplete: StubAutocompleteContext,
  };

  return (
    <Box
      sx={{
        maxHeight: "inherit",
        maxWidth: "calc(max(20%, 50ch))",
        width: "20ch",
        margin: "0 2px",
      }}
    >
      <ColDefContext.Provider value={x as Required<ColumnDef>}>
        <CustomChoiceField />
      </ColDefContext.Provider>
    </Box>
  );
}

export function PositionsCore() {
  const handleDisableButton = (
    args: Partial<{
      [x: string]: ArgumentType;
    }>
  ) => {
    if ("accountName" in args && args["accountName"]) {
      return args["accountName"]?.length <= 0;
    } else {
      return true;
    }
  };

  return (
    <>
      <PositionAutoRefreshProvider>
        <RowStacks
          leftRowStack={
            <>
              <ArgumentFence field="date">
                <SingleDatePicker />
              </ArgumentFence>
              <ArgumentFence field="state">
                <SelectPositionState />
              </ArgumentFence>
              <AutoRefreshButton disabled={handleDisableButton} />
            </>
          }
          rightRowStack={
            <>
              <SearchButton disabled={handleDisableButton} />
              <RestartButton />
              <ExportMenu />
              <ChooseColumns />
            </>
          }
        />
      </PositionAutoRefreshProvider>

      <Box
        sx={{
          display: "flex",
          width: "inherit",
          flexDirection: "column",
          position: "relative",
          height: "calc(100% - 30px)",
          overflow: "hidden",
        }}
      >
        <CustomDataGrid
          baseUrl="trade"
          showHeaderFilters={true}
          path="/v2/positions/account"
          enableColumnPrefix={false}
          transformArgs={(args) => {
            delete args["quantity"];
            delete args["averagePrice"];
            // delete args["position"];
            return args;
          }}
          parseList={(obj: any) => {
            if (Array.isArray(obj)) {
              return obj.map(({ account, ...rest }) => ({
                accountName: account.name,
                ...rest,
              }));
            } else {
              throw "not an array";
            }
          }}
          parseTotal={(obj: any) => {
            if (Array.isArray(obj)) {
              return obj.length;
            } else {
              throw "not an array";
            }
          }}
        />
      </Box>
    </>
  );
}
