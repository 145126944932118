import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useReducer,
  Reducer,
  ReducerState,
  Dispatch,
  ReducerAction,
  createContext,
  useContext,
  SetStateAction,
} from "react";
import { CreateStateContext } from "./stateContext";
import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";

const LocalStatePrefixContext = createContext<string>("");
const [DropKeysProvider, useDropKeys, useSetDropKeys] = CreateStateContext<
  Set<string> | false
>("drop_keys", false, false);

/**
 * This hook returns a function that will delete every key from localstorage
 * that is prefixed by the LocalStateContextProvider's prefix prop
 */
export function useDropTheseKeys() {
  const keys = useDropKeys();
  const prefix = useContext(LocalStatePrefixContext);

  return useCallback(() => {
    if (prefix) {
      try {
        if (keys !== false) {
          keys.forEach((key) => {
            if (key.startsWith(prefix)) {
              console.log("localstorage: Dropping key", key);
              localStorage.removeItem(key);
              keys.delete(key);
            }
          });
        }
      } catch (e: any) {
        Sentry.captureException(e);
      }
    } else {
      console.error("DropTheseKeys called with no prefix");
    }
  }, [keys, prefix]);
}

export const PROTECT_BY_DEFAULT = true;

/**
 * Is a hook that is used exacly like useState but this one syncs its
 * state with localstorage. Exclude `key` to disable saving. Set protect to
 * false to reset this local state if the version changes.
 */
export function useLocalState<T>(
  initial: T | (() => T),
  key?: string,
  protect = PROTECT_BY_DEFAULT
): [T, React.Dispatch<React.SetStateAction<T>>, boolean] {
  const [state, setState] = useState<T>(initial);
  const [ready, setReady] = useState(false);
  const prefix = useContext(LocalStatePrefixContext);
  const storeKey = prefix + key;
  const update = () => {
    document.dispatchEvent(new Event("storage"));
  }

  const setDropKeys = useSetDropKeys();
  const addKey = useCallback(
    (key: string) => {
      if (setDropKeys) {
        setDropKeys((prev) => {
          return prev ? prev.add(key) : new Set([key]);
        });
      }
    },
    [setDropKeys]
  );

  useEffect(() => {
    if (!protect) {
      const versionKey = storeKey + "version";
      const localVersion = localStorage.getItem(versionKey);
      const { publicRuntimeConfig } = getConfig();
      const version = publicRuntimeConfig.build_number;
      if (localVersion) {
        if (localVersion !== version) {
          console.warn(
            `localstorage: RESETTING ${storeKey} DUE TO VERSION CHANGE`
          );
          localStorage.removeItem(storeKey);
        }
      }
      localStorage.setItem(versionKey, version);
      update()
      addKey(versionKey);
    }
  }, [storeKey, protect, addKey, update]);

  useEffect(() => {
    if (key) {
      try {
        addKey(storeKey);
        console.log("localstorage: add key to drop context", prefix, storeKey);
      } catch (e: any) {
        Sentry.captureException(e);
      }
    }
  }, [addKey, storeKey, key, prefix]);

  useEffect(() => {
    if (key && typeof state !== "undefined" && localStorage && ready) {
      console.log("localstorage: Saving state", storeKey, state);
      try {
        localStorage.setItem(storeKey, JSON.stringify(state));
        update()
      } catch (e: any) {
        Sentry.captureException(e);
      }
    }
  }, [storeKey, state, ready, key, update]);

  useEffect(() => {
    if (key && !ready) {
      const t = localStorage.getItem(storeKey);
      if (t) {
        console.log("localstorage: Loading state", storeKey, t);
        try {
          setState(JSON.parse(t));
        } catch (e: any) {
          Sentry.captureException(e);
        }
      }
      setReady(true);
    }
  }, [storeKey, key, ready]);

  return [state, setState, ready];
}

/**
 * Is a hook exactly like useReducer but this one saves state changes to
 * local storage automatically.
 */
export function useLocalReducer<R extends Reducer<any, any>, I>(
  key: string,
  reducer: R,
  initializerArg: I & ReducerState<R>,
  initializer?: (arg: I & ReducerState<R>) => ReducerState<R>,
  protect = PROTECT_BY_DEFAULT
): [ReducerState<R>, Dispatch<ReducerAction<R>>] {
  const [state, setState, hydrated] = useLocalState<ReducerState<R>>(
    initializerArg && initializer
      ? initializer(initializerArg)
      : initializerArg,
    key,
    protect
  );

  const d: Dispatch<ReducerAction<R>> = useCallback(
    (action) => {
      if (hydrated) {
        setState((prev) => {
          try {
            const x = reducer(prev, action);
            return x;
          } catch (e: any) {
            Sentry.captureException(e);
          }
        });
      }
    },
    [setState, hydrated, reducer]
  );

  return [state, d];
}

/**
 * Allows useLocalState and useLocalReducer to save different states for
 * different instances of the same component.
 */
export function LocalStateContextProvider(
  props: React.PropsWithChildren<{ prefix: string }>
) {
  return (
    <DropKeysProvider>
      <LocalStatePrefixContext.Provider value={props.prefix}>
        {props.children}
      </LocalStatePrefixContext.Provider>
    </DropKeysProvider>
  );
}
LocalStateContextProvider.displayName = "standalone:LocalStateContextProvider";
