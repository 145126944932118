import { CreateStateContext } from "./stateContext";

const [ShowInputFieldProvider, useShowInputFields, useSetShowInputFields] =
  CreateStateContext<boolean>("expanded", true, true);

export { ShowInputFieldProvider, useShowInputFields };

export function useToggleExpanded() {
  const set = useSetShowInputFields();

  if (!set) {
    throw "useToggleExpanded not in context";
  }

  return () => set((prev) => !prev);
}
