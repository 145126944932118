import { useDrag, useDrop } from "react-dnd";
import { headerRenderer as HeaderRenderer } from "react-data-grid";
import type { HeaderRendererProps } from "react-data-grid";
import {
  Button,
  ClickAwayListener,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { PushPinRounded } from "@mui/icons-material";
import React, { createContext, useCallback, useContext, useState } from "react";
import { ColumnDef } from "../../../types.d";
import { CustomInputField } from "../new-inputs/InputField";
import {
  useChangeColumns,
  useToggleVisibleColumn,
} from "../../hooks/useColumns";
import { fieldBorderRadius } from "../../../styles/globalVar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface DraggableHeaderRendererProps<R> extends HeaderRendererProps<R> {
  columnDef: Required<ColumnDef>;
}

const ShowHeadersFilterContext = createContext<boolean>(true);

export function ShowHeadersFilterProvider(
  props: React.PropsWithChildren<{ show: boolean }>
) {
  return (
    <ShowHeadersFilterContext.Provider value={props.show}>
      {props.children}
    </ShowHeadersFilterContext.Provider>
  );
}

export function CustomHeaderRenderer<R>({
  column,
  ...props
}: DraggableHeaderRendererProps<R>) {
  const theme = useTheme();

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [{ isDragging }, drag] = useDrag({
    type: "COLUMN_DRAG",
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const change = useChangeColumns();
  const showFilter = useContext(ShowHeadersFilterContext);

  const [_, toggle] = useToggleVisibleColumn(column.key);

  const [{ isOver }, drop] = useDrop({
    accept: "COLUMN_DRAG",
    drop({ key }: { key: string }) {
      change({
        type: "swap",
        from: key,
        to: column.key,
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleOnClickPinnedColumn = useCallback(() => {
    change({
      type: "pinned",
      key: column.key,
    });
  }, [column, change]);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null
      );
    },
    [setContextMenu]
  );

  const handleClose = useCallback(() => {
    setContextMenu(null);
  }, [setContextMenu]);

  const handleHideColumn = useCallback(() => {
    toggle();
    setContextMenu(null);
  }, [toggle, setContextMenu]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          width: "inherit",
          overflow: "visible",
        }}
        ref={(ref) => drop(ref)}
      >
        <div
          ref={(ref) => drag(ref)}
          style={{
            cursor: "move",
            width: "100%",
            opacity: isDragging ? 0.3 : 1,
            color: isOver ? theme.palette.success.main : undefined,
            alignItems: "flex-end",
          }}
          className="column-header-cls"
          onContextMenu={handleContextMenu}
        >
          <PushPinRounded
            onClick={handleOnClickPinnedColumn}
            sx={{
              color: column.frozen ? theme.palette.success.main : "#aaa",
              transform: column.frozen ? "rotateZ(0deg)" : "rotateZ(45deg)",
              cursor: "pointer",
              fontSize: 14,
              marginRight: 1,
              ":hover": {
                color: theme.palette.success.main,
              },
            }}
            fontSize="small"
          />
          <HeaderRenderer
            column={column}
            sortDirection={props.sortDirection}
            priority={props.priority}
            onSort={props.onSort}
            allRowsSelected={false}
            onAllRowsSelectionChange={() => {}}
            isCellSelected={false}
          />
        </div>
        {showFilter ? (
          <CustomInputField columnDefinition={props.columnDef} />
        ) : null}
      </div>

      <ClickAwayListener onClickAway={handleClose}>
        <Popover
          open={contextMenu !== null}
          onClose={handleClose}
          PaperProps={{
            elevation: 2,
            sx: {
              borderRadius: fieldBorderRadius,
            },
          }}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Button
            disableTouchRipple
            color="inherit"
            variant="text"
            onClick={handleHideColumn}
            sx={{
              padding: "4px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              textTransform: "none",
            }}
          >
            <VisibilityOffIcon
              fontSize="small"
              sx={{ fontSize: 18, pr: 0.5 }}
            />
            <Typography variant="body2">Hide {column.name}</Typography>
          </Button>
        </Popover>
      </ClickAwayListener>
    </>
  );
}

CustomHeaderRenderer.displayName = "standalone:DraggableHeaderRenderer";
