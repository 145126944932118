import {
  useTheme,
  TableContainer,
  Paper,
  TableCell,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { EventsRiskType } from "../../../../types.d";
import CustomChip from "../../CustomChip";
import NoRowsFallBack from "../NoRowsFallBack";

interface RiskProps {
  data: EventsRiskType;
}

function Risk({ data }: RiskProps) {
  const theme = useTheme();

  return (
    <>
      {data.length <= 0 ? (
        <NoRowsFallBack
          height="50%"
          width="100%"
          marginTop="34px"
          marginLeft="inherit"
        />
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: 0,
            height: "100%",
            background: theme.palette.mode === "light" ? "#fff" : "#181D1F",
          }}
        >
          <Table
            size="small"
            aria-label="a dense table"
            sx={{
              height: "70%",
              borderCollapse: "unset !important",
              background: "inherit",
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>
                  <strong>Order ID:</strong> {data[0].orderId}
                </TableCell>
                <TableCell>
                  <strong>Limit Price: </strong>$
                  {data[0].limitPrice?.toFixed(2)}
                </TableCell>
                <TableCell>
                  <strong>Last Trade Price: </strong>$
                  {data[0].lastPrice?.toFixed(2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Symbol: </strong>
                  <CustomChip color="primary">{data[0].symbol}</CustomChip>
                </TableCell>
                <TableCell>
                  <strong>Bid: </strong>${data[0].bid?.toFixed(2)}
                </TableCell>
                <TableCell>
                  <strong>Closing Price: </strong>$
                  {data[0].closePrice?.toFixed(2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Return Type: </strong>
                  <CustomChip color="info">{data[0].returnType}</CustomChip>
                </TableCell>
                <TableCell>
                  <strong>Ask: </strong>${data[0].ask?.toFixed(2)}
                </TableCell>
                <TableCell>
                  <strong>Reference Price: </strong>$
                  {data[0].referencePrice?.toFixed(2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Lower Bound: </strong>$
                  {data[0].lowerBound?.toFixed(2)}
                </TableCell>
                <TableCell>
                  <strong>Upper Bound: </strong>$
                  {data[0].upperBound?.toFixed(2)}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

Risk.displayName = "standalone:Risk";

export default Risk;
