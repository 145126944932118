import {
  Button,
  CircularProgress,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { GrepResults, GrepResult } from "../../types.d";
import { FlexWrapper } from "../body/common/FlexWrapper";
import { InputWrapper } from "../body/common/InputWrapper";
import { CustomChoiceField } from "../body/new-inputs/CustomChoiceField";
import { CustomNumberField } from "../body/new-inputs/CustomNumberField";
import { CustomTextField } from "../body/new-inputs/CustomTextField";
import {
  accountNameDef,
  arcaDirectFormatDef,
  batsBinaryFormatDef,
  dateDef,
  dateForTidQueryDef,
  endTimeDef,
  nysePillarFormatDef,
  orderIdDef,
  ouchFormatDef,
  priceDef,
  quantityDef,
  routeDef,
  startTimeDef,
  symbolDef,
  tidDef,
  tradingDomainDef,
  utpDirectFormatDef,
  wireFormatDef,
} from "../hooks/columndefs";
import { ArgumentType, useArgs, useFetcher } from "../hooks/useQuery";
import { Box } from "@mui/system";
import { CustomDateField } from "../body/new-inputs/CustomDateField";
import { ClearButton } from "../body/common/Clear";
import { CustomBooleanField } from "../body/new-inputs/CustomBooleanField";
import { capitalCase } from "change-case";

function SubmitButton(props: {
  onResponse: (resp: GrepResults) => void;
  onClick: () => void;
}) {
  const args = useArgs();
  const fetcher = useFetcher("search");

  const query = useCallback(async () => {
    props.onClick();
    const flatten = (x: ArgumentType) => {
      if (Array.isArray(x)) {
        return x[0];
      } else if (x) {
        return x;
      } else {
        return null;
      }
    };

    if (fetcher) {
      const fixedArgs = [
        accountNameDef,
        arcaDirectFormatDef,
        batsBinaryFormatDef,
        dateDef,
        dateForTidQueryDef,
        endTimeDef,
        nysePillarFormatDef,
        orderIdDef,
        ouchFormatDef,
        priceDef,
        quantityDef,
        routeDef,
        startTimeDef,
        symbolDef,
        tidDef,
        tradingDomainDef,
        utpDirectFormatDef,
        wireFormatDef,
      ].reduce((newArgs, def) => {
        const v = flatten(args[def.rdgColumn.key]);
        var o: any;

        switch (def.type) {
          case "money":
          case "number":
            const [_, n] = v?.split(":") ?? [];
            o = parseFloat(n);
            break;
          case "boolean":
            if (v === "true") {
              o = true;
            } else if (v === "false") {
              o = false;
            } else {
              o = null;
            }
            break;
          default:
            o = v;
        }

        return {
          ...newArgs,
          [def.apiKeyOverride["efix"] ?? def.rdgColumn.key]: o,
        };
      }, {});

      const resp: GrepResults | undefined = await fetcher("v2/efix", fixedArgs);

      if (resp) {
        if (resp.errorMsg) {
          alert("Grep error: " + resp.errorMsg)
        }  
        props.onResponse(resp);
      }
    }
  }, [props, args, fetcher]);

  return (
    <Button variant="contained" color="success" onClick={query}>
      Search
    </Button>
  );
}

function Dict(props: { dict: GrepResult }) {
  const entries = Object.entries(props.dict);
  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {entries.map(([key, values], i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {capitalCase(key)}
                </TableCell>
                <TableCell align="left">
                  {Array.isArray(values) ? (
                    <List>
                      {values.map((x, i) => (
                        <ListItem key={i}>
                          <Typography>
                            &#8226;
                            {x}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography>{values}</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export function EfixGrepCore() {
  const [result, setResult] = useState<GrepResults>();
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const handleResults = useCallback(
    (r: GrepResults) => {
      setLoading(false);
      setResult(r);
    },
    [setLoading, setResult]
  );

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(10,10,10,0.55)",
            zIndex: 100,
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      <FlexWrapper title="Query by Order Id" expanded>
        <InputWrapper def={orderIdDef}>
          <CustomTextField />
        </InputWrapper>
      </FlexWrapper>
      <FlexWrapper title="Query by Date, Trading Domain, and TID" divider>
        <InputWrapper def={dateDef}>
          <CustomDateField />
        </InputWrapper>
        <InputWrapper def={tradingDomainDef}>
          <CustomTextField />
        </InputWrapper>
        <InputWrapper def={tidDef}>
          <CustomTextField />
        </InputWrapper>
      </FlexWrapper>
      <FlexWrapper title="Query by other fields" divider>
        <InputWrapper def={dateForTidQueryDef}>
          <CustomDateField />
        </InputWrapper>
        <InputWrapper def={accountNameDef}>
          <CustomChoiceField singleSelect />
        </InputWrapper>
        <InputWrapper def={routeDef}>
          <CustomChoiceField singleSelect />
        </InputWrapper>
        <InputWrapper def={symbolDef}>
          <CustomChoiceField singleSelect />
        </InputWrapper>
        <InputWrapper def={priceDef}>
          <CustomNumberField hideMode />
        </InputWrapper>
        <InputWrapper def={quantityDef}>
          <CustomNumberField hideMode />
        </InputWrapper>
      </FlexWrapper>
      <FlexWrapper divider title="Narrow a query by a specific time window">
        <InputWrapper def={startTimeDef}>
          <CustomDateField />
        </InputWrapper>
        <InputWrapper def={endTimeDef}>
          <CustomDateField />
        </InputWrapper>
      </FlexWrapper>
      <FlexWrapper divider title="Additional query parameters">
        <InputWrapper def={arcaDirectFormatDef}>
          <CustomBooleanField />
        </InputWrapper>
        <InputWrapper def={batsBinaryFormatDef}>
          <CustomBooleanField />
        </InputWrapper>
        <InputWrapper def={ouchFormatDef}>
          <CustomBooleanField />
        </InputWrapper>
        <InputWrapper def={utpDirectFormatDef}>
          <CustomBooleanField />
        </InputWrapper>
        <InputWrapper def={nysePillarFormatDef}>
          <CustomBooleanField />
        </InputWrapper>
        <InputWrapper def={wireFormatDef}>
          <CustomBooleanField />
        </InputWrapper>
      </FlexWrapper>
      <FlexWrapper expanded>
        <SubmitButton onResponse={handleResults} onClick={handleClick} />
        <ClearButton onClear={() => {}} />
      </FlexWrapper>
      {result?.results?.map((result, i) => (
        <Dict key={i} dict={result} />
      ))}
    </>
  );
}
