import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { useArgs } from "../hooks/useQuery";
import { useThisNode } from "./thisNode";
import copy from "copy-to-clipboard";
import { useSetShowNotification } from "../hooks/useGlobalState";
import CustomSnackbar from "../CustomSnackbar";
import { useCallback, useState } from "react";

type Props = {
  endpoint: "orders" | "events";
};

const CopyTabURLBtn = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const node = useThisNode();
  const args = useArgs();
  const setShowNotification = useSetShowNotification();

  const handleOnClickCopyURL = useCallback(() => {
    const filterEmptyParams = Object.entries(args).filter(([key, value]) => {
      if (value!.length > 0) return { key, value };
    });

    const url = new URL(
      `${location.toString()}/${props.endpoint}/${node?.getId()}`
    );
    filterEmptyParams.map(([key, val]) => {
      // delete the old key first
      url.searchParams.delete(key);
      if (Array.isArray(val)) {
        (val as string[]).forEach((v) => url.searchParams.append(key, v));
      } else {
        // for number input field
        url.searchParams.append(key, val as string);
      }
    });

    copy(url.toString());
    setShowNotification({
      open: true,
      message: "URL Copied Successfully",
      type: "success",
    });
    handleClose();
  }, [args, node, setShowNotification, props.endpoint, handleClose]);

  return (
    <>
      <Tooltip title="More">
        <IconButton
          disableRipple
          size="small"
          sx={{ height: 24, width: 24 }}
          onClick={handleClick}
        >
          <MoreVertRoundedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "more-menu-btn",
        }}
      >
        <MenuItem sx={{ fontSize: 12 }} onClick={handleOnClickCopyURL}>
          Copy URL
        </MenuItem>
      </Menu>
      <CustomSnackbar />
    </>
  );
};

export default CopyTabURLBtn;
