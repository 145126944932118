import type { AppContext } from "next/app";
import App from "next/app";
import { UserProvider, getSession, useUser } from "@auth0/nextjs-auth0";
import React, { useState, useCallback, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import "../styles/globals.css";

// Custom Components
import CustomAppBar from "../components/header/CustomAppBar";
import getUserChoiceTheme from "../theme/Theme";
import Head from "next/head";
import { useLocalState } from "../components/hooks/useLocalState";
import LayoutTheme from "../theme/LayoutTheme";
import { UncontrolledProviders } from "../components/providers/providers";
import { TokenProvider, TokenLoader } from "../components/hooks/useToken";
import { FetchAllContextProvider } from "../components/hooks/useQuery";

import { useRouter } from "next/router";
import { CssBaseline } from "@mui/material";
import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";

type PropsWithToken = { token: string };

function SentrySetUser() {
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.email ?? "no-email",
        username: user.name ?? "no-name",
      });
    }
  }, [user]);

  return <></>;
}

// written by chatgpt
function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null;

  return function debounced(...args: Parameters<T>): void {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
  };
}

function MyApp(props) {
  // State that change theme mode
  const { Component, pageProps, token } = props;
  
  const {publicRuntimeConfig} = getConfig();
  const server_name = publicRuntimeConfig.server_name ? `| ${publicRuntimeConfig.server_name}` : null

  const router = useRouter();
  const [mode, setMode] = useLocalState<"light" | "dark">("dark", "theme");
  const theme = React.useMemo(
    () => createTheme(getUserChoiceTheme(mode ?? "dark")),
    [mode]
  );

  const toggleColorMode = useCallback(() => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  }, [setMode]);

  const eventHandler = React.useRef(
    debounce(async () => {
      if (token) {
        const resp = await fetch("/api/state", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(localStorage),
        });
      }
    }, 100)
  );

  useEffect(() => {
    document.addEventListener("storage", eventHandler.current);
    return () => document.removeEventListener("storage", eventHandler.current);
  }, []);

  const [localStorageReady, setLocalStorageReady] = useState(false);

  useEffect(() => {
    if (window && token) {
      (async () => {
        const resp = await fetch("/api/state", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (resp.ok) {
          const body = await resp.json();
          const entries = Object.entries(body)

          if (entries.length > 0) {
            localStorage.clear()

            entries.forEach(([key, value]) => {
              localStorage.setItem(key, value as any);
            });
          }
        }

        setLocalStorageReady(true);
      })();
    }
  }, [token]);

  if (!token && typeof window !== "undefined") {
    Sentry.captureMessage("No token, doing logout");
    window.location.href =
      `/api/auth/logout?returnTo=${process.env["NEXT_PUBLIC_AUTH0_BASE_URL"]}/api/auth/login&federated`;
  }

  const routersWithoutTilingWindow = router.pathname !== "/";


  if (localStorageReady) {
    return (
      <>
        <Head>
          <title>Lime Dashboard {server_name}</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <TokenProvider override={token}>
          <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <LayoutTheme mode={mode} />
            <UserProvider>
              <SentrySetUser />
              <FetchAllContextProvider>
                <UncontrolledProviders theme={theme}>
                  {!routersWithoutTilingWindow ? (
                    <CustomAppBar toggleColorMode={toggleColorMode} />
                  ) : null}
                  <Component {...pageProps} />
                </UncontrolledProviders>
              </FetchAllContextProvider>
            </UserProvider>
          </ThemeProvider>
        </TokenProvider>
      </>
    );
  }
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps_p = App.getInitialProps(appContext);

  const token_p = (async () => {
    if (appContext.ctx.req && appContext.ctx.res) {
      const session = await getSession(appContext.ctx.req, appContext.ctx.res);
      if (session) {
        return session.accessToken;
      } else {
        console.warn("No session", session);
      }
    } else {
      console.warn("No request or response object", appContext.ctx);
    }
  })();

  const appProps = await appProps_p;
  const token = await token_p;

  return { ...appProps, token };
};

export default MyApp;
