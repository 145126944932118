import { Button } from "@mui/material";

export function CustomButton(props: {
  color: string;
  onClick: () => void;
  label: string;
  "data-testid": string
}) {
  return (
    <Button
      data-testid={props["data-testid"]}
      disableElevation
      disableRipple
      sx={{
        textTransform: "none",
        borderRadius: 0.6,
        pt: 0.4,
        pb: 0.4,
        my: 1,
      }}
      variant="contained"
      color={props.color as any}
      size="large"
      onClick={props.onClick}
      fullWidth
    >
      {props.label}
    </Button>
  );
}
