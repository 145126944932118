import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { CreateStateContext } from "../hooks/stateContext";
import { Box, NoSsr, Modal } from "@mui/material";

export type ModalControls<T> = {
  hide: () => void;
} & T;

export function ModalFactory<PropsType>(
  friendlyName: string,
  Component: React.FC<ModalControls<PropsType>>
): [React.FC<React.PropsWithChildren>, (props: PropsType) => () => void] {
  type PropsWrapper =
    | {
        props: PropsType;
        visible: true;
      }
    | {
        props: {};
        visible: false;
      };

  const [PropsProvider, useProps, useSetProps] = CreateStateContext<
    PropsWrapper | undefined
  >(`modal_props:${friendlyName}`, undefined);

  const DefaultState: PropsWrapper = {
    visible: false,
    props: {},
  };

  const ModalController: React.FC<React.PropsWithChildren> = ({ children }) => {
    const props = useProps();
    const [state, setState] = useState<PropsWrapper>(DefaultState);

    useEffect(() => {
      if (props) {
        setState(props);
      }
    }, [setState, props]);

    const hide = useCallback(() => setState(DefaultState), [setState]);

    return (
      <>
        <NoSsr>
          <Modal open={state!.visible} onClose={hide}>
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                boxShadow: 24,
              }}
            >
              <Box sx={{ padding: "32px" }}>
                <Component {...(state!.props as PropsType)} hide={hide} />
              </Box>
            </Box>
          </Modal>
        </NoSsr>
        {children}
      </>
    );
  };

  const ModalProvider: React.FC<React.PropsWithChildren> = (props) => {
    return (
      <PropsProvider>
        <ModalController>{props.children}</ModalController>
      </PropsProvider>
    );
  };

  const useShowModal = (props: PropsType) => {
    const setProps = useSetProps();
    return () => {
      setProps({
        visible: true,
        props,
      });
    };
  };

  return [ModalProvider, useShowModal];
}
