import moment, { Moment } from "moment";
import { useEffect } from "react";
import getConfig from "next/config";
import { useFetcher } from "./useQuery";
import {
  CreateDependentStateContext,
  CreateStateContext,
} from "./stateContext";
import { useKind } from "../windows/thisNode";

const [AvaliableDatesProvider, useAvaliableDates, useSetAvaliableDates] =
  CreateStateContext<Array<number>>("avaliable-dates", [], false);

function AvaliableDatesRunner() {
  const fetcher = useFetcher();
  const endpoint = useKind();
  const set = useSetAvaliableDates();

  useEffect(() => {
    if (fetcher && endpoint) {
      (async () => {
        const body = await fetcher<string[]>(
          `/v2/available-indexes/${endpoint.toUpperCase()}`
        );
        if (body) {
          const dates = body.map((line) => {
            const [_, __, dateStr] = line.split("-");
            const d = moment(dateStr, "YYYY.MM.DD").valueOf();
            return d;
          });
          set(dates);
        }
      })();
    }
  }, [fetcher, endpoint, set]);

  return <></>;
}

function AvaliableDatesMapProvider(props: React.PropsWithChildren) {
  return (
    <AvaliableDatesProvider>
      <AvaliableDatesRunner />
      {props.children}
    </AvaliableDatesProvider>
  );
}

export { AvaliableDatesMapProvider, useAvaliableDates };
