import { Box, Typography } from "@mui/material";
import { ColumnDef } from "../../../types.d";
import { ArgumentFence } from "../../hooks/useQuery";
import { ColDefContext } from "../new-inputs/utils";

export type PropsWithDef = {
  def: Required<ColumnDef>;
  lock?: boolean;
  highlightStyle?: React.CSSProperties; // Optional highlight style prop
};

export function InputWrapper(props: React.PropsWithChildren<PropsWithDef>) {
  // Apply the highlightStyle if provided, else default to no additional styling
  const boxStyle = props.highlightStyle ? { ...props.highlightStyle, width: "20ch" } : { width: "20ch" };

  return (
    <ArgumentFence field={props.def.rdgColumn.key}>
      <ColDefContext.Provider value={props.def}>
        {/* Use the boxStyle which includes highlightStyle if it was passed */}
        <Box sx={boxStyle}>
          <Box>
            <Box sx={{ mx: 1 }}>
              <Typography variant="caption">
                {props.def.rdgColumn.name}
              </Typography>
            </Box>
            {props.children}
          </Box>
        </Box>
      </ColDefContext.Provider>
    </ArgumentFence>
  );
}
