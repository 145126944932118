import React from "react";
import { NotificationType } from "../../types.d";
import { CreateStateContext } from "./stateContext";
import { CreateRunnerGroup } from "./useRunner";

const [NotificationProvider, useShowNotification, useSetShowNotification] =
  CreateStateContext<NotificationType>("notifications", {
    open: false,
    type: "info",
    message: "",
  });

const [ClientSideSearchProvider, useClientSideSearch, useSetClientSideSearch] =
  CreateStateContext<boolean>("client side search", false);

const [FetchAllGridsGroup, useRegisterForFetchAll, useFetchAllGrids] =
  CreateRunnerGroup("fetch_all_grids");

export {
  NotificationProvider,
  useShowNotification,
  useSetShowNotification,
  useClientSideSearch,
  useSetClientSideSearch,
  useRegisterForFetchAll,
  useFetchAllGrids,
  FetchAllGridsGroup,
};

export const GlobalStateProvider: React.FC<React.PropsWithChildren<{}>> = (
  props
) => {
  return (
    <NotificationProvider>
      <ClientSideSearchProvider>{props.children}</ClientSideSearchProvider>
    </NotificationProvider>
  );
};

GlobalStateProvider.displayName = "standalone:GlobalStateProvider";
