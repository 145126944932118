import React, { useCallback, useEffect } from "react";
import {
  CreateStateContext,
  CreateDependentStateContext,
} from "./stateContext";
import { IJsonModel, Model } from "flexlayout-react";
import { CreateRunnerGroup } from "./useRunner";

export const DEFAULT_NAME = "default";

const [WorkspaceNamesProvider, useWorkspacesNames, useSetWorkspacesNames] =
  CreateStateContext<string[]>("workspaces", [DEFAULT_NAME], true, true);
export { useWorkspacesNames };

const [DropWorkspaceGroup, useRegisterWorkspaceToDrop, useDropWorkspace] =
  CreateRunnerGroup("drop_workspace");
export { DropWorkspaceGroup, useRegisterWorkspaceToDrop };

const [WorkspaceNameProvider, useWorkspaceName, useSetWorkspaceName] =
  CreateStateContext("workspace", DEFAULT_NAME, true, true);
export { useWorkspaceName, useSetWorkspaceName };

const [
  WorkspaceModelProvider,
  useWorkspaceModel,
  useSetWorkspaceModel,
  useSetSpecificWorkspace,
] = CreateDependentStateContext<IJsonModel>(
  useWorkspaceName,
  {
    global: {
      tabEnableClose: true,
      tabEnableFloat: false,
      tabEnableRename: false,
      tabSetEnableClose: false,
      tabSetEnableMaximize: false,
      splitterSize: 2,
    },
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          id: "tabset-1",
          children: [],
        },
      ],
    },
  },
  "workspace-model",
  true,
  true
);

export { useWorkspaceModel, useSetWorkspaceModel };

export function useAddWorkspace() {
  const set = useSetWorkspacesNames();
  const names = useWorkspacesNames();

  return useCallback(
    (name: string) => {
      if (names.includes(name)) {
        alert(`You already have a workspace named "${name}"`);
      } else {
        set((prev) => (prev ?? []).concat(name));
      }
    },
    [set, names]
  );
}

export function useDeleteWorkspace() {
  const names = useWorkspacesNames();
  const setNames = useSetWorkspacesNames();

  const name = useWorkspaceName();
  const setWorkspaceName = useSetWorkspaceName();

  const drop = useDropWorkspace();
  const setAt = useSetSpecificWorkspace();

  useEffect(() => {
    if (!names.includes(name)) {
      setWorkspaceName(names[0]);
    }
  }, [names, name, setWorkspaceName]);

  return useCallback(
    (name: string) => {
      setAt(name, undefined);
      drop();
      setNames((prev) => (prev ?? []).filter((x) => x !== name));
    },
    [setNames, drop, setAt]
  );
}

// PATCH: find a better way
function DropOrphanedLocalStates() {
  const names = useWorkspacesNames();
  useEffect(() => {
    const modelsRaw = localStorage.getItem("workspace-model");
    if (modelsRaw) {
      const modelLookup: Record<string, IJsonModel> = JSON.parse(modelsRaw);
      const allWorkspaces = Object.keys(modelLookup);
      const orphanedWorkspaces = allWorkspaces.filter(
        (x) => !names.includes(x)
      );

      const localKeys = Object.keys(localStorage);

      orphanedWorkspaces.forEach((orphanedKey) => {
        Model.fromJson(modelLookup[orphanedKey]).visitNodes((node) => {
          if (node.getType() === "tab") {
            localKeys.forEach((localKey) => {
              if (localKey.startsWith(node.getId())) {
                console.log("Dropping orphaned key", localKey);
                localStorage.removeItem(localKey);
              }
            });
          }
        });
      });
    }
  }, [names]);
  return <></>;
}

export function WorkspaceProvider(props: React.PropsWithChildren) {
  return (
    <DropWorkspaceGroup>
      <WorkspaceNamesProvider>
        <WorkspaceNameProvider>
          <DropOrphanedLocalStates />
          <WorkspaceModelProvider>{props.children}</WorkspaceModelProvider>
        </WorkspaceNameProvider>
      </WorkspaceNamesProvider>
    </DropWorkspaceGroup>
  );
}

WorkspaceProvider.displayName = "provider:workspace";
