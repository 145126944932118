import { useArgumentField, useSetArgumentField } from "../../hooks/useQuery";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React from "react";
import { useLocalState } from "../../hooks/useLocalState";
import { Box } from "@mui/system";
import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
  EventRounded,
} from "@mui/icons-material";

export function SingleDatePicker() {
  const [dateOption, setDateOption] = useLocalState("TODAY", "pbDatePicker");

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      setDateOption(event.target.value as string);
    },
    [setDateOption]
  );

  const date = useArgumentField();
  const setDate = useSetArgumentField();

  useEffect(() => {
    if (!date || date.length === 0 || dateOption === "TODAY") {
      const d = moment().format("YYYY-MM-DD");
      setDate(d);
    }
  }, [date, setDate]);

  const handleDateChange = useCallback(
    (date: any) => {
      setDate(moment(date).format("YYYY-MM-DD"));
    },
    [setDate]
  );

  return (
    <>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={dateOption}
        label="date-option"
        onChange={handleChange}
        sx={{
          borderRadius: "3px",
          marginLeft: "2px",
        }}
      >
        <MenuItem sx={{ fontSize: 12, p: "0 8px" }} value="TODAY">
          Today
        </MenuItem>
        <MenuItem sx={{ fontSize: 12, p: "0 8px" }} value="CUSTOM_DATE">
          Custom Date
        </MenuItem>
      </Select>
      {dateOption === "CUSTOM_DATE" ? (
        <Box sx={{ margin: "0 2px" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disableFuture
              reduceAnimations={true}
              label="Date desktop"
              inputFormat="MM/DD/YYYY"
              dayOfWeekFormatter={(day) => day}
              value={date}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  sx={{
                    ".MuiInputBase-root": {
                      borderRadius: "3px",
                      width: "115px",
                    },
                  }}
                />
              )}
              OpenPickerButtonProps={{
                disableRipple: true,
                sx: { pr: 1.5, pl: 0 },
              }}
              components={{
                SwitchViewButton: ({ children }) => {
                  const viewType = children.props.ownerState.openView;
                  return (
                    <IconButton size="small" sx={{ p: 0 }} disableTouchRipple>
                      {viewType === "day" ? (
                        <ArrowDropDownRounded />
                      ) : (
                        <ArrowDropUpRounded />
                      )}
                    </IconButton>
                  );
                },
                LeftArrowButton: (props) => (
                  <IconButton
                    {...props}
                    disableTouchRipple
                    sx={{ p: 0 }}
                    size="small"
                  >
                    <ChevronLeftRounded />
                  </IconButton>
                ),
                RightArrowButton: (props) => (
                  <IconButton
                    {...props}
                    disableTouchRipple
                    sx={{ p: 0 }}
                    size="small"
                  >
                    <ChevronRightRounded />
                  </IconButton>
                ),
                OpenPickerIcon: (props) => <EventRounded fontSize="small" />,
              }}
            />
          </LocalizationProvider>
        </Box>
      ) : null}
    </>
  );
}
