import { Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

interface NoRowsFallBackProps {
  height?: number | string;
  width?: number | string;
  marginTop?: number | string;
  marginLeft?: number | string;
}

function NoRowsFallBack({
  height,
  width,
  marginTop,
  marginLeft,
}: NoRowsFallBackProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width,
        height,
        marginTop,
        marginLeft,
      }}
    >
      <SentimentVeryDissatisfiedIcon />
      <Typography fontWeight={500} variant="body2" color="default">
        No Data Found
      </Typography>
    </div>
  );
}

NoRowsFallBack.displayName = "standalone:NoRowsFallBack";

export default NoRowsFallBack;
