import React, { useEffect } from "react";
import { CreateMappingProvider } from "./autocompleteMapper";

const [FirmMapProvider, useFirmMap, FirmMappingContext] = CreateMappingProvider(
  "/v2/firms",
  (body: { values: string[] }) => {
    const unique = Array.from(new Set(body.values).values());
    return unique.reduce(
      (obj, val) => ({
        ...obj,
        [val]: `${val}`,
      }),
      {}
    );
  }
);

export { FirmMapProvider, useFirmMap, FirmMappingContext };
