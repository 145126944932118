import { alpha, Button, useTheme } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ArgumentFence, useArgumentField } from "../../hooks/useQuery";
import DTRPModal from "./DTRPModal";
import AutoRefreshGridBtn from "../grid/AutoRefreshGridBtn";
import {
  useDateTimeRPData,
  useSetDateTimeRPData,
} from "../../hooks/datetimerangepicker-hook/useDateTimeRangePicker";
import { useFetchThisGrid } from "../grid/CustomDataGrid";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { AutoRefreshButton } from "../common/ControlRow";
import { useAutoRefreshData } from "../../hooks/datetimerangepicker-hook/useAutoRefresh";

export type DATETIME_TYPE = "START_DATETIME" | "END_DATETIME";
type Props = {
  field: "recorded" | "timestamp";
};

const INCREMENT_TIME_LABEL = [
  "Last 1 Minute",
  "Last 5 Minute",
  "Last 15 Minute",
];

function DTRPMain({ field }: Props) {
  return (
    <ArgumentFence field={field}>
      <DTRPModalCore />
    </ArgumentFence>
  );
}

const DTRPModalCore = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const doFetch = useFetchThisGrid();
  
  const autorefresh = useAutoRefreshData();

  const dateTimeRPData = useDateTimeRPData();
  const { startDate, endDate, defaultDateTimeLabel } =
    dateTimeRPData;

  const [queryParamsReady, setQueryParamsReady] = useState(false);
  const currentDate = useArgumentField();

  const datetime =
    moment(startDate).format("YYYY/MM/DD hh:mm:ss A") +
    " - " +
    moment(endDate).format("YYYY/MM/DD hh:mm:ss A");

  useEffect(() => {
    if (currentDate) {
      // check are query params load from localStorage
      setQueryParamsReady(true);
    }
  }, [currentDate]);

  return (
    <>
      <Button
        sx={{
          height: 24,
          borderRadius: "3px",
          textTransform: "none",
          padding: "0 6px",
          margin: "0 3px",
          background: alpha(theme.palette.success.dark, 0.1),
          color: theme.palette.success.dark,
          whiteSpace: "nowrap",
        }}
        startIcon={<DateRangeIcon />}
        disableTouchRipple
        color="success"
        size="small"
        onClick={() => setOpen(true)}
      >
        {defaultDateTimeLabel ? defaultDateTimeLabel : datetime}
      </Button>
      <DTRPModal open={open} setOpen={setOpen} />
    </>
  );
};

DTRPMain.displayName = "standalone:DTRPMain";

export default DTRPMain;
