import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Typography, Button, Snackbar } from "@mui/material";
import { useArgs, useFetcher } from "../hooks/useQuery";
import { CustomChoiceField } from "../body/new-inputs/CustomChoiceField";
import { ColumnContentType, ColumnDef } from "../../types.d";
import {
  accountNameDef,
  buyingSideDef,
  buyingTimeInForceDef,
  orderTypeDef,
  priceDef,
  quantityDef,
  routeDef,
  sideDef,
  symbolDef,
  timeInForceDef,
  tradableAccountNameDef,
  tradeEntryRouteDef,
} from "../hooks/columndefs";
import { CustomNumberField } from "../body/new-inputs/CustomNumberField";
import { SymbolLookupProvider } from "../hooks/useSymbolLookup";
import getConfig from "next/config";
import { useLocalState } from "../hooks/useLocalState";
import AlertMessage from "../body/common/AlertMessage";
import { InputWrapper, PropsWithDef } from "../body/common/InputWrapper";
import { FlexWrapper } from "../body/common/FlexWrapper";
import { ClearButton } from "../body/common/Clear";
import {
  FetchRoutesByAccountProvider,
  TradeEntryRoutesContext,
} from "../hooks/useFetchRoutesbyAccountNum";

function ChoiceWrapper(props: PropsWithDef) {
  return (
    <InputWrapper def={props.def} lock={props.lock}>
      <CustomChoiceField singleSelect disabled={props.lock} />
    </InputWrapper>
  );
}

function RouteWrapper(props: PropsWithDef) {
  return (
    <InputWrapper
      def={{
        ...routeDef,
        autocomplete: TradeEntryRoutesContext,
      }}
      lock={props.lock}
    >
      <CustomChoiceField singleSelect disabled={props.lock} />
    </InputWrapper>
  );
}

function PriceWrapper() {
  const args = useArgs();
  const disabled = useMemo(() => !args["orderType"]?.includes("limit"), [args]);
  return (
    <InputWrapper def={priceDef} lock={false}>
      <CustomNumberField hideMode disabled={disabled} />
    </InputWrapper>
  );
}

const VALID_LETTERS = "1234567890ABCDEFGHIJKLMNOPQRSTUV";
function generateId(): string {
  var id = "";

  for (let i = 0; i < 8; i++) {
    const cidx = Math.floor(Math.random() * VALID_LETTERS.length);
    id += VALID_LETTERS[cidx];
  }
  return id;
}

type SubmitResponse = {
  clientOrderID: string;
  eventType: "OnOrderAckEvent" | string;
  reason: string;
};

function SubmitButton(props: { onResponse: (resp: SubmitResponse) => void }) {
  const args = useArgs();
  const fetcher = useFetcher("trade");
  const [cancelReplace] = useLocalState(false, "cancel_replace", false);
  const [original] = useLocalState<ColumnContentType | undefined>(
    undefined,
    "original",
    false
  );

  const getArgVal = useCallback(
    (def: Required<ColumnDef>) => {
      const key = def.rdgColumn.key;
      if (args && key in args) {
        const val = Array.isArray(args[key]) ? args[key]![0] : args[key];
        if ((def.type === "number" || def.type === "money") && val) {
          const v = Array.isArray(val) ? val[0] : val;
          const [_, n] = v.split(":");
          return parseFloat(n);
        } else {
          return val;
        }
      } else {
        return undefined;
      }
    },
    [args]
  );

  const enabled: boolean = useMemo(() => {
    var x =
      getArgVal(accountNameDef) &&
      getArgVal(symbolDef) &&
      getArgVal(orderTypeDef) &&
      getArgVal(sideDef) &&
      getArgVal(quantityDef)
        ? true
        : false;

    if (getArgVal(orderTypeDef) === "limit") {
      x = x && (getArgVal(priceDef) ? true : false);
    }

    return x;
  }, [getArgVal]);

  const submitOrder = useCallback(() => {
    if (fetcher && enabled) {
      (async () => {
        if (cancelReplace) {
          if (original) {
            const resp: SubmitResponse | undefined = await fetcher(
              `/v2/trading/${original["account"]}/orders/${original["clOrdId"]}/replace`,
              {},
              "PATCH",
              {
                newClientOrderID: generateId(),
                newPrice: getArgVal(priceDef),
                newQuantity: getArgVal(quantityDef),
              }
            );
            if (resp) {
              props.onResponse(resp);
            } else {
              alert("Unable to replace this order");
              throw "unable to replace order";
            }
          }
        } else {
          const body = {
            ...(getArgVal(priceDef) ? { price: getArgVal(priceDef) } : {}),
            ...(getArgVal(tradeEntryRouteDef)
              ? { route: getArgVal(tradeEntryRouteDef) }
              : {}),
            ...(getArgVal(timeInForceDef)
              ? { timeInForce: getArgVal(timeInForceDef) }
              : {}),
            clientOrderID: generateId(),
            quantity: getArgVal(quantityDef),
            side: getArgVal(sideDef)?.toString().toUpperCase(),
            symbol: getArgVal(symbolDef),
            type: getArgVal(orderTypeDef)?.toString().toUpperCase(),
          };
          const accountNumber = getArgVal(accountNameDef);
          if (accountNumber) {
            const resp: SubmitResponse | undefined = await fetcher(
              `/v2/trading/${accountNumber}/order`,
              {},
              "POST",
              body
            );
            console.log("Trade submitted", resp);
            if (resp) {
              props.onResponse(resp);
            }
          } else {
            throw "unable to find account number";
          }
        }
      })();
    }
  }, [fetcher, enabled, getArgVal, cancelReplace, original, props]);

  return (
    <Button
      variant="contained"
      color="success"
      onClick={submitOrder}
      disabled={!enabled}
    >
      {cancelReplace ? "Cancel and Replace" : "Submit"}
    </Button>
  );
}

export function TradeEntryCore() {
  const [resp, setResp] = useState<SubmitResponse | undefined>();
  const [lockInputs] = useLocalState(false, "cancel_replace", false);

  const handleClear = useCallback(() => {
    setResp(undefined);
  }, [setResp]);

  const { publicRuntimeConfig } = getConfig();

  if (!publicRuntimeConfig.enable_trade_submit) {
    return <Typography variant="h1">Coming soon</Typography>;
  }

  return (
    <>
      <FlexWrapper expanded>
        <ChoiceWrapper def={tradableAccountNameDef} lock={lockInputs} />
        <SymbolLookupProvider>
          <ChoiceWrapper def={symbolDef} lock={lockInputs} />
        </SymbolLookupProvider>
        <ChoiceWrapper def={orderTypeDef} lock={lockInputs} />
        <ChoiceWrapper def={buyingSideDef} lock={lockInputs} />
        <ChoiceWrapper def={buyingTimeInForceDef} lock={lockInputs} />

        <FetchRoutesByAccountProvider>
          <RouteWrapper def={tradeEntryRouteDef} lock={lockInputs} />
        </FetchRoutesByAccountProvider>

        <InputWrapper def={quantityDef} lock={false}>
          <CustomNumberField hideMode noDecimals />
        </InputWrapper>
        <PriceWrapper />
      </FlexWrapper>
      <FlexWrapper expanded>
        <SubmitButton onResponse={setResp} />
        {lockInputs ? <></> : <ClearButton onClear={handleClear} />}
      </FlexWrapper>
      {resp ? (
        <Snackbar
          open={true}
          onClose={handleClear}
          autoHideDuration={60000}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div>
            {resp.eventType === "OnOrderAckEvent" ||
            resp.eventType === "OnReplaceActEvent" ? (
              <AlertMessage
                title="Success"
                severity="success"
                onClose={handleClear}
              >
                - Order: <strong>{resp.clientOrderID}</strong> placed
                successfully
                <br />- {resp.eventType}
              </AlertMessage>
            ) : (
              <AlertMessage
                title="Error"
                severity="error"
                onClose={handleClear}
              >
                - Failed to place order: <strong>{resp.clientOrderID}</strong>
                <br />- {resp.eventType}
              </AlertMessage>
            )}
          </div>
        </Snackbar>
      ) : null}
    </>
  );
}
