import { useEffect } from "react";
import { CreateStateContext } from "./stateContext";
import { useArgs, useFetcher } from "./useQuery";
import { AutocompleteContextValueType } from "../../types.d";

const [
  TradeEntryRoutesProvider,
  useTradeEntryRoutes,
  useSetTradeEntryRoute,
  TradeEntryRoutesContext,
] = CreateStateContext<AutocompleteContextValueType>("tradeentryroutes", {});

function FetchRoutesByAccountRunner(props: {}) {
  const args = useArgs();
  const fetcher = useFetcher("trade");
  const set = useSetTradeEntryRoute();

  useEffect(() => {
    (async () => {
      if (fetcher) {
        const account = Array.isArray(args["accountName"])
          ? args["accountName"][0]
          : args["accountName"];

        if (account) {
          const body: string[] | undefined = await fetcher(
            `v2/trading/${account}/venues`
          );

          if (body) {
            set(
              body.reduce((obj, route) => {
                return {
                  ...obj,
                  [route]: route,
                };
              }, {})
            );
          }
        } else {
          set({});
        }
      }
    })();
  }, [fetcher, args]);

  return <></>;
}

export function FetchRoutesByAccountProvider(props: React.PropsWithChildren) {
  return (
    <TradeEntryRoutesProvider>
      <FetchRoutesByAccountRunner />
      {props.children}
    </TradeEntryRoutesProvider>
  );
}

export { TradeEntryRoutesContext };
