import { useCallback, useState } from "react";
import { useResult } from "../../../hooks/useQuery";
import { useSetSelectedRows } from "../../../hooks/useSelectedRows";

const CheckboxFormatter = (props) => {
  const [checked, setChecked] = useState(false);

  const rows = useResult();
  const setSelectedRows = useSetSelectedRows();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(e.target.checked);
      if (e.target.checked) {
        const rowKeys = rows.map(row => row.key);
        setSelectedRows(new Set(rowKeys));
      } else {
        setSelectedRows(new Set());
      }
    },
    [setChecked, setSelectedRows, rows]
  );

  return (
    <input
      type="checkbox"
      style={{ margin: "12px", cursor: "pointer" }}
      checked={checked}
      onChange={handleChange}
    />
  );
};

CheckboxFormatter.displayName = "standalone:CheckboxFormatterComponent";
export default CheckboxFormatter;
