import React, { useCallback, useEffect, useState } from "react";
import {
  ButtonGroup,
  MenuItem,
  NoSsr,
  SelectChangeEvent,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  useColDef,
  isValidMode,
  isValidOperator,
  Operator,
  ModeMapReversed,
  ModeMap,
} from "./utils";
import { useArgs, useArgumentField, useSetArgumentField } from "../../hooks/useQuery";
import {
  FlatOnLeftSelect,
  FlatOnRightTextField,
  RoundedTextField,
} from "./StyledTextField";
import { Close } from "@mui/icons-material";

export type CustomNumberFieldProps = {
  hideMode?: boolean;
  disabled?: boolean;
  noDecimals?: boolean;
};

export function CustomNumberField(props: CustomNumberFieldProps) {
  const args = useArgs();
  const def = useColDef();

  const rawValue = useArgumentField();
  const setValue = useSetArgumentField();

  const [state, setState] = useState<[Operator, string]>(["=", ""]);
  const [clearing, setClearing] = useState(false);

  useEffect(() => {
    if (Object.entries(args).length <= 0) {
      // someone just clicked reset all button
      setClearing(true);
    }
  }, [args])

  useEffect(() => {
    const value = Array.isArray(rawValue) ? rawValue[0] : rawValue;
    const [operator, content] = state;
    const uiHasBeenChanged = operator === "=" && content === "" ? false : true;

    const set = () => {
      if (content) {
        setValue(
          `${ModeMap[operator]}:${(props.noDecimals ? parseInt : parseFloat)(
            content
          )}`
        );
      }
    };

    if (value?.includes("NaN")) {
      setValue("");
      return;
    }

    if (clearing) {
      setValue("");
      setState(["=", ""]);
      setClearing(false);
    } else {
      if (value) {
        if (uiHasBeenChanged) {
          set();
        } else {
          const s = value.split(":");
          if (s.length == 2 && isValidMode(s[0])) {
            s[0] = ModeMapReversed[s[0]];
            setState(s as [Operator, string]);
          }
        }
      } else {
        set();
      }
    }
  }, [rawValue, state, clearing]);

  const handleChangeOperator = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const v = e.target.value as string;
      if (isValidOperator(v)) {
        setState((prev) => [v, prev[1]]);
      }
    },
    [setState]
  );

  const handleChangeNumber: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (e) => {
      if (e.target.value.length > 0) {
        setState((prev) => [prev[0], e.target.value]);
      } else {
        setClearing(true);
      }
    },
    [setState]
  );

  const handleClear = useCallback(() => {
    setClearing(true);
  }, [setClearing]);

  const InputComponent = props.hideMode
    ? RoundedTextField
    : FlatOnRightTextField;

  const [operator, content] = state;

  return (
    <NoSsr>
      <ButtonGroup>
        {props.hideMode ? (
          <></>
        ) : (
          <Tooltip title={content ? "" : "Enter a number"}>
            <FlatOnLeftSelect
              disabled={content ? false : true}
              defaultValue="="
              value={operator}
              onChange={handleChangeOperator}
              sx={{ borderTopLeftRadius: "3px" }}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    display: "block",
                    padding: 0,
                  },
                },
                PaperProps: {
                  elevation: 1,
                },
              }}
            >
              {Object.keys(ModeMap).map((op, i) => (
                <MenuItem key={i} value={op}>
                  {op}
                </MenuItem>
              ))}
            </FlatOnLeftSelect>
          </Tooltip>
        )}
        <InputComponent
          id={`ch-numberField-${def.rdgColumn.key}`}
          label={def.rdgColumn.name}
          variant="filled"
          type="number"
          value={content}
          onChange={handleChangeNumber}
          disabled={props.disabled ?? false}
          sx={{
            opacity: props.disabled ? 0.5 : 1,
            width: "100%",
          }}
          InputProps={{
            endAdornment: content ? (
              <IconButton
                id={`btn-clear-${def.rdgColumn.key}`}
                onClick={handleClear}
                disableTouchRipple
                sx={{ height: "100%", p: 0.2, borderRadius: "5px" }}
                size="small"
              >
                <Close fontSize="small" />
              </IconButton>
            ) : null,
          }}
        />
      </ButtonGroup>
    </NoSsr>
  );
}
