import { Box } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect } from "react";
import CustomDataGrid from "../../components/body/grid/CustomDataGrid";
import {
  SetOfColumnsForOrders,
  SetOfColumnsForEvents,
} from "../../components/hooks/columndefs";
import {
  RowStacks,
  CancelSelectedOrdersButton,
  ExportMenu,
  RestartButton,
  SearchButton,
  ChooseColumns,
  CancelReplaceButton,
  AutoRefreshButton,
} from "../../components/body/common/ControlRow";
import DTRPMain from "../body/date-picker/DTRMain";
import { AvaliableDatesMapProvider } from "../hooks/useAvaliableDatesMap";
import useValidateUrl from "../hooks/copy-url-validation-hooks/useValidateUrl";
import CopyTabURLBtn from "./CopyTabURLBtn";
import { DateTimeRangePickerProvider } from "../hooks/datetimerangepicker-hook/useDateTimeRangePicker";
import { AutoRefreshProvider } from "../hooks/datetimerangepicker-hook/useAutoRefresh";

const Modes = {
  orders: {
    datePickerField: "recorded",
    columnSet: SetOfColumnsForOrders,
    enableCancelSelected: true,
    urlPath: "/v2/orders",
    enableColumnPrefix: true,
  },
  events: {
    datePickerField: "timestamp",
    columnSet: SetOfColumnsForEvents,
    enableCancelSelected: false,
    urlPath: "/v2/events",
    enableColumnPrefix: false,
  },
} as const;

export function OrdersEventsCore(props: { mode: keyof typeof Modes }) {
  const config = Modes[props.mode];
  const router = useRouter();
  const validateURL = useValidateUrl();

  useEffect(() => {
    if (router.isReady) {
      validateURL(config.columnSet);
    }
  }, [router.isReady, config.columnSet, validateURL]);

  return (
    <AvaliableDatesMapProvider>
      <AutoRefreshProvider>
        <DateTimeRangePickerProvider>
          <RowStacks
            leftRowStack={
              <>
                <DTRPMain field={config.datePickerField} />
                <AutoRefreshButton />
              </>
            }
            rightRowStack={
              <>
                <SearchButton />
                <RestartButton />
                {config.enableCancelSelected ? (
                  <CancelSelectedOrdersButton />
                ) : null}
                <CancelReplaceButton />
                <ExportMenu />
                <ChooseColumns />
                <CopyTabURLBtn endpoint={props.mode} />
              </>
            }
          />
        </DateTimeRangePickerProvider>
      </AutoRefreshProvider>
      <Box
        sx={{
          display: "flex",
          width: "inherit",
          position: "relative",
          flexDirection: "column",
          height: "calc(100% - 30px)",
          overflow: "hidden",
        }}
      >
        <CustomDataGrid
          showHeaderFilters
          path={config.urlPath}
          enableColumnPrefix={config.enableColumnPrefix}
          parseList={(obj: any) => obj.values}
          parseTotal={(obj: any) => obj.total}
        />
      </Box>
    </AvaliableDatesMapProvider>
  );
}
