import React, { useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Button,
  Modal,
  Typography,
  LinearProgress,
  FilledInput,
} from "@mui/material";
import { CancelResp } from "../../hooks/useCancel";

export function ErrorBox(props: { resps: CancelResp[] }) {
  return (
    <Box
      sx={{
        overflowY: "scroll",
        height: props.resps.length > 0 ? "20vh" : 0,
      }}
    >
      {props.resps.map((resp, i) => {
        var color: string;
        var message: string;

        switch (resp.eventType) {
          case "OnCancelAckEvent":
            message = `Successfully canceled order ${resp.clientOrderID}`;
            color = "success.main";
            break;
          case "OnCancelRejectEvent":
            message = `Order ${resp.clientOrderID} is already canceled`;
            color = "error.main";
            break;
          case "ok":
            message = `Canceled all orders for ${resp.clientOrderID}`;
            color = "success.main";
            break;

          default:
            message = `Unknown problem: ${resp.eventType}, ${resp.reason}`;
            color = "warning.main";
        }

        return (
          <Box
            key={i}
            sx={{
              margin: 1,
              padding: 1,
              borderRadius: 2,
              backgroundColor: color,
            }}
          >
            <Typography variant="body2">{message}</Typography>
          </Box>
        );
      })}
    </Box>
  );
}
