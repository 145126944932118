import { AlertColor } from "@mui/material";
import React from "react";
import { Column } from "react-data-grid";

export interface Trade {
  key: string;
  name: string;
  id: number;
  orderId: number;
  execId: string;
  tid: string;
  route: string;
  lastMarket: string;
  account: string;
  accountName: string;
  tradingDomain: string;
  price: number;
  avgPrice: number;
  quantity: number;
  side: string;
  symbol: string;
  symbolUnderlying: string;
  status: string;
  securityType: string;
  positionEffect: string;
  platform: string;
  text: string;
  timestamp: Date;
  firmId: string;
  fixMsgIn: string;
  fixMsgOut: string;
  efixReceived: string;
  efixTransmitted: string;
  efixHub: string;
  senderId: string;
  targetId: string;
  clOrdId: string;
  algo: string;
  algoParentId: string;
  algoRouteParentId: string;
  type: string;
  expanded: boolean;
}

export type TradeArgs =
  | "key"
  | "id"
  | "firmId"
  | "execId"
  | "route"
  | "lastMarket"
  | "account"
  | "accountName"
  | "tradingDomain"
  | "price"
  | "avgPrice"
  | "quantity"
  | "side"
  | "symbol"
  | "symbolUnderlying"
  | "status"
  | "timestamp"
  | "platform"
  | "efixHub"
  | "senderId"
  | "targetId"
  | "sort"
  | "limit"
  | "offset";

export interface Order {
  key: string;
  name: string;
  id: number;
  orderId: number;
  route: string;
  account: string;
  accountName: string;
  tradingDomain: string;
  price: number;
  avgPrice: number;
  quantity: number;
  filledQuantity: number;
  totalCost: number;
  orderType: number;
  securityType: string;
  positionEffect: string;
  side: string;
  timeInForce: Date;
  symbol: string;
  symbolUnderlying: string;
  state: string;
  status: string;
  recorded: string;
  accepted: string;
  completed: string;
  lastChange: string;
  parentId: string;
  firmId: string;
  replacedBy: string;
  rejectReason: string;
  platform: string;
  efixHub: string;
  senderId: string;
  targetId: string;
  clOrdId: string;
  algo: string;
  algoParentId: string;
  algoRouteParentId: string;
  clientAccount: string;
  expanded: boolean;
  type: string;
}

export type OrderArgs =
  | "key"
  | "id"
  | "firmId"
  | "route"
  | "account"
  | "accountName"
  | "tradingDomain"
  | "price"
  | "avgPrice"
  | "quantity"
  | "filledQuantity"
  | "totalCost"
  | "orderType"
  | "side"
  | "symbol"
  | "symbolUnderlying"
  | "positionEffect"
  | "securityType"
  | "state"
  | "status"
  | "recorded"
  | "lastChange"
  | "timestamp"
  | "timeInForce"
  | "platform"
  | "efixHub"
  | "senderId"
  | "targetId"
  | "parentId"
  | "replacedBy"
  | "algoParentId"
  | "algoRouteParentId"
  | "clOrdId"
  | "sort"
  | "limit"
  | "clientAccount";

type AutocompleteContextValueType = Record<string, string> | undefined;
type AutocompleteContextType = React.Context<AutocompleteContextValueType>;

interface Extras {
  _extras?: Partial<{
    origKey: string;
    choices: Array<string>;
    order: boolean;
    trade: boolean;
    hide: boolean;
    selected: boolean;
    defaultChecked: boolean;
    autocomplete: AutocompleteContextType;
  }>;
}

interface Risk {
  orderId: string;
  symbol: string;
  returnType: string;
  timestamp: string;
  limitPrice: number;
  ask: number;
  lastPrice: number;
  closePrice: number;
  referencePrice: number;
  lowerBound: number;
  upperBound: number;
  bid: number;
}

export type Columns<T> = Array<Column<Order | Trade> & Extras>;
export type EventsRiskType = Array<Column<Trade> & Risk>;

export type NotificationType = {
  anchorOrigin?: SnackbarOrigin;
  autoHideDuration?: number;
  type: AlertColor;
  message: string;
  open: boolean;
  transitionProps?: React.ReactElement;
  variant?: string;
  duration?: number;
};

export type ColumnContentType = Record<string, string | number>;
export type MasterDetailsColContentType = ColumnContentType & {
  riskData: [];
  tradeDetailsData: Array<ColumnContentType>;
};
export type LimeColumn = Column<ColumnContentType, any>;

export const ALL_TYPES = [
  "text",
  "number",
  "choice",
  "money",
  "date",
  "autocomplete",
  "datetime",
  "time",
  "boolean",
] as const;
export type ColumnTypes = (typeof ALL_TYPES)[number];
export type ArrayOfColumnTypes = Array<ColumnTypes>;
export type SetOfColumnDefs = Array<Required<ColumnDef>>;
export const LookupTabName = {
  orders: "Order Search",
  events: "Trade Search",
  buyingpower: "Buying Power",
  positions: "Positions",
  tradeEntry: "Trade Entry",
  efixGrep: "Efix Grepper",
  complianceQueryTool: "Compliance Query Tool"
} as const;

export type AvaliableTabs = keyof typeof LookupTabName;

export type ColumnDef = {
  rdgColumn: LimeColumn; // column definition for react-data-grid
  visibleByDefault?: boolean; // if this column is visible by default on the data grid
  autocomplete?: AutocompleteContextType; // for the autocomplete feature
  // choices?: { [key: string]: string }; // selectable choices for dropdowns
  choices?: Record<string, string>; // selectable choices for dropdowns
  type?: ColumnTypes; // standard type for column
  apiKeyOverride?: Record<AvaliableTab, string>; // use this for the query key durring fetching
  enableHeaderFilter?: boolean;
  hideSummary?: boolean;
  allowCustomChoices?: boolean;
  isUpperCase?: boolean;
}

type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value | undefined ? P : never]: T[P];
};

type GrepResult = {
  canonicalMarkup: string;
  classes: string;
  destination: number;
  domain: number;
  externalID: string;
  flags: string;
  openOrderBP: number;
  receiveMarkup: string;
  receivedMessage: string;
  source: number;
  tid: number;
  timestamp: number;
  transmittedMessage: string;
};

type GrepResults = {
  results?: Array<GrepResult>;
  errorMsg?: string;
};

type GrepQueryResponse = Array<GrepResults> | { err: string; resp?: any };

export type GreenlightAccount = {
  id: string;
  name: string;
  ucsPath: string;
};

export type GreenlightUser = {
  employee?: boolean;
  tradeableAccounts?: GreenlightAccount[];
  userName?: string;
  viewableAccounts?: GreenlightAccount[];
};
