import { useEffect } from "react";
import { CreateMappingProvider } from "./autocompleteMapper";
import { CreateStateContext } from "./stateContext";
import { useFetcher } from "./useQuery";

export const [
  ViewableAccountsProvider,
  useViewableAccounts,
  ViewableAccountsContext,
] = CreateMappingProvider(
  "v2/viewable/accounts",
  (obj) =>
    // Flips the keys and values of obj.accounts
    Object.fromEntries(Object.entries(obj.accounts).map(([x, y]) => [y, x])),
  "search"
);

export const [
  TradableAccountsProvider,
  useTradableAccounts,
  TradableAccountsContext,
] = CreateMappingProvider("v2/trading/accounts", (x) => x.accounts, "trade");

const [EmployeeProvider, useIsEmployee, useSetIsEmployee] = CreateStateContext(
  "is_employee",
  undefined,
  false,
  false
);

export { useIsEmployee };

function IsEmployeeRunner() {
  const fetcher = useFetcher("trade");
  const set = useSetIsEmployee();
  useEffect(() => {
    (async () => {
      if (fetcher) {
        const resp = await fetcher("v2/trading/accounts");
        if (resp) {
          set(resp.employee);
        }
      }
    })();
  }, [fetcher, set]);
  return <></>;
}

export function AccountsProvider(props: React.PropsWithChildren) {
  return (
    <EmployeeProvider>
      <IsEmployeeRunner />
      <ViewableAccountsProvider>
        <TradableAccountsProvider>{props.children}</TradableAccountsProvider>
      </ViewableAccountsProvider>
    </EmployeeProvider>
  );
}
