import React, {
  createContext,
  useReducer,
  useState,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { Column } from "react-data-grid";
import {
  AutocompleteContextType,
  AutocompleteContextValueType,
  Order,
  Trade,
  SetOfColumnDefs,
  ColumnDef,
  ColumnTypes,
  AvaliableTabs,
} from "../../types.d";
import { COLUMN_PREFIX, AllDefs } from "./columndefs";
import { useLocalReducer } from "./useLocalState";
import * as Sentry from "@sentry/nextjs";
import { useKind } from "../windows/thisNode";

export interface ColumnConfigState {
  readonly column: Required<ColumnDef>;
  visible: boolean;
}

type OrderedColumnActions =
  | {
      type: "reset";
    }
  | {
      type: "swap";
      from: string;
      to: string;
    }
  | {
      type: "toggle-visible";
      key: string;
    }
  | {
      type: "toggle-all";
    }
  | {
      type: "restore";
    }
  | {
      type: "pinned";
      key: string;
    }
  | {
      type: "load";
      columnSet: SetOfColumnDefs;
    };

export type ColumnsSaveState = {
  key: string;
  visible: boolean;
  pinned: boolean;
};
export type LookupColumnSaveState = Record<
  AvaliableTabs,
  Array<ColumnsSaveState>
>;
export type ReducerState = {
  options: ColumnsSaveState[];
  original: ColumnsSaveState[];
};

export const reducer: React.Reducer<ReducerState, OrderedColumnActions> = (
  previous,
  action
) => {
  var state: ReducerState = {
    options: (previous.options ?? []).map((x) => Object.assign({}, x)),
    original: (previous.original ?? []).map((x) => Object.assign({}, x)),
  };

  const findIndex = (key: string) => {
    const i = state.options.findIndex((x) => x.key === key);
    if (i < 0) {
      throw "unable to find column";
    }
    return i;
  };

  switch (action.type) {
    case "load":
      {
        state.original = action.columnSet.map((x) => ({
          key: x.rdgColumn.key,
          pinned: false,
          visible: x.visibleByDefault,
        }));

        const originalKeys = state.original.map((x) => x.key);
        const optionKeys = state.options.map((x) => x.key);

        if (
          !originalKeys.every((originalKey) => optionKeys.includes(originalKey))
        ) {
          state.options = state.original;
        }
      }
      break;

    case "reset":
    case "restore":
      state.options = state.original;
      break;

    case "swap":
      {
        const from = findIndex(action.from);
        const to = findIndex(action.to);

        state.options.splice(to, 0, state.options.splice(from, 1)[0]);
      }
      break;

    case "toggle-visible":
      {
        const i = findIndex(action.key);
        state["options"][i]["visible"] = !state["options"][i]["visible"];
      }
      break;

    case "toggle-all":
      {
        const n = state.options.every((x) => x.visible);
        state.options.forEach((_, i) => (state.options[i].visible = !n));
      }
      break;

    case "pinned":
      {
        const i = findIndex(action.key);
        state.options[i].pinned = !state.options[i].pinned;
      }
      break;

    default:
      Sentry.captureException(new Error("unknown action: " + action));
      console.warn("unknown action", action);
  }

  return state;
};

const DataGridColumnsContext = createContext<Array<Required<ColumnDef>>>([]);
const DispatchContext = createContext<
  React.Dispatch<OrderedColumnActions> | undefined
>(undefined);
const ColumnConfigContext = createContext<Array<ColumnConfigState>>([]);

export const DataGridColumnsProvider: React.FC<
  React.PropsWithChildren<{ columnSet: SetOfColumnDefs }>
> = (props) => {
  const [columnsLookup, changeColumnsLookup] = useLocalReducer(
    "columns",
    reducer,
    {
      options: [],
      original: [],
    }
  );

  const configColumns: ColumnConfigState[] = useMemo(() => {
    return columnsLookup.options.map((column) => {
      const c = AllDefs.find((x) => column.key === x.rdgColumn.key);
      if (c) {
        const x: ColumnConfigState = {
          column: c,
          visible: column.visible,
        };
        (x.column.rdgColumn as any).frozen = column.pinned;
        return x;
      } else {
        throw "configColumns unable to find def";
      }
    });
  }, [columnsLookup]);

  const gridColumns: Required<ColumnDef>[] = useMemo(
    () =>
      columnsLookup.options
        .filter((x) => x.visible)
        .map((x) => {
          const d = AllDefs.find((d) => d.rdgColumn.key === x.key);
          if (d) {
            return d;
          } else {
            throw "gridColumns unable to find def";
          }
        }),
    [columnsLookup]
  );

  useEffect(() => {
    changeColumnsLookup({
      type: "load",
      columnSet: props.columnSet,
    });
  }, [props.columnSet, changeColumnsLookup]);

  return (
    <DispatchContext.Provider value={changeColumnsLookup}>
      <DataGridColumnsContext.Provider value={gridColumns}>
        <ColumnConfigContext.Provider value={configColumns}>
          {props.children}
        </ColumnConfigContext.Provider>
      </DataGridColumnsContext.Provider>
    </DispatchContext.Provider>
  );
};

DataGridColumnsProvider.displayName = "standalone:DataGridColumnsProvider";

export const useColumnsForDatagrid = (enablePrefix: boolean) => {
  const endPoint = useKind();
  const cols = useContext(DataGridColumnsContext);

  return useMemo(() => {
    const columnPrefix = enablePrefix ? COLUMN_PREFIX : [];
    return [...columnPrefix, ...cols.map((x) => x.rdgColumn)];
  }, [cols, enablePrefix]);
};
export const useVisibleDatagridColumns = () =>
  useContext(DataGridColumnsContext);
export const useColumnConfigs = () => useContext(ColumnConfigContext);

export function useChangeColumns() {
  const change = useContext(DispatchContext);

  if (typeof change === "undefined") {
    throw "useChangeColumns not in context";
  }

  return change;
}

export function useToggleVisibleColumn(colKey: string): [boolean, () => void] {
  const change = useContext(DispatchContext);

  if (!change) {
    throw "useToggleVisibleColumn not in context";
  }

  const columns = useColumnConfigs();
  const column = useMemo(() => {
    const c = columns.find((x) => x.column.rdgColumn.key === colKey);
    if (!c) {
      throw "unable to find column " + colKey;
    }
    return c;
  }, [columns, colKey]);

  return [
    column.visible,
    () => {
      change({
        type: "toggle-visible",
        key: colKey,
      });
    },
  ];
}

export function useToggleAll(): [boolean, () => void] {
  const states = useColumnConfigs();
  const all = useMemo(() => states.every((x) => x.visible), [states]);
  const change = useContext(DispatchContext);

  if (!change) {
    throw "useToggleAll not in context";
  }

  return [
    all,
    () => {
      change({
        type: "toggle-all",
      });
    },
  ];
}

export function useRestore() {
  const change = useContext(DispatchContext);

  if (!change) {
    throw "useRestore not in context";
  }

  return () => {
    change({
      type: "restore",
    });
  };
}
