import React, {
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";
import { ModalFactory } from "./modal";
import {
  Box,
  Typography,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  useArgumentField,
  useSetArgumentField,
  ArgumentFence,
} from "../hooks/useQuery";
import { useColumnConfigs } from "../hooks/useColumns";
import { ColumnDef } from "../../types.d";
import { Choice } from "../body/new-inputs/utils";
import { recordToChoices } from "../body/new-inputs/CustomChoiceField";
import { RoundedTextField } from "../body/new-inputs/StyledTextField";

interface InputModalProps {
  colDef: Required<ColumnDef>;
}

const ManageInputCore = (props: InputModalProps) => {
  const value = useArgumentField();
  const setValue = useSetArgumentField();
  const columns = useColumnConfigs();

  const def = props.colDef;

  const autocompleteChoices = useContext(def.autocomplete);
  const options: Choice[] = useMemo(() => {
    if (def.type === "autocomplete") {
      if (autocompleteChoices) {
        return recordToChoices(autocompleteChoices);
      }
    } else if (def.type === "choice" && def.choices) {
      return recordToChoices(def.choices);
    } else {
      throw "incompatible type " + def.type;
    }
    return [];
  }, [autocompleteChoices, def]);

  const handleDelete = useCallback(
    (index: number) => () => {
      setValue((prev) =>
        Array.isArray(prev) ? prev!.filter((_, i) => i !== index) : prev
      );
    },
    [setValue]
  );

  const toggleItem = useCallback(
    (c: Choice) => {
      return () => {
        setValue((prev) => {
          if (prev) {
            if (prev.includes(c.value)) {
              if (Array.isArray(prev)) {
                return prev.filter((x) => x !== c.value);
              } else {
                return prev.split(",").filter((x) => x !== c.value);
              }
            } else {
              return prev.concat(c.value);
            }
          } else {
            return [c.value];
          }
        });
      };
    },
    [setValue]
  );

  const [search, setSearch] = useState("");

  function ChipGroup(props: { selected: boolean }) {
    return (
      <>
        {options
          .filter((x) => {
            const a = value?.includes(x.value);
            const b = !props.selected;
            return (a && !b) || (!a && b);
          })
          .filter((o) =>
            search ? o.label.toLowerCase().includes(search.toLowerCase()) : true
          )
          .map((word, index) => (
            <>
              <Chip
                data-testid={`chip${word}`}
                key={index}
                label={word.label}
                size="small"
                onDelete={toggleItem(word)}
                color={props.selected ? "success" : "default"}
                deleteIcon={
                  value?.includes(word.value) ? (
                    <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )
                }
                sx={{
                  m: 0.1,
                  p: 0.1,
                }}
              />
            </>
          ))}
      </>
    );
  }

  return (
    <>
      <Typography>
        Add to {props.colDef.rdgColumn.name}
        {props.colDef.rdgColumn.name.toString().includes(" ") ? "'" : ""}s
      </Typography>
      <RoundedTextField
        variant="filled"
        sx={{
          width: "100%",
          my: 0.5,
        }}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Box
        sx={{
          height: "30vh",
          overflowX: "none",
          overflowY: "scroll",
        }}
      >
        <ChipGroup selected={true} />
        <ChipGroup selected={false} />
      </Box>
    </>
  );
};

const [ManageInputModalProvider, useShowManageInputModal] =
  ModalFactory<InputModalProps>("manage inputs", (props) => {
    return (
      <ArgumentFence field={props.colDef.rdgColumn.key}>
        <ManageInputCore {...props} />
      </ArgumentFence>
    );
  });

export { ManageInputModalProvider, useShowManageInputModal };
