import { nanoid } from "nanoid";
import { useLayout } from "./controller";
import { AvaliableTabs, LookupTabName } from "../../types.d";
import { useIsEmployee } from "../hooks/useAccounts";
import { useCallback } from "react";

export function useAddTab() {
  const layout = useLayout();
  const isEmployee = useIsEmployee();

  return useCallback(
    (
      kind: AvaliableTabs,
      tabSetId: string,
      initState?: Record<string, any>
    ) => {
      if (!layout) {
        throw "no layout";
      }

      const id = nanoid(8);

      if (initState) {
        Object.entries(initState).forEach(([key, value]) => {
          localStorage.setItem(id + key, JSON.stringify(value));
        });
      }

      setTimeout(() => {
        if (tabSetId) {
          layout.addTabToTabSet(tabSetId, {
            id: id,
            component: kind,
            name: LookupTabName[kind],
          });
        } else {
          layout.addTabToActiveTabSet({
            id: id,
            component: kind,
            name: LookupTabName[kind],
          });
        }
      }, 10);
    },
    [layout, isEmployee]
  );
}
