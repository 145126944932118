import { ThisNodeProvider } from "./thisNode";
import {
  LocalStateContextProvider,
  useDropTheseKeys,
} from "../hooks/useLocalState";
import * as FlexLayout from "flexlayout-react";
import { ControlledProvider } from "../providers/providers";
import { FetchThisGridProvider } from "../../components/body/grid/CustomDataGrid";
import { useMemo, useEffect } from "react";
import { useRouter } from "next/router";
import { AvaliableTabs, SetOfColumnDefs } from "../../types.d";
import { ThisKindProvider } from "./thisNode";

type CoreWrapperProps = { columnSet: SetOfColumnDefs } & (
  | { node: FlexLayout.Node; mode: "flexlayout" }
  | { mode: "popout"; kind: AvaliableTabs }
);

function DropRunner(props: React.PropsWithChildren<{ node: FlexLayout.Node }>) {
  const drop = useDropTheseKeys();
  useEffect(() => {
    props.node.setEventListener("close", () => {
      drop();
    });
    return () => props.node.removeEventListener("close");
  }, [props, drop]);

  return <>{props.children}</>;
}

export function CoreWrapper(props: React.PropsWithChildren<CoreWrapperProps>) {
  const router = useRouter();
  const tabId = useMemo(() => {
    const { key } = router.query;
    var k = "";
    if (Array.isArray(key)) {
      console.warn("key param is an array", router.query);
      k = key[0];
    } else {
      if (key) {
        k = key;
      }
    }
    return k;
  }, [router.query]);

  switch (props.mode) {
    case "popout":
      if (tabId.length <= 0) {
        throw "requires key param";
      }
      return (
        <FetchThisGridProvider>
          <ThisKindProvider kind={props.kind}>
            <LocalStateContextProvider prefix={tabId}>
              <ControlledProvider columnSet={props.columnSet}>
                {props.children}
              </ControlledProvider>
            </LocalStateContextProvider>
          </ThisKindProvider>
        </FetchThisGridProvider>
      );
    case "flexlayout":
      return (
        <FetchThisGridProvider>
          <ThisNodeProvider node={props.node}>
            <LocalStateContextProvider prefix={props.node.getId()}>
              <ControlledProvider columnSet={props.columnSet}>
                <DropRunner node={props.node}>{props.children}</DropRunner>
              </ControlledProvider>
            </LocalStateContextProvider>
          </ThisNodeProvider>
        </FetchThisGridProvider>
      );
  }
}
