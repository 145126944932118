import { createContext, useContext } from "react";
import { ColumnDef } from "../../../types.d";

export const ModeMap = {
  "<": "lt",
  ">": "gt",
  ">=": "gte",
  "<=": "lte",
  "=": "eq",
} as const;

export type Operator = keyof ModeMapType;
export type Modes = ModeMapType[Operator];
export const ModeMapReversed: /*{ [m: Modes]: Operator }*/ any =
  Object.fromEntries(Object.entries(ModeMap).map((x) => [x[1], x[0]]));
export type ModeMapType = typeof ModeMap;

export function isValidOperator(op: string): op is Operator {
  return Object.keys(ModeMap).includes(op);
}

export function isValidMode(mode: string): mode is Modes {
  // return mode in Object.values(ModeMap);
  return Object.values(ModeMap).includes(mode as any);
}

export type Choice = {
  label: string;
  value: string;
};

export const ColDefContext = createContext<Required<ColumnDef> | undefined>(
  undefined
);

export function useColDef(): Required<ColumnDef> {
  const x = useContext(ColDefContext);
  if (x) {
    return x;
  } else {
    throw "not in coldef context";
  }
}
