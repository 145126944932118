import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Stack,
  Chip,
  AutocompleteInputChangeReason,
  Typography,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useArgumentField, useSetArgumentField } from "../../hooks/useQuery";
import { useSetSymbolArg } from "../../hooks/useSymbolLookup";
import { useColDef, Choice } from "./utils";
import { RoundedTextField } from "./StyledTextField";
import { ClearInputAdornment } from "./Clear";
import ListboxComponent from "./ListboxComponent";
import { useShowManageInputModal } from "../../modal/manageInput";
import { useRegisterResetThisGrid } from "../../body/grid/CustomDataGrid";

export function recordToChoices(r: Record<string, string>): Choice[] {
  return Object.entries(r)
    .map(([value, label]) => ({
      label,
      value,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
}

export type CustomChoiceFieldProps = {
  singleSelect?: boolean;
  disabled?: boolean;
}

export function CustomChoiceField(props: CustomChoiceFieldProps) {
  const def = useColDef();
  const show = useShowManageInputModal({
    colDef: def,
  });

  const value = useArgumentField();
  const setValue = useSetArgumentField();
  const setSymbolArg = useSetSymbolArg(def.rdgColumn.key);

  const [content, setContent] = useState("");
  const [input, setInput] = useState("");
  const [customChoices, setCustomChoices] = useState<Choice[]>([]);

  useEffect(() => {
    setSymbolArg(content);
  }, [content, setSymbolArg]);

  useEffect(() => {
    if (
      def.type === "autocomplete" &&
      customChoices.length === 0 &&
      Array.isArray(value) &&
      value.length > 0
    ) {
      if (def.allowCustomChoices) {
        setCustomChoices(
          value.map((x) => ({
            label: x,
            value: x,
          }))
        );
      }
    }
  }, [customChoices, value]);

  useRegisterResetThisGrid(() => {
    setContent("");
    setInput("");
  });

  const autocompleteChoices = useContext(def.autocomplete);

  const options: Choice[] = useMemo(() => {
    var ret: Choice[] = customChoices ?? [];
    if (def.type === "autocomplete") {
      if (autocompleteChoices) {
        ret = ret.concat(recordToChoices(autocompleteChoices));
      }
    } else if (def.type === "choice" && def.choices) {
      ret = ret.concat(recordToChoices(def.choices));
    } else {
      throw "incompatible type " + def.type;
    }

    if (ret.every((v) => v.label === v.value)) {
      if (content) {
        ret = [
          {
            label: content,
            value: content,
          },
        ].concat(ret);
      }

      if (value) {
        const v = Array.isArray(value) ? value[0] : value;
        if (v && ret.findIndex((x) => x.value == v) < 0) {
          ret = ret.concat([
            {
              label: v,
              value: v,
            },
          ]);
        }
      }
    }

    return ret
      .filter(
        (x, i) =>
          x && x.label && ret.findIndex((y) => y.label === x.label) === i
      )
      .sort((x, y) => x.label.localeCompare(y.label));
  }, [autocompleteChoices, customChoices, def]);

  const getOptionLabel = useCallback((option: string | Choice): string => {
    if ( option ) {
      if (typeof option === "string") {
        return option;
      } else if ("label" in option && "value" in option) {
        return option.label;
      } else {
        throw "what is this?";
      }
    } else {
      return "";
    }
  }, []);

  const onInputChange = useCallback(
    (
      _: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => {
      switch (reason) {
        case "input":
          if (
            def.type === "autocomplete" &&
            options.every((v) => v.label === v.value)
          ) {
            setCustomChoices((prev) =>
              (prev ?? []).concat([
                {
                  label: value,
                  value,
                },
              ])
            );
          }
        case "clear":
          setContent(value);
        case "reset":
          const val = def.isUpperCase ? value.toUpperCase() : value;
          setInput(val);
      }
    },
    [setContent, options]
  );

  const onChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: (string | Choice)[]) => {
      var v: any[] = [];
      if (props.singleSelect) {
        v = [value[value.length - 1]];
      } else {
        v = value;
      }

      setValue(v.map((x) => (typeof x === "object" ? x.value : x)));
    },
    [setValue, props.singleSelect]
  );

  var displayValues: Choice[] = [];

  if (Array.isArray(value)) {
    displayValues = options.filter((x) => {
      return value.includes(x.label) || value.includes(x.value);
    });
  } else {
    const c = options.find((x) => x.label === value);
    if (c) {
      displayValues = [c];
    }
  }

  const handleDeleteChip = useCallback(
    (index: number) => () => {
      setValue((prev) => {
        if (Array.isArray(prev)) {
          return prev.filter((_, i) => i !== index);
        } else if (prev) {
          return prev.split(",").filter((_, i) => i !== index);
        } else {
          return prev;
        }
      });
    },
    [setValue]
  );

  const handleRightClick = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      show();
    },
    [show]
  );

  return (
    <Autocomplete<Choice, true, true, true>
      id={`ch-autocomplete-${def.rdgColumn.key}`}
      disabled={props.disabled}
      sx={{ opacity: props.disabled ? 0.5 : 1 }}
      value={displayValues}
      options={options}
      getOptionLabel={getOptionLabel}
      filterOptions={createFilterOptions({
        ignoreCase: false,
        matchFrom: "start",
        trim: true,
        ignoreAccents: true,
        stringify: (option) => option.label,
      })}
      multiple
      freeSolo
      openOnFocus
      disableCloseOnSelect
      inputValue={input}
      onInputChange={onInputChange}
      onChange={onChange}
      disableClearable
      onContextMenu={handleRightClick}
      // onDoubleClick={show}
      renderInput={(params) => {
        return (
          <RoundedTextField
            {...params}
            variant="filled"
            label={def.rdgColumn.name}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <ClearInputAdornment
                  id={`btn-clear-${def.rdgColumn.key}`}
                  visible={value?.length ?? value ? true : false}
                />
              ),
            }}
          />
        );
      }}
      renderOption={(props, option, state) => {
        return (
          <span {...props}>
            <FormControlLabel
              checked={state.selected}
              control={
                <Checkbox
                  disableTouchRipple
                  size="small"
                  sx={{ transform: "scale(0.75)", p: 0, pr: 0.5 }}
                />
              }
              label={
                <Typography variant="caption" fontSize={12}>
                  {option.label}
                </Typography>
              }
            />
          </span>
        );
      }}
      renderTags={(options) => {
        return (
          <Stack
            id={`typed-options-${def.rdgColumn.key}`}
            direction="row"
            spacing={0.5}
            sx={{
              whiteSpace: "nowrap",
              verticalAlign: "center",
              width: "fit-content",
            }}
          >
            {options.map((option, i) => (
              <Chip
                size="small"
                key={i}
                label={option.label}
                onDelete={handleDeleteChip(i)}
              />
            ))}
          </Stack>
        );
      }}
      ListboxComponent={ListboxComponent}
    />
  );
}
