import { FormatterProps, SelectColumn, useRowSelection } from "react-data-grid";
import type {
  ColumnContentType,
  ColumnDef,
  LimeColumn,
  SetOfColumnDefs,
  MasterDetailsColContentType,
  AutocompleteContextType,
  AutocompleteContextValueType,
  ColumnTypes,
} from "../../types.d";
import MasterDetailsRow from "../body/grid/master-row/MasterDetailsRow";
import CellExpanderFormatter from "../body/grid/formatter/CellExpanderFormatter";
import OpenNewWindowFormatter from "../body/grid/formatter/OpenNewWindowFormatter";
import { FirmMappingContext } from "./useFirmMappings";
import { LastMarketMappingContext } from "./useLastMarketMappings";
import { createContext, useCallback } from "react";
import { RoutesMappingContext } from "./useRouteMappings";
import { SymbolLookupContext } from "./useSymbolLookup";
import {
  DateFormat,
  DateTimeFormat,
  EnumFormat,
  MoneyFormat,
  NumberFormat,
  SummaryMoneyTotalFormat,
  SummaryTotalFormat,
  SymbolFormat,
  TextFormat,
  BooleanFormat,
} from "../body/grid/formatter/GridColumnsFormatters";
import { CustomHeaderRenderer } from "../body/grid/CustomHeaderRenderer";
import { ClearingGroupsContext } from "./useClearingGroups";
import { BuyingPowerGroupsContext } from "./useBuyingPowerGroups";
import {
  TradableAccountsContext,
  ViewableAccountsContext,
  useTradableAccounts,
} from "./useAccounts";
import { TradeEntryRoutesContext } from "./useFetchRoutesbyAccountNum";

function PrefixCheckboxFormatter(
  props: FormatterProps<ColumnContentType, any>
) {
  const [selected, changeSelected] = useRowSelection();
  const tradableAccounts = useTradableAccounts();

  let disabled = tradableAccounts?.[props.row.account] ? false : true;

  const hanldeOnChange = useCallback(() => {
    changeSelected({ checked: !selected, isShiftClick: false, row: props.row });
  }, [selected, props.row, changeSelected]);

  return (
    <input
      id="select-single-row"
      type="checkbox"
      checked={selected}
      disabled={disabled}
      onChange={hanldeOnChange}
    />
  );
}

PrefixCheckboxFormatter.displayName = "standalone:PrefixCheckboxFormatter";

function Formatter(props: FormatterProps<ColumnContentType, any>) {
  if (props.row.type === "DETAIL") {
    return <MasterDetailsRow row={props.row as MasterDetailsColContentType} />;
  }
  return <PrefixCheckboxFormatter {...props} />;
}

export const COLUMN_PREFIX: Array<LimeColumn> = [
  {
    ...SelectColumn,
    width: 38,
    minWidth: 38,
    maxWidth: 38,
    formatter: (props) => <Formatter {...props} />,
  },
  {
    name: "",
    key: "expanded",
    width: 28,
    minWidth: 28,
    maxWidth: 28,
    frozen: true,
    formatter({ row, column, onRowChange }: any) {
      if (row.type === "DETAIL") return null;
      return (
        <CellExpanderFormatter
          expanded={row.expanded}
          onCellExpand={() => {
            onRowChange({
              ...row,
              expanded: !row.expanded,
              columnKey: column.key,
            });
          }}
        />
      );
    },
  },
  {
    name: "",
    key: "nw-details",
    width: 28,
    minWidth: 28,
    maxWidth: 28,
    frozen: true,
    formatter: ({ row }: FormatterProps<ColumnContentType, any>) => {
      if (row.type === "DETAIL") return null;
      return <OpenNewWindowFormatter row={row} />;
    },
  },
];

const SummaryFormatterLookup: Partial<Record<ColumnTypes, React.FC<any>>> = {
  number: SummaryTotalFormat,
  money: SummaryMoneyTotalFormat,
};

export var AllDefs: SetOfColumnDefs = [];

export function FixColumnDef(def: ColumnDef): Required<ColumnDef> {
  const DefaultNamesContext: AutocompleteContextType =
    createContext<AutocompleteContextValueType>(undefined);
  const FormatterLookup: Partial<Record<ColumnTypes, React.FC<any>>> = {
    autocomplete: SymbolFormat,
    number: NumberFormat,
    choice: EnumFormat,
    money: MoneyFormat,
    datetime: DateTimeFormat,
    date: DateFormat,
    boolean: BooleanFormat,
  };

  if (!def.rdgColumn) {
    throw new Error(
      "Must defines react-data-grid column: " + JSON.stringify(def)
    );
  }

  const type = def.type ?? "text";
  const Formatter = FormatterLookup[type] ?? TextFormat;

  const completeDef: Required<ColumnDef> = {
    enableHeaderFilter: def.enableHeaderFilter ?? true,
    autocomplete: def.autocomplete ?? DefaultNamesContext,
    choices: def.choices ?? {},
    rdgColumn: {
      ...def.rdgColumn,
      headerRenderer: (props) => (
        <CustomHeaderRenderer columnDef={completeDef} {...props} />
      ),
      formatter: (props) => <Formatter def={completeDef} cell={props} />,
      summaryFormatter: (props) => {
        let Summary = SummaryFormatterLookup[type];
        if (Summary && !def.hideSummary) {
          return <Summary colName={props.column.key} />;
        }
        return <></>;
      },
    },
    visibleByDefault:
      typeof def.visibleByDefault === "boolean" ? def.visibleByDefault : true,
    type: type,
    apiKeyOverride: def.apiKeyOverride ?? {
      events: def.rdgColumn.key,
      orders: def.rdgColumn.key,
    },
    hideSummary: def.hideSummary ?? false,
    allowCustomChoices: def.allowCustomChoices ?? false,
    isUpperCase: def.isUpperCase ?? false,
  };

  AllDefs.push(completeDef);
  return completeDef;
}

export const accountDef = FixColumnDef({
  rdgColumn: {
    key: "account",
    name: "Acct Number",
    minWidth: 125,
  },
  visibleByDefault: true,
  type: "text",
});
export const tradingDomainDef = FixColumnDef({
  rdgColumn: {
    key: "tradingDomain",
    name: "Trading Domain",
    minWidth: 150,
  },
  visibleByDefault: false,
  type: "text",
});
export const symbolUnderlyingDef = FixColumnDef({
  rdgColumn: {
    key: "symbolUnderlying",
    name: "Sym Root",
    minWidth: 125,
  },
  visibleByDefault: true,
  type: "text",
});
export const tidDef = FixColumnDef({
  rdgColumn: {
    key: "tid",
    name: "TID",
    minWidth: 120,
  },
  visibleByDefault: false,
  type: "text",
});
export const timeInForceDef = FixColumnDef({
  rdgColumn: {
    key: "timeInForce",
    name: "TIF",
    minWidth: 140,
  },
  choices: {
    day: "Day",
    goodtillcancel: "Good Until Cancel",
    attheopening: "At the Opening",
    ioc: "IOC",
    fillorkill: "Fill Or Kill",
    goodtillcrossing: "Good Until Crossing",
    goodtilldate: "Good Until Date",
    attheclose: "At the Close",
  },
  visibleByDefault: false,
  type: "choice",
});
export const buyingTimeInForceDef = FixColumnDef({
  rdgColumn: {
    key: "timeInForce",
    name: "TIF",
    minWidth: 140,
  },
  choices: {
    DAY: "DAY",
    MOO: "MOO",
    IOC: "IOC",
    GTX: "GTX",
    MOC: "MOC",
    LOO: "LOO",
    LOC: "LOC",
  },
  visibleByDefault: false,
  type: "choice",
});
export const routeDef = FixColumnDef({
  rdgColumn: {
    key: "route",
    name: "Route",
    minWidth: 165,
  },
  visibleByDefault: true,
  type: "autocomplete",
  autocomplete: RoutesMappingContext,
  allowCustomChoices: true,
});
export const tradeEntryRouteDef = FixColumnDef({
  rdgColumn: {
    key: "route",
    name: "Route",
    minWidth: 165,
  },
  visibleByDefault: true,
  type: "autocomplete",
  autocomplete: TradeEntryRoutesContext,
  allowCustomChoices: true,
});

export const lastMarketDef = FixColumnDef({
  rdgColumn: {
    key: "lastMarket",
    name: "Last Market",
    minWidth: 130,
  },
  visibleByDefault: false,
  type: "autocomplete",
  autocomplete: LastMarketMappingContext,
  allowCustomChoices: true,
});
export const orderTypeDef = FixColumnDef({
  rdgColumn: {
    key: "orderType",
    name: "Ord Type",
    minWidth: 100,
  },
  choices: {
    market: "Market",
    limit: "Limit",
  },
  visibleByDefault: true,
  type: "choice",
});
export const securityTypeDef = FixColumnDef({
  rdgColumn: {
    key: "securityType",
    name: "Sec Type",
    minWidth: 100,
  },
  choices: { equity: "Equity", option: "Option", multi_leg: "Multi Leg" },
  visibleByDefault: false,
  type: "choice",
});
export const filledQuantityDef = FixColumnDef({
  rdgColumn: {
    key: "filledQuantity",
    name: "Exec Qty",
    minWidth: 120,
  },
  visibleByDefault: true,
  type: "number",
});
export const senderIdDef = FixColumnDef({
  rdgColumn: {
    key: "senderId",
    name: "Sender ID",
    minWidth: 100,
  },
  visibleByDefault: false,
  type: "text",
});
export const targetIdDef = FixColumnDef({
  rdgColumn: {
    key: "targetId",
    name: "Target ID",
    minWidth: 100,
  },
  visibleByDefault: false,
  type: "text",
});
export const replacedByDef = FixColumnDef({
  rdgColumn: {
    key: "replacedBy",
    name: "Replaced Id",
    minWidth: 124,
  },
  visibleByDefault: false,
  type: "text",
});
export const algoDef = FixColumnDef({
  rdgColumn: {
    key: "algo",
    name: "Algo Name",
    minWidth: 130,
  },
  visibleByDefault: false,
  type: "text",
});
export const algoParentIdDef = FixColumnDef({
  rdgColumn: {
    key: "algoRouteParentId",
    name: "Algo Parent ID",
    minWidth: 150,
  },
  visibleByDefault: false,
  type: "text",
});
export const orderIdDef = FixColumnDef({
  rdgColumn: {
    key: "orderId",
    name: "Lime Id",
    minWidth: 125,
  },
  apiKeyOverride: {
    orders: "id",
    events: "orderId",
  },
  visibleByDefault: true,
  type: "text",
});
export const idDef = FixColumnDef({
  rdgColumn: {
    key: "id",
    name: "Lime Id",
    minWidth: 125,
  },
  apiKeyOverride: {
    orders: "id",
    events: "orderId",
  },
  visibleByDefault: true,
  type: "text",
});
export const clOrdIdDef = FixColumnDef({
  rdgColumn: {
    key: "clOrdId",
    name: "ClOrd Id",
    minWidth: 125,
  },
  visibleByDefault: true,
  type: "text",
});
export const execIdDef = FixColumnDef({
  rdgColumn: {
    key: "execId",
    name: "Execution ID",
    minWidth: 160,
  },
  visibleByDefault: false,
  type: "text",
});
export const firmIdDef = FixColumnDef({
  rdgColumn: {
    key: "firmId",
    name: "Firm Name",
    minWidth: 150,
  },
  autocomplete: FirmMappingContext,
  visibleByDefault: false,
  type: "autocomplete",
});
export const accountNameDef = FixColumnDef({
  rdgColumn: {
    key: "accountName",
    name: "Acct Name",
    minWidth: 170,
  },
  apiKeyOverride: {
    events: "account",
    orders: "account",
    positions: "id",
    efix: "account",
    complianceQueryTool: "account",
  },
  visibleByDefault: true,
  autocomplete: ViewableAccountsContext,
  type: "autocomplete",
  isUpperCase: true,
});
export const tradableAccountNameDef = FixColumnDef({
  rdgColumn: {
    key: "accountName",
    name: "Acct Name",
    minWidth: 170,
  },
  apiKeyOverride: {
    events: "account",
    orders: "account",
    positions: "id",
  },
  visibleByDefault: true,
  autocomplete: TradableAccountsContext,
  type: "autocomplete",
  isUpperCase: true
});
export const symbolDef = FixColumnDef({
  rdgColumn: {
    key: "symbol",
    name: "Symbol",
    minWidth: 100,
  },
  visibleByDefault: true,
  type: "autocomplete",
  autocomplete: SymbolLookupContext,
  allowCustomChoices: true,
  isUpperCase: true,
});
export const sideDef = FixColumnDef({
  rdgColumn: {
    key: "side",
    name: "Side",
    minWidth: 90,
  },
  choices: {
    buy: "Buy",
    sell: "Sell",
    short: "Short",
    short_exempt: "Shrt Ex",
    cover: "Cover",
  },
  visibleByDefault: true,
  type: "choice",
});
export const quantityDef = FixColumnDef({
  rdgColumn: {
    key: "quantity",
    name: "Quantity",
    minWidth: 120,
  },
  visibleByDefault: true,
  type: "number",
});
export const priceDef = FixColumnDef({
  rdgColumn: {
    key: "price",
    name: "Px",
    minWidth: 120,
  },
  visibleByDefault: true,
  type: "money",
  hideSummary: true,
});
export const avgPriceDef = FixColumnDef({
  rdgColumn: {
    key: "avgPrice",
    name: "Average Price",
    minWidth: 130,
  },
  visibleByDefault: true,
  type: "money",
  hideSummary: true,
});
export const statusDef = FixColumnDef({
  rdgColumn: {
    key: "status",
    name: "Ord Status",
    minWidth: 120,
  },
  choices: {
    new_order: "New Order",
    pending_new: "Pending New",
    pending_cancel: "Pending Cancel",
    replaced: "Replaced",
    pending_replace: "Pending Replace",
    partially_filled: "Partial",
    filled: "Filled",
    done_for_day: "Done For Day",
    canceled: "Canceled",
    rejected: "Rejected",
    stopped: "Stopped",
    suspended: "Suspended",
    calculated: "Calculated",
    expired: "Expired",
    accepted_for_bidding: "Accepted",
  },
  visibleByDefault: true,
  type: "choice",
});
export const stateDef = FixColumnDef({
  rdgColumn: {
    key: "state",
    name: "State",
    minWidth: 90,
  },
  choices: { open: "Open", closed: "Closed" },
  visibleByDefault: true,
  type: "choice",
});
export const lastChangeDef = FixColumnDef({
  rdgColumn: {
    key: "lastChange",
    name: "Last Changed",
    minWidth: 200,
  },
  visibleByDefault: false,
  type: "datetime",
});
export const timestampDef = FixColumnDef({
  rdgColumn: {
    key: "timestamp",
    name: "Timestamp",
    minWidth: 200,
  },
  visibleByDefault: true,
  type: "datetime",
  enableHeaderFilter: false,
});
export const platformDef = FixColumnDef({
  rdgColumn: {
    key: "platform",
    name: "Platform",
    minWidth: 90,
  },
  visibleByDefault: false,
  type: "choice",
  choices: {
    LIME: "LIME",
    TRANSAQ: "TRANSAQ"
  },
});
export const efixHubDef = FixColumnDef({
  rdgColumn: {
    key: "efixHub",
    name: "Efix Hub",
    minWidth: 115,
  },
  visibleByDefault: false,
  type: "text",
});
export const positionEffectDef = FixColumnDef({
  rdgColumn: {
    key: "positionEffect",
    name: "Position Effect",
    minWidth: 128,
  },
  choices: { open: "Open", close: "Close", rolled: "Rolled", fifo: "FIFO" },
  visibleByDefault: false,
  type: "choice",
});
export const algoRouteParentIdDef = FixColumnDef({
  rdgColumn: {
    key: "algoRouteParentId",
    name: "Algo Route Parent ID",
  },
  visibleByDefault: false,
  type: "text",
});
export const clientAccountDef = FixColumnDef({
  rdgColumn: {
    key: "clientAccount",
    name: "Tag1/9050",
    minWidth: 140,
  },
  visibleByDefault: true,
  type: "text",
});
export const offsetDef = FixColumnDef({
  rdgColumn: {
    key: "offset",
    name: "Offset",
  },
  visibleByDefault: true,
  type: "number",
});
export const totalCostDef = FixColumnDef({
  rdgColumn: {
    key: "totalCost",
    name: "Total Cost",
    minWidth: 120,
  },
  visibleByDefault: false,
  type: "money",
});
export const recordedDef = FixColumnDef({
  rdgColumn: {
    key: "recorded",
    name: "Order Recorded",
    minWidth: 200,
  },
  visibleByDefault: true,
  type: "datetime",
  enableHeaderFilter: false,
});
export const acceptedDef = FixColumnDef({
  rdgColumn: {
    key: "accepted",
    name: "Order Accepted",
  },
  visibleByDefault: false,
  type: "datetime",
});
export const parentIdDef = FixColumnDef({
  rdgColumn: {
    key: "parentId",
    name: "Parent ID",
    minWidth: 140,
  },
  visibleByDefault: false,
  type: "text",
});
export const fixMsgInDef = FixColumnDef({
  rdgColumn: {
    key: "fixMsgIn",
    name: "FIX Received",
    minWidth: 200,
  },
  visibleByDefault: true,
  type: "text",
});
export const fixMsgOutDef = FixColumnDef({
  rdgColumn: {
    key: "fixMsgOut",
    name: "FIX Sent",
    minWidth: 200,
  },
  visibleByDefault: true,
  type: "text",
});
export const efixReceivedDef = FixColumnDef({
  rdgColumn: {
    key: "efixReceived",
    name: "EFIX Received",
    minWidth: 200,
  },
  visibleByDefault: false,
  type: "text",
});
export const efixTransmittedDef = FixColumnDef({
  rdgColumn: {
    key: "efixTransmitted",
    name: "EFIX Transmitted",
    minWidth: 200,
  },
  visibleByDefault: false,
  type: "text",
});
export const orderQuantityDef = FixColumnDef({
  rdgColumn: {
    key: "quantity",
    name: "Order Quantity",
    minWidth: 155,
  },
  visibleByDefault: false,
  type: "number",
});
export const textDef = FixColumnDef({
  rdgColumn: {
    key: "text",
    name: "Text",
    minWidth: 120,
  },
  visibleByDefault: false,
  type: "text",
});
export const rejectReasonDef = FixColumnDef({
  rdgColumn: {
    key: "rejectReason",
    name: "Reject Reason",
    minWidth: 132,
  },
  visibleByDefault: false,
  enableHeaderFilter: false,
});
export const buyingPowerGroupDef = FixColumnDef({
  rdgColumn: {
    key: "buyingPowerGroup",
    name: "Buying Power Group",
    minWidth: 160,
  },
  allowCustomChoices: false,
  type: "autocomplete",
  visibleByDefault: true,
  autocomplete: BuyingPowerGroupsContext,
  isUpperCase: true,
});
export const dateDef = FixColumnDef({
  rdgColumn: {
    key: "date",
    name: "Date",
  },
  type: "date",
});
export const buyingPowerGroupeIdDef = FixColumnDef({
  rdgColumn: {
    key: "buyingPowerGroupId",
    name: "Buying Power Group Id",
    minWidth: 180,
  },
  type: "text",
  enableHeaderFilter: false,
});
export const startOfDayBuyingPowerDef = FixColumnDef({
  rdgColumn: {
    key: "startOfDayBuyingPower",
    name: "Start Of Day Buying Power",
    minWidth: 200,
  },
  type: "money",
  enableHeaderFilter: false,
});
export const updatedBuyingPowerDef = FixColumnDef({
  rdgColumn: {
    key: "updatedBuyingPower",
    name: "Updated Buying Power",
    minWidth: 180,
  },
  type: "money",
  enableHeaderFilter: false,
});
export const clearingGroupDef = FixColumnDef({
  rdgColumn: {
    key: "clearingGroup",
    name: "Clearing Group",
    minWidth: 130,
  },
  type: "autocomplete",
  allowCustomChoices: false,
  autocomplete: ClearingGroupsContext,
  isUpperCase: true,
});
export const openOrderBuyingPowerMultipleDef = FixColumnDef({
  rdgColumn: {
    key: "openOrderBuyingPowerMultiple",
    name: "Open Order Buying Power Multiple",
    minWidth: 260,
  },
  type: "number",
  enableHeaderFilter: false,
});
export const marginTypeDef = FixColumnDef({
  rdgColumn: {
    key: "marginType",
    name: "Margin Type",
    minWidth: 120,
  },
  type: "text",
  enableHeaderFilter: false,
});
export const positionDef = FixColumnDef({
  rdgColumn: {
    key: "position",
    name: "Position",
  },
  choices: {
    LONG: "LONG",
    SHORT: "SHORT",
    FLAT: "FLAT",
  },
  type: "choice",
  visibleByDefault: true,
});
export const averagePriceDef = FixColumnDef({
  rdgColumn: {
    key: "averagePrice",
    name: "Average Price",
  },
  type: "money",
  visibleByDefault: true,
});
export const buyingSideDef = FixColumnDef({
  rdgColumn: {
    key: "side",
    name: "Side",
  },
  type: "choice",
  choices: {
    BUY: "Buy",
    SELL: "Sell",
    SELL_SHORT: "Short",
    BUY_TO_COVER: "Buy to cover",
  },
  visibleByDefault: false,
});
export const dateForTidQueryDef = FixColumnDef({
  rdgColumn: {
    key: "dateForTidQuery",
    name: "Date For Tid Query",
  },
  type: "date",
  visibleByDefault: false,
});
export const startTimeDef = FixColumnDef({
  rdgColumn: {
    key: "startTime",
    name: "Start Time",
  },
  type: "time",
  visibleByDefault: false,
});
export const endTimeDef = FixColumnDef({
  rdgColumn: {
    key: "endTime",
    name: "End Time",
  },
  type: "time",
  visibleByDefault: false,
});
export const isISODef = FixColumnDef({
  rdgColumn: {
    key: "isISO",
    name: "ISO",
  },
  type: "boolean",
  visibleByDefault: false,
});
export const openOrderBuyingPower = FixColumnDef({
  rdgColumn: {
    key: "openOrderBuyingPower",
    name: "Open Order Buying Power",
    width: 150,
  },
  type: "number",
  visibleByDefault: false,
});
export const sodBuyingPowerDef = FixColumnDef({
  rdgColumn: {
    key: "sodBuyingPower",
    name: "SOD Buying Power",
    width: 150,
  },
  type: "number",
  visibleByDefault: false,
});
export const wireFormatDef = FixColumnDef({
  rdgColumn: {
    key: "wireFormat",
    name: "Wire Format",
  },
  type: "boolean",
});
export const acceptToDoQueryDef = FixColumnDef({
  rdgColumn: {
    key: "acceptToDoQuery",
    name: "Accept To Do Query",
  },
  type: "boolean",
});
export const ouchFormatDef = FixColumnDef({
  rdgColumn: {
    key: "ouchFormat",
    name: "OUCH Format",
  },
  type: "boolean",
});
export const utpDirectFormatDef = FixColumnDef({
  rdgColumn: {
    key: "utpDirectFormat",
    name: "UTP Direct Format",
  },
  type: "boolean",
});
export const arcaDirectFormatDef = FixColumnDef({
  rdgColumn: {
    key: "arcaDirectFormat",
    name: "ARCA Direct Format",
  },
  type: "boolean",
});
export const batsBinaryFormatDef = FixColumnDef({
  rdgColumn: {
    key: "batsBinaryFormat",
    name: "Bats Binary Format",
  },
  type: "boolean",
});
export const nysePillarFormatDef = FixColumnDef({
  rdgColumn: {
    key: "nysePillarFormat",
    name: "NYSE Pillar Format",
  },
  type: "boolean",
});
export const mpidDef = FixColumnDef({
  rdgColumn: {
    key: "mpid",
    name: "MPID",
  },
  type: "text",
})
export const clearingIdDef = FixColumnDef({
  rdgColumn: {
    key: "clearingId",
    name: "Clearing ID",
    width: 50,
  },
  type: "text",
  visibleByDefault: false,
})
export const efixServerDef = FixColumnDef({
  rdgColumn: {
    key: "efixHub",
    name: "Trading Server",
    width: 150
  },
  type: "text",
  visibleByDefault: false
})

// needs to be var for testing purposes ¯\_(ツ)_/¯
export var SetOfColumnDefsForPositions: SetOfColumnDefs = [
  accountNameDef,
  symbolDef,
  quantityDef,
  positionDef,
  averagePriceDef,
];

// needs to be var for testing purposes ¯\_(ツ)_/¯
export var SetOfColumnsForOrders: SetOfColumnDefs = [
  // Default Fields
  recordedDef,
  idDef,
  accountDef,
  accountNameDef,
  clientAccountDef,
  clOrdIdDef,
  stateDef,
  statusDef,
  symbolDef,
  symbolUnderlyingDef,
  sideDef,
  quantityDef,
  filledQuantityDef,
  priceDef,
  routeDef,
  orderTypeDef,
  avgPriceDef,
  // Optional Fields
  algoDef,
  algoParentIdDef,
  efixHubDef,
  firmIdDef,
  lastChangeDef,
  platformDef,
  positionEffectDef,
  rejectReasonDef,
  replacedByDef,
  securityTypeDef,
  senderIdDef,
  targetIdDef,
  timeInForceDef,
  totalCostDef,
  tradingDomainDef,
  isISODef,
  openOrderBuyingPower,
  sodBuyingPowerDef,
  clearingIdDef,
  efixHubDef
];

// needs to be var for testing purposes ¯\_(ツ)_/¯
export var SetOfColumnsForEvents: SetOfColumnDefs = [
  timestampDef,
  orderIdDef,
  accountDef,
  accountNameDef,
  clOrdIdDef,
  statusDef,
  symbolDef,
  symbolUnderlyingDef,
  sideDef,
  quantityDef,
  priceDef,
  routeDef,
  avgPriceDef,
  // Optional Fields
  efixHubDef,
  execIdDef,
  firmIdDef,
  lastMarketDef,
  platformDef,
  positionEffectDef,
  securityTypeDef,
  textDef,
  tidDef,
  tradingDomainDef,
  isISODef,
  clearingIdDef,
];

// needs to be var for testing purposes ¯\_(ツ)_/¯
export var SetOfColumnDefsForBuyingPower: SetOfColumnDefs = [
  buyingPowerGroupDef,
  dateDef,
  buyingPowerGroupeIdDef,
  startOfDayBuyingPowerDef,
  updatedBuyingPowerDef,
  clearingGroupDef,
  openOrderBuyingPowerMultipleDef,
  marginTypeDef,
];

// needs to be var for testing purposes ¯\_(ツ)_/¯
export var SetOfColumnsForMRTradeDetails = [
  timestampDef,
  statusDef,
  quantityDef,
  avgPriceDef,
  routeDef,
  lastMarketDef,
  execIdDef,
  senderIdDef,
  targetIdDef,
  efixReceivedDef,
  fixMsgInDef,
  fixMsgOutDef,
];
