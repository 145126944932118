import { useCallback } from "react";
import { CreateDynamicMapping } from "./autocompleteMapper";
import { AutocompleteContextValueType } from "../../types.d";
import { CreateDependentStateContext } from "./stateContext";

const [SymbolLookupProvider, useSetSymbolArgs, SymbolLookupContext] =
  CreateDynamicMapping<Array<{ symbol: string; description: string }>>(
    "/v2/security",
    (arr, curr): AutocompleteContextValueType => {
      if (Array.isArray(arr)) {
        const oldArr = Object.keys(curr ?? {});
        const newArr = arr.map((x) => x.symbol);
        const a = oldArr.concat(newArr).sort((x, y) => x.localeCompare(y));

        const obj = a.reduce(
          (prev, curr) => ({
            ...prev,
            [curr]: curr,
          }),
          {} as AutocompleteContextValueType
        );

        return obj;
      } else {
        console.error("unable to parse", arr);
        throw "unable to parse";
      }
    },
    (args) => {
      const s = args["symbol"];
      return {
        symbol: Array.isArray(s)
          ? s.map((x) => x.toUpperCase())
          : s?.toUpperCase(),
      };
    },
    "symbol_lookup"
  );

function useSetSymbolArg(field: string) {
  const set = useSetSymbolArgs();

  return useCallback(
    (value: string) => {
      set((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    [set, field]
  );
}

export {
  SymbolLookupProvider,
  SymbolLookupContext,
  useSetSymbolArgs,
  useSetSymbolArg,
};
