import { Box } from "@mui/material";
import { useMemo, useState, createContext } from "react";
import {
  RowStacks,
  ExportMenu,
  RestartButton,
  SearchButton,
  ChooseColumns,
  AutoRefreshButton,
} from "../../components/body/common/ControlRow";
import { useMyAccountNumbers } from "../hooks/useCancel";
import CustomDataGrid from "../../components/body/grid/CustomDataGrid";
import { ArgumentFence, ArgumentType } from "../../components/hooks/useQuery";
import { CustomChoiceField } from "../body/new-inputs/CustomChoiceField";
import { ColDefContext } from "../body/new-inputs/utils";
import { AutocompleteContextValueType, ColumnDef } from "../../types.d";
import { SingleDatePicker } from "../body/common/SingleDatePicker";
import { AutoRefreshProvider as BuyingPowerAutoRefreshProvider } from "../hooks/datetimerangepicker-hook/useAutoRefresh";

const StubAutocompleteContext = createContext<AutocompleteContextValueType>(
  {} as any
);

function SelectAccount() {
  const myAccounts = useMyAccountNumbers();

  const injectableColDef: Required<ColumnDef> = useMemo(
    () => ({
      allowCustomChoices: false,
      apiKeyOverride: {},
      enableHeaderFilter: false,
      hideSummary: true,
      visibleByDefault: true,
      choices: myAccounts.reduce((dict, account) => {
        return {
          ...dict,
          [account.number]: account.name,
        };
      }, {} as Record<string, string>),
      type: "choice",
      rdgColumn: {
        key: "ids",
        name: "Select accounts",
      },
      autocomplete: StubAutocompleteContext,
      isUpperCase: false
    }),
    [myAccounts]
  );

  return (
    <Box
      sx={{
        maxHeight: "inherit",
        maxWidth: "calc(max(20%, 50ch))",
        width: "50ch",
        margin: "0 2px",
      }}
    >
      <ColDefContext.Provider value={injectableColDef as Required<ColumnDef>}>
        <CustomChoiceField />
      </ColDefContext.Provider>
    </Box>
  );
}

export function BuyingPowerCore() {
  const handleDisabledBtn = (
    args: Partial<{
      [x: string]: ArgumentType;
    }>
  ) => {
    if ("id" in args) {
      if (Array.isArray(args.id)) {
        return args.id.length === 0;
      }
    }
    return true;
  };

  return (
    <>
      <BuyingPowerAutoRefreshProvider>
        <RowStacks
          leftRowStack={
            <>
              <ArgumentFence field="date">
                <SingleDatePicker />
              </ArgumentFence>
              <ArgumentFence field="id">
                <SelectAccount />
              </ArgumentFence>
              <AutoRefreshButton disabled={handleDisabledBtn} />
            </>
          }
          rightRowStack={
            <>
              <SearchButton disabled={handleDisabledBtn} />
              <RestartButton />
              <ExportMenu />
              <ChooseColumns />
            </>
          }
        />
      </BuyingPowerAutoRefreshProvider>
      <Box
        sx={{
          display: "flex",
          width: "inherit",
          flexDirection: "column",
          position: "relative",
          height: "calc(100% - 30px)",
          overflow: "hidden",
        }}
      >
        <CustomDataGrid
          baseUrl="trade"
          showHeaderFilters={true}
          path="/v2/buying-power/account"
          enableColumnPrefix={false}
          parseList={(obj: any) => {
            if (Array.isArray(obj)) {
              return obj;
            } else {
              return [obj];
            }
          }}
          parseTotal={(obj: any) => {
            if (Array.isArray(obj)) {
              return obj.length;
            } else {
              return 1;
            }
          }}
        />
      </Box>
    </>
  );
}
