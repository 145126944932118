import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  useCallback,
} from "react";
import { useLocalState } from "./useLocalState";

type Provider<T> = React.FC<React.PropsWithChildren<{}>>;
type DispatcherFunction<T> = () => React.Dispatch<
  React.SetStateAction<T | undefined>
>;
type ValueLookupType<T> = Record<string, T>;

export type StateDispatcher<T> = (() => T) & {
  displayName: string;
};

/**
 * A factory function for creating a react context that provided control over a
 * single property/field without contextual rerendering.
 *
 * Returns: [
 *   * A context provider with a prop "field" to set which field of the larger
 *   state to control,
 *   * A useState hook that returns that field,
 *   * A useSetState hook that returns a setter for that field
 * ]
 */
export function CreateStateFence<T extends { [x: string]: any }>(
  [_, useState, useSetState, __]: StateContext<T> | DependentStateContext<T>,
  def: T[keyof T],
  friendlyName?: string
): [
  React.FC<React.PropsWithChildren<{ field: keyof T }>>,
  () => T[keyof T] | undefined,
  () => React.Dispatch<React.SetStateAction<T[keyof T]>>
] {
  type KeyOfT = keyof T;
  type ValueOfT = T[KeyOfT];

  const FenceContext = createContext<ValueOfT | undefined>(undefined);
  const FieldContext = createContext<KeyOfT | undefined>(undefined);

  function FenceContextProvider(
    props: React.PropsWithChildren<{ field: KeyOfT }>
  ) {
    const state = useState();
    const part: ValueOfT = state[props.field];

    return (
      <FenceContext.Provider value={part}>
        <FieldContext.Provider value={props.field}>
          {props.children}
        </FieldContext.Provider>
      </FenceContext.Provider>
    );
  }

  FenceContextProvider.displayName = `fence:${friendlyName}`;

  function useFencedValue(): ValueOfT | undefined {
    return useContext(FenceContext);
  }

  // function useSetFencedValue(): (val: ValueOfT) => void {
  const useSetFencedValue: () => React.Dispatch<
    React.SetStateAction<ValueOfT>
  > = () => {
    const set = useSetState();
    const field = useContext(FieldContext);

    return useCallback(
      (val: React.SetStateAction<ValueOfT>) => {
        if (!field) {
          throw "field is undefined";
        }

        set((prev) => {
          var n: ValueOfT | undefined = prev?.[field];

          if (val instanceof Function) {
            n = val(n ?? def);
          } else {
            n = val;
          }

          return {
            ...prev,
            [field]: n,
          } as T;
        });
      },
      [set, field]
    );
  };

  return [FenceContextProvider, useFencedValue, useSetFencedValue];
}

type DependentStateContext<T> = [
  Provider<ValueLookupType<T>>,
  StateDispatcher<T>,
  DispatcherFunction<T>,
  () => (key: string, val: React.SetStateAction<undefined | T>) => void
];

/**
 * A factory function for creating a state context that is affected by another
 * state.
 *
 * Returns: [
 *   * A context provider that is affected by useKey,
 *   * A useState hook that is affected by useKey,
 *   * A useSetState that returns a setter that is affected by useKey,
 *   * A setter function used to override the key context
 * ]
 */
export function CreateDependentStateContext<T>(
  useKey: StateDispatcher<string>,
  def: T,
  friendlyName: string,
  save?: boolean,
  protect = false
): DependentStateContext<T> {
  type ValueLookup = ValueLookupType<T>;

  const displayName = `dependant_state_provider:${friendlyName}`;

  const [DependentStateProvider, useDependentState, useSetDependentState] =
    CreateStateContext<ValueLookup>(friendlyName, {}, save);

  function Provider(props: React.PropsWithChildren) {
    return <DependentStateProvider>{props.children}</DependentStateProvider>;
  }

  Provider.displayName = displayName;

  function useLookupDependentState(): T | undefined {
    const key = useKey();
    const state = useDependentState();

    // if (!key) {
    //   return undefined;
    // }

    // if (!state) {
    //   return def;
    // }

    return state[key] ?? def;
  }

  useLookupDependentState.displayName = `dependant_state_get:${displayName}`;

  function useSetLookedupDependentState(): React.Dispatch<
    React.SetStateAction<T | undefined>
  > {
    const key = useKey();
    const setAt = useSetAtKey();

    return useCallback(
      (val: React.SetStateAction<undefined | T>) => {
        if (key) {
          setAt(key, val);
        }
      },
      [setAt, key]
    );
  }

  function useSetAtKey() {
    const setAll = useSetDependentState();

    if (!setAll) {
      throw "no context";
    }

    return useCallback(
      (key: string, val: React.SetStateAction<undefined | T>) => {
        setAll((prev) => {
          var ret: NonNullable<typeof prev> = prev
            ? { ...prev }
            : ({} as ValueLookup);

          var v: T | undefined;

          if (typeof val === "function") {
            const sub = ret[key];
            v = (val as any)(sub);
          } else {
            v = val;
          }

          if (typeof v === "undefined") {
            delete ret[key];
          } else {
            ret[key] = v;
          }

          return ret;
        });
      },
      [setAll]
    );
  }

  return [
    Provider,
    useLookupDependentState as StateDispatcher<T>,
    useSetLookedupDependentState,
    useSetAtKey,
  ];
}

export type StateProviderProps<T> = React.PropsWithChildren<{
  initial?: T;
  override?: T;
}>;
export type StateContext<T> = [
  (props: React.PropsWithChildren<StateProviderProps<T>>) => JSX.Element,
  StateDispatcher<T>,
  DispatcherFunction<T>,
  React.Context<T | undefined>
];

/**
 * A factory function for creating a general purpose useState hook wrapped in
 * a context provider.
 *
 * Returns: [
 *   * A state provider,
 *   * A useState hook,
 *   * A useSetState hook,
 *   * The underlying context
 * ]
 */
export function CreateStateContext<T>(
  friendlyName: string,
  initial?: T | (() => T),
  save?: boolean,
  protect?: boolean
): StateContext<T> {
  const ValContext = createContext<T | undefined>(
    typeof initial !== "function" ? initial : (initial as () => T)()
  );

  const SetContext = createContext<
    React.Dispatch<React.SetStateAction<T | undefined>> | undefined
  >(undefined);

  /**
   * If `props.initial` is provided, this provider will initalize to `props.initial` instead of the initial argument,
   * If `props.override` is provided, this provider will copy `props.override` into its state on each change
   */
  function Provider(props: React.PropsWithChildren<StateProviderProps<T>>) {
    const [state, setState] = useLocalState(
      props.override ?? props.initial ?? initial,
      save ? friendlyName : undefined,
      protect
    );

    useEffect(() => {
      if ("override" in props) {
        setState(props.override);
      }
    }, [props, setState]);

    return (
      <ValContext.Provider value={state}>
        <SetContext.Provider value={setState}>
          {props.children}
        </SetContext.Provider>
      </ValContext.Provider>
    );
  }

  Provider.displayName = `state_provider:${friendlyName}`;

  function useValue() {
    return useContext(ValContext);
  }

  useValue.displayName = `state_getter:${friendlyName}`;

  function useSetter() {
    const set = useContext(SetContext);
    return (s: React.SetStateAction<T | undefined>) => {
      if (typeof set === "undefined") {
        console.log(`useSetter(${friendlyName}) not in context`);
        return;
      }
      set(s);
    };
  }

  return [Provider, useValue as StateDispatcher<T>, useSetter, ValContext];
}
