import { Tooltip } from "@mui/material";
import { FormatterProps } from "react-data-grid";
import { useMemo } from "react";
import { useResult } from "../../../hooks/useQuery";
import type { ColumnContentType, ColumnDef } from "../../../../types.d";
import moment from "moment";
import useFormattedText from "../../../hooks/useFormattedText";
import CustomChip from "../../CustomChip";
import chipColorCode from "../../../../utils/chipColorCode";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

const CurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 4, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
});

const CommaFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 4,
});

export type LimeFormatterProps<T = unknown> = {
  cell: FormatterProps<ColumnContentType, any>;
  def: Required<ColumnDef>;
} & T;

function NumberTypograpy(props: { value: number | string; money?: boolean }) {
  const num = useMemo(() => {
    let n = parseFloat(`${props.value}`);

    if (Number.isNaN(n)) {
      n = 0;
    }

    return props.money ? CurrencyFormatter.format(n) : CommaFormatter.format(n);
  }, [props.value, props.money]);

  return <p id="grid-text-css">{num}</p>;
}

NumberTypograpy.displayName = "standalone:NumberTypograpy";

function useCellValue(cell: FormatterProps<ColumnContentType>): string {
  if (Object.keys(cell.row).includes(cell.column.key)) {
    const t = cell.row[cell.column.key];
    if (
      t &&
      (typeof t === "string" || typeof t === "number" || typeof t === "boolean")
    ) {
      return `${t}`;
    }
  }

  return "";
}

export function NumberFormat(
  props: LimeFormatterProps<{
    money?: boolean;
  }>
) {
  const v: string = useCellValue(props.cell);
  return <NumberTypograpy value={v} money={props.money} />;
}

NumberFormat.displayName = "standalone:NumberFormat";

export function MoneyFormat(props: LimeFormatterProps) {
  return <NumberFormat money {...props} />;
}

MoneyFormat.displayName = "standalone:MoneyFormat";

export function DateTimeFormat(props: LimeFormatterProps) {
  const v = useCellValue(props.cell);
  const ns = `${v.split(".")[1]}`;
  var dateStr = moment(v).format("YYYY-MM-DD HH:mm:ss").toString();

  if (ns !== "undefined") {
    dateStr += "." + ns.substr(0, ns.length - 1) ?? "";
  }

  dateStr = dateStr
    .replace("invalid", "")
    .replace("Invalid", "")
    .replace("undefined", "")
    .replace("undefine", "");

  return <p style={{ margin: 0 }}>{dateStr}</p>;
}

DateTimeFormat.displayName = "standalone:DateTimeFormat";

export function DateFormat(props: LimeFormatterProps) {
  const v = useCellValue(props.cell);
  const ns = `${v.split(".")[1]}`;
  var dateStr = moment(v).format("YYYY-MM-DD").toString();
  return <p style={{ margin: 0 }}>{dateStr}</p>;
}

DateTimeFormat.displayName = "standalone:DateFormat";

export function EnumFormat(props: LimeFormatterProps) {
  const value = useCellValue(props.cell);
  const formattedText = props.def.choices[value] ?? props.def.choices[value.toLowerCase()];

  if (!value || value === "null" || value === "undefined") {
    return <></>;
  } else {
    return (
      <CustomChip color={chipColorCode[value] || "info"}>
        {formattedText}
      </CustomChip>
    );
  }
}

EnumFormat.displayName = "standalone:EnumFormat";

export function SymbolFormat(props: LimeFormatterProps) {
  const value = useCellValue(props.cell);

  if (!value || value === "null" || value === "undefined") {
    return <></>;
  } else {
    return <CustomChip color="primary">{value}</CustomChip>;
  }
}

SymbolFormat.displayName = "standalone:SymbolFormat";

export function TextFormat(props: LimeFormatterProps) {
  const value = useCellValue(props.cell);

  if (!value || value === "null" || value === "undefined") {
    return <></>;
  } else {
    return <p style={{ margin: 0 }}>{value}</p>;
  }
}

TextFormat.displayName = "standalone:TextFormat";

export function BooleanFormat(props: LimeFormatterProps) {
  const value = useCellValue(props.cell);

  return (
    <div
      style={{
        textAlign: "center",
        verticalAlign: "center",
      }}
    >
      {value === "true" ? <CheckBox /> : <CheckBoxOutlineBlank />}
    </div>
  );
}

TextFormat.displayName = "standalone:BooleanFormat";

function stringOrNumberToNumber(x: string | number): number {
  return parseFloat(`${x}`);
}

export function SummaryTotalFormat(
  props: LimeFormatterProps<{
    colName: string;
    money?: boolean;
  }>
) {
  const data = useResult();

  const total = useMemo(() => {
    return data
      ?.map((x) => x[props.colName])
      .reduce(
        (sum, curr) =>
          stringOrNumberToNumber(sum) + stringOrNumberToNumber(curr),
        0
      );
  }, [data, props.colName]);

  const tip = useMemo(() => {
    if (data) {
      var max = 0;
      var min = Number.MAX_VALUE;
      var avg = 0;

      data
        .map((x) => x[props.colName])
        .forEach((cell) => {
          const c = stringOrNumberToNumber(cell);
          max = Math.max(c, max);
          min = Math.min(c, min);
          avg += c;
        });
      avg /= data.length;

      return `Min: ${CommaFormatter.format(min)}, Max: ${CommaFormatter.format(
        max
      )}, Avg: ${CommaFormatter.format(avg)}`;
    }

    return "";
  }, [data, props.colName]);

  return (
    <Tooltip title={tip} placement="top">
      <span>
        <NumberTypograpy value={total} money={props.money} />
      </span>
    </Tooltip>
  );
}

SummaryTotalFormat.displayName = "standalone:SummaryTotalFormat";

export function SummaryMoneyTotalFormat(
  props: LimeFormatterProps<{
    colName: string;
  }>
) {
  return <SummaryTotalFormat money {...props} />;
}

SummaryMoneyTotalFormat.displayName = "standalone:SummaryMoneyTotalFormat";
