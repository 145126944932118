import config from "next/config";
import React from "react";
import { CreateStateContext } from "./stateContext";
import { useResult } from "./useQuery";

const [SelectedRowsProvider, useSelectedRows, useSetSelectedRows] =
  CreateStateContext<Set<React.Key>>("selected rows", new Set());

export { SelectedRowsProvider, useSelectedRows, useSetSelectedRows };

export function useSelectedResults() {
  const data = useResult();
  const rows = useSelectedRows();

  if (!data && !rows) {
    throw "no context";
  }

  const normalIndexes = Array.from(rows.values());
  return data.filter((x) => normalIndexes.includes(x.key));
}
