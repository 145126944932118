import {
  Box,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  LinearProgress,
  Chip,
  Checkbox,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

export type MultiSelectProps<T> = {
  value: Array<T>;
  options: Array<T>;
  onChange: (selected: Array<T>) => void;
  renderChipLabel: (item: T) => string;
  renderMenuLabel: (item: T) => string;
};

export function MultiSelect<T>(props: MultiSelectProps<T>) {
  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "none",
      }}
    >
      <Select<T[]>
        data-testid="select"
        value={props.value}
        label="Age"
        onChange={(e) =>
          Array.isArray(e.target.value) ? props.onChange(e.target.value) : null
        }
        fullWidth
        variant="filled"
        multiple
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              overflowY: "none",
              overflowX: "overlay",
              margin: 0,
              padding: 0,
              height: "fit-content",
              gap: 0.1,
            }}
          >
            {selected
              .map((x) => props.options.find((y) => x === y))
              .map((item, i) =>
                item ? (
                  <Chip
                    key={i}
                    label={
                      <Typography variant="caption">
                        {props.renderChipLabel(item)}
                      </Typography>
                    }
                    onDelete={() => {}}
                    sx={{
                      backgroundColor: "background.paper !important",
                      "& *": {
                        py: "0 !important",
                        my: "0 !important",
                      },
                    }}
                    deleteIcon={
                      <span
                        onMouseDown={(event) => {
                          event.stopPropagation();
                          const I = props.value.findIndex((x) => x === item);
                          if (I >= 0) {
                            props.onChange(
                              props.value.filter((_, i) => i !== I)
                            );
                          }
                        }}
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <ClearIcon sx={{ fontSize: "14px" }} />
                      </span>
                    }
                    size="small"
                  />
                ) : null
              )}
          </Box>
        )}
        sx={{
          borderRadius: "5px",
        }}
      >
        {props.options.map((item, i) => (
          <MenuItem key={i} value={item as any} sx={{ height: "2rem" }}>
            <Typography variant="body2">
              <Checkbox
                checked={props.value.includes(item)}
                sx={{
                  height: "100%",
                }}
              />
              {props.renderMenuLabel(item)}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
