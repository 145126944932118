import { Box } from "@mui/material";
import moment from "moment";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { DATETIME_TYPE } from "./DTRMain";
import { useCallback } from "react";
import {
  useDateTimeRPData,
  useSetDateTimeRPData,
} from "../../hooks/datetimerangepicker-hook/useDateTimeRangePicker";

function TimePicker(props: { type: DATETIME_TYPE }) {
  const dateTimeRPData = useDateTimeRPData();
  const { startDate, endDate } = dateTimeRPData;
  const setDateTimeRPDate = useSetDateTimeRPData();

  const date = moment(props.type === "START_DATETIME" ? startDate : endDate);
  const time = date.format("HH:mm:ss");

  const handleTimeValue = useCallback(
    (e) => {
      const dStr = moment(`${date.format("YYYY/MM/DD")} ${e.target.value}`);
      if (props.type === "START_DATETIME") {
        setDateTimeRPDate({ ...dateTimeRPData, startDate: dStr });
      } else {
        setDateTimeRPDate({ ...dateTimeRPData, endDate: dStr });
      }
    },
    [props.type, dateTimeRPData, setDateTimeRPDate, date]
  );

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <AccessTimeRoundedIcon sx={{ fontSize: 18 }} />
      <input
        style={{
          background: "inherit",
          borderRadius: "3px",
        }}
        type="time"
        step="1"
        value={time}
        onChange={handleTimeValue}
      />
    </Box>
  );
}

TimePicker.displayName = "standalone:TimePicker";

export default TimePicker;
