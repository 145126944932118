import * as React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Risk from "./Risk";
import TradeDetails from "./MDTradeDetails";
import {
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Typography,
} from "@mui/material";
import {
  FixMsgData,
  useShowFixMessageModal,
} from "../../../modal/FixMessageModal";
import { MasterDetailsColContentType } from "../../../../types.d";

type RowProps = {
  row: MasterDetailsColContentType;
};
type ListType = "Trade Details" | "Risk";

function MasterDetailsRow({ row }: RowProps) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;

  const listItems = ["Trade Details", "Risk"];
  const [selectedIndex, setSelectedIndex] =
    React.useState<ListType>("Trade Details");

  const fixMsgData: FixMsgData[] = row.tradeDetailsData
    .map(({ fixMsgIn, fixMsgOut, efixReceived }) => ({
      fixMsgIn: `${fixMsgIn}`,
      fixMsgOut: `${fixMsgOut}`,
      efixReceived: `${efixReceived}`,
    }))
    .reverse();

  const showFixMsg = useShowFixMessageModal({
    data: fixMsgData,
  });

  const handleListItemClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, nextView: ListType) => {
      if (nextView !== null) {
        setSelectedIndex(nextView);
      }
    },
    [setSelectedIndex]
  );

  return (
    <Box
      onContextMenu={(e) => {
        e.stopPropagation();
        return;
      }}
      sx={{
        height: "180px",
        display: "flex",
        flexDirection: "column",
        position: "sticky",
      }}
    >
      <ToggleButtonGroup
        exclusive
        sx={{
          borderBottom: `1px solid ${alpha(theme.palette.success.main, 0.1)}`,
          borderRadius: 0,
        }}
        size="small"
        color="success"
        value={selectedIndex}
        onChange={handleListItemClick}
      >
        {listItems.map((primary, index) => (
          <ToggleButton
            disableTouchRipple
            key={index}
            sx={{
              border: 0,
              borderRadius: 0,
              fontSize: 12,
              height: 22,
              padding: "0px 16px",
              textTransform: "none",
            }}
            value={primary}
          >
            {primary}
          </ToggleButton>
        ))}
        <Button
          disableTouchRipple
          color="inherit"
          variant="text"
          onClick={showFixMsg}
          sx={{
            borderRadius: 0,
            textTransform: "none",
            padding: "0px 16px",
            height: 22,
          }}
        >
          <Typography variant="caption">FIX Message</Typography>
        </Button>
      </ToggleButtonGroup>
      {selectedIndex == "Trade Details" && (
        <TradeDetails data={row.tradeDetailsData} />
      )}
      {selectedIndex === "Risk" && <Risk data={row.riskData} />}
    </Box>
  );
}

MasterDetailsRow.displayName = "standalone:MasterDetailsRow";

export default MasterDetailsRow;
