import React, { useState, useCallback } from "react";
import { ModalFactory, ModalControls } from "./modal";
import {
  Box,
  Typography,
  LinearProgress,
  Chip,
} from "@mui/material";
import {
  useSelectedResults,
  useSelectedRows,
  useSetSelectedRows,
} from "../hooks/useSelectedRows";
import { CancelResp, useCancelSelectedRows } from "../hooks/useCancel";
import { ErrorBox } from "./components/errorBox";
import { CustomButton } from "./components/customButton";

function CancelModal(props: ModalControls<unknown>) {
  const selectedRows = useSelectedRows();
  if (!selectedRows) {
    throw "selected rows is undefined";
  }

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resps, setResps] = useState<CancelResp[]>([]);
  const selectedResults = useSelectedResults();
  const setSelectedResults = useSetSelectedRows();

  const cancel = useCancelSelectedRows((count, total) => {
    if (total > 0) {
      setProgress((count / total) * 100);
    }
  });

  const handleCancelOrders = useCallback(async () => {
    setLoading(true);
    const resps = await cancel();
    if (resps) {
      setResps(resps);
    }
  }, [cancel, setResps]);

  const handleRemoveOrder = useCallback(
    (id: string | number) => () => {
      setSelectedResults((prev) => {
        if (prev) {
          prev.delete(parseInt(`${id}`) * 2 + 1);
        }
        return new Set(prev);
      });
    },
    [setSelectedResults]
  );

  return (
    <>
      {progress > 0 ? (
        <Box sx={{ my: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      ) : null}

      {loading ? (
        <>
          <Typography variant="h5" data-testid="title">
            Successfuly canceled orders
          </Typography>
          <Box sx={{ my: 1 }}>
            <ErrorBox resps={resps} />
          </Box>
          <CustomButton data-testid="success" color="success" onClick={props.hide} label="Close" />
        </>
      ) : (
        <Box>
          <Typography variant="h5" data-testid="title">
            Cancel {selectedRows.size} order
            {selectedRows.size > 1 ? "s" : ""}
          </Typography>
          <Typography variant="caption" data-testid="caption">
            Are you sure you want to cancel{" "}
            {selectedRows.size > 1 ? "these" : "this"} order
            {selectedRows.size > 1 ? "s" : ""}?
          </Typography>
          <Box
            sx={{
              overflowX: "none",
              overflowY: "scroll",
              maxHeight: "20vh",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {selectedResults.map((result, i) => (
              <Box key={i} sx={{ margin: "2px" }}>
                <Chip
                  label={result.clOrdId}
                  onDelete={handleRemoveOrder(result.id)}
                />
              </Box>
            ))}
          </Box>
          <CustomButton
            data-testid="yesbutton"
            onClick={handleCancelOrders}
            color="success"
            label="Cancel Orders"
          />
          <CustomButton
            data-testid="nobutton"
            onClick={() => props.hide()}
            color="error"
            label="Abort"
          />
        </Box>
      )}
    </>
  );
}

const [CancelModalProvider, useShowCancelModal] = ModalFactory(
  "cancel",
  (props) => <CancelModal {...props} />
);

export { CancelModalProvider, useShowCancelModal };
