import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ModalFactory, ModalControls } from "./modal";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  LinearProgress,
  Chip,
  Checkbox,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelectedRows } from "../hooks/useSelectedRows";
import { useDoFetchAll } from "../hooks/useQuery";
import {
  AccountInfo,
  CancelResp,
  useMassCancel,
  useMyAccountNumbers,
} from "../hooks/useCancel";
import { Confirmation } from "./components/confirmation";
import { ErrorBox } from "./components/errorBox";
import { CustomButton } from "./components/customButton";
import { MultiSelect } from "../body/MultiSelect";

function MassCancel(props: ModalControls<unknown>) {
  const myAccounts = useMyAccountNumbers();
  const [selectedAccounts, setSelectedAccounts] = useState<AccountInfo[]>([]);
  const [confirmed, setConfirmed] = useState(false);
  const [progress, setProgress] = useState(0);
  const [resps, setResps] = useState<CancelResp[]>([]);

  const massCancel = useMassCancel(selectedAccounts, (i, total) => {
    setProgress((i / total) * 100);
  });

  const handleRemoveAccount = useCallback(
    // (accountNumber: number) => () => {
    (account: AccountInfo) => {
      setSelectedAccounts((prev) =>
        prev.filter((x) => x.number !== account.number)
      );
    },
    [setSelectedAccounts]
  );

  const refresh = useDoFetchAll();
  const handleMassCancel = useCallback(async () => {
    // const account = selectedAccount[i];
    const resps = await massCancel();
    if (resps) {
      setResps((prev) => prev.concat(resps));
    }
  }, [massCancel]);

  return (
    <>
      {progress > 0 ? (
        <Box sx={{ my: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      ) : null}

      <Typography variant="h5" data-testid="title">
        Mass Cancel
      </Typography>

      <MultiSelect<AccountInfo>
        onChange={setSelectedAccounts}
        options={myAccounts ?? []}
        renderChipLabel={(x) => x.name}
        renderMenuLabel={(x) => x.name}
        value={selectedAccounts}
      />

      {selectedAccounts.length > 0 ? (
        <>
          <ErrorBox resps={resps} />
          <Box
            sx={{
              flexGrow: 1,
              height: "2rem",
              my: 1,
            }}
          >
            <Confirmation onConfirmed={() => setConfirmed(true)} />
          </Box>
        </>
      ) : null}

      {confirmed && progress == 0 ? (
        <CustomButton
          data-testid="success"
          onClick={handleMassCancel}
          label="Cancel All Orders"
          color="success"
        />
      ) : (
        <></>
      )}
      <CustomButton
        data-testid="failure"
        onClick={() => props.hide()}
        label={progress > 0 ? "Close" : "Abort"}
        color="error"
      />
    </>
  );
}

const [MassCancelModalProvider, useShowMassCancelModal] = ModalFactory(
  "cancel",
  (props) => <MassCancel {...props} />
);

export { MassCancelModalProvider, useShowMassCancelModal };
