import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import moment, { Moment } from "moment";
import { useCallback } from "react";
import {
  useDateTimeRPData,
  useSetDateTimeRPData,
} from "../../hooks/datetimerangepicker-hook/useDateTimeRangePicker";

type TimeSetting = {
  [key: string]: {
    label: string;
    start: (m: Moment) => Moment;
    end: (m: Moment) => Moment;
  };
};

export const DefaultTimeSettings: TimeSetting = {
  "Last 1 Minute": {
    label: "LAST_1_MIN",
    start: (m) => m.subtract(1, "minute"),
    end: (m) => m,
  },
  "Last 5 Minute": {
    label: "LAST_5_MIN",
    start: (m) => m.subtract(5, "minute"),
    end: (m) => m
  },
  "Last 15 Minute": {
    label: "LAST_15_MIN",
    start: (m) => m.subtract(15, "minute"),
    end: (m) => m,
  },
  Today: {
    label: "TODAY",
    start: (m) => m.hour(4).minute(0).second(0),
    end: (m) => m.hour(20).minute(0).second(0),
  },
  Yesterday: {
    label: "YESTERDAY",
    start: (m) => m.subtract(1, "day").hour(4).minute(0).second(0),
    end: (m) => m.subtract(1, "day").hour(20).minute(0).second(0),
  },
  "Last 7 Days": {
    label: "LAST_7_DAYS",
    start: (m) => m.subtract(7, "day").hour(4).minute(0).second(0),
    end: (m) => m.hour(20).minute(0).second(0),
  },
  "Last 30 Days": {
    label: "LAST_30_DAYS",
    start: (m) => m.subtract(30, "day").hour(4).minute(0).second(0),
    end: (m) => m.hour(20).minute(0).second(0),
  },
};

const DefaultDTRPOptions = () => {
  const { defaultDateTimeLabel } = useDateTimeRPData();
  const setDateTimeRPData = useSetDateTimeRPData();

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, label: string) => {
      const selectedLabel = label ?? "Today";
      setDateTimeRPData((prev) => {
        if (prev) {
          return {
            startDate: DefaultTimeSettings[selectedLabel].start(moment()),
            endDate: DefaultTimeSettings[selectedLabel].end(moment()),
            defaultDateTimeLabel: selectedLabel,
          };
        } else {
          throw "undefined";
        }
      });
    },
    [setDateTimeRPData]
  );

  return (
    <ToggleButtonGroup
      orientation="vertical"
      value={defaultDateTimeLabel}
      onChange={handleChange}
      exclusive
    >
      {Object.keys(DefaultTimeSettings).map((label) => (
        <ToggleButton
          disableTouchRipple
          size="small"
          value={label}
          key={label}
          sx={{ textTransform: "none", borderRadius: 0, height: "100%" }}
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default DefaultDTRPOptions;
