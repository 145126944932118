import { Box, alpha, useTheme, Modal } from "@mui/material";
import moment from "moment";
import { useDateTimeRPData, useSetDateTimeRPData } from "../../hooks/datetimerangepicker-hook/useDateTimeRangePicker";
import { useSetArgumentField } from "../../hooks/useQuery";
import DatePicker from "./DatePicker";
import DefaultDTRPOptions, { DefaultTimeSettings } from "./DefaultDTRPOptions";
import TimePicker from "./TimePicker";
import { useEffect } from "react";

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

function DTRPModal({ open, setOpen }: Props) {
  const theme = useTheme();

  const { defaultDateTimeLabel, startDate, endDate } = useDateTimeRPData();
  const setDateTimeRPData = useSetDateTimeRPData();

  const setCurrentDate = useSetArgumentField();

  const handleOnClose = () => {
    let v: string[] = [];

    if (defaultDateTimeLabel) {
      const tmp = DefaultTimeSettings[defaultDateTimeLabel].label;
      v = [`r:${tmp}`];
    } else {
      v = [
        "gte:" + moment(startDate).toISOString(),
        "lte:" + moment(endDate).toISOString(),
      ];
    }
    setCurrentDate(v);
    setOpen(false);
  };

  useEffect(() => {
    if (open && defaultDateTimeLabel) {
      console.log("Date: ", moment())
      console.log("calling", DefaultTimeSettings[defaultDateTimeLabel].start(moment()))
      setDateTimeRPData((prev) => {
        if (prev) {
          return {
            ...prev,
            startDate: DefaultTimeSettings[defaultDateTimeLabel].start(moment()),
            endDate: DefaultTimeSettings[defaultDateTimeLabel].end(moment()),
          };
        } else {
          throw "undefined";
        }
      });
    }
  }, [open, defaultDateTimeLabel])

  return (
    <Modal keepMounted open={open} onClose={handleOnClose} >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            background: theme.palette.mode === "dark" ? "#181D1F" : "#fff",
          }}
        >
          <DefaultDTRPOptions />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
              <Box>
                <Box data-testid="time-start" sx={{ textAlign: "center" }}>
                  <DatePicker type="START_DATETIME" />
                  <TimePicker type="START_DATETIME" />
                </Box>
              </Box>
              <Box
                sx={{
                  borderLeft: `1px solid ${alpha(
                    theme.palette.success.main,
                    0.1
                  )}`,
                }}
              >
                <Box data-testid="time-end" sx={{ textAlign: "center" }}>
                  <DatePicker type="END_DATETIME" />
                  <TimePicker type="END_DATETIME" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default DTRPModal;
