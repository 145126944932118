import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Grid,
  ButtonGroup,
  Button,
  ButtonProps,
  Tab,
  Tabs,
  useTheme,
  Tooltip,
} from "@mui/material";
import { useCallback, useState } from "react";
import cleanFixMsgData from "../../utils/cleanFixMsgData";
import { ModalFactory } from "./modal";
import copy from "copy-to-clipboard";
import { useSetShowNotification } from "../hooks/useGlobalState";
import { Download } from "@mui/icons-material";
import { useFixMsgDict } from "../hooks/useFixMsgDictData";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: 1,
  minWidth: 650,
  transform: "translate(-50%, -50%)",
  padding: "16px",
};

const ModalBtnProps: ButtonProps = {
  disableTouchRipple: true,
  sx: { textTransform: "none", mt: 2, mr: 0.2 },
  disableElevation: true,
  variant: "outlined",
  size: "small",
};

export type FixMsgData = {
  fixMsgIn?: string;
  fixMsgOut?: string;
  efixReceived?: string;
};
interface Props {
  hide?: () => void;
  data: Array<FixMsgData>;
}

const [FixMessageModalProvider, useShowFixMessageModal] = ModalFactory(
  "fix",
  ({ hide, data }: Props) => {
    const theme = useTheme();
    const themeMode = theme.palette.mode;

    const fixMsgDict = useFixMsgDict();
    const cleanedFixMsgData = data.map((obj) =>
      Object.values(cleanFixMsgData(obj))
    );

    const msgType = cleanedFixMsgData.flatMap((arr) => {
      return arr.filter((ele) => ele.tag === "35");
    });

    const [next, setNext] = useState(0);

    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
    const rowMsg = ["fixMsgIn", "fixMsgOut", "efixReceived"];

    const setShowNotification = useSetShowNotification();
    const handleOnCopy = () => {
      copy(data[next][rowMsg[value]]);
      setShowNotification({
        open: true,
        type: "success",
        message: "Copied",
      });
    };

    const handleFirst = useCallback(() => {
      setNext(0);
    }, [setNext]);

    const handlePrev = useCallback(() => {
      setNext((prev) => (prev - 1 < 0 ? data.length - 1 - prev : prev - 1));
    }, [setNext, data]);

    const handleNext = useCallback(() => {
      setNext((prev) => (prev + 1) % data.length);
    }, [setNext, data]);

    const handleLast = useCallback(() => {
      setNext(data.length - 1);
    }, [setNext, data]);

    const downloadFixData = () => {
      const dataStr = data[next][rowMsg[value]].toString();
      if (dataStr === "") {
        return;
      }
      const ele = document.createElement("a");
      const file = new Blob([dataStr], {
        type: "text/plain",
      });
      ele.href = URL.createObjectURL(file);
      ele.download = `${rowMsg[value]}.txt`;
      document.body.appendChild(ele);
      ele.click();
    };

    const msgTypeCode =
      msgType[next]?.efixReceived ||
      msgType[next]?.fixMsgIn ||
      msgType[next]?.fixMsgOut;

    return (
      <Grid
        container
        sx={{
          ...style,
          background: themeMode === "light" ? "#fff" : "#181D1F",
        }}
      >
        {data.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Typography
                color="success.main"
                id="modal-modal-title"
                variant="subtitle1"
                fontWeight={500}
                sx={{ mb: 1 }}
              >
                Message Type: {msgTypeCode}
              </Typography>
              <TableContainer
                elevation={0}
                sx={{
                  maxHeight: 400,
                  background: "inherit",
                  boxShadow: "0 0 1px #888",
                }}
                component={Paper}
              >
                <Table stickyHeader size="small" sx={{ minWidth: 550 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: "10ch" }}>Tag #</TableCell>
                      <TableCell>Field Name</TableCell>
                      <TableCell>Fix In</TableCell>
                      <TableCell>Fix Out</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cleanedFixMsgData[next].map((row) => (
                      <TableRow
                        key={row?.tag}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row?.tag !== "null" ? row?.tag : null}
                        </TableCell>
                        {fixMsgDict ? (
                          <TableCell>{fixMsgDict[row?.tag]}</TableCell>
                        ) : null}
                        <TableCell>
                          {row?.fixMsgIn}
                          {row?.efixReceived}
                        </TableCell>
                        <TableCell>{row?.fixMsgOut}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                centered
                TabIndicatorProps={{ sx: { display: "none" } }}
                value={value}
                onChange={handleChange}
              >
                <Tab
                  disableTouchRipple
                  sx={{
                    color:
                      value === 0
                        ? `${theme.palette.success.main} !important`
                        : "#aaa",
                    textTransform: "none",
                  }}
                  label="Fix Message In"
                />
                <Tab
                  disableTouchRipple
                  sx={{
                    color:
                      value === 1
                        ? `${theme.palette.success.main} !important`
                        : "#aaa",
                    textTransform: "none",
                  }}
                  label="Fix Message Out"
                />

                <Tab
                  disableTouchRipple
                  sx={{
                    color:
                      value === 2
                        ? `${theme.palette.success.main} !important`
                        : "#aaa",
                    textTransform: "none",
                  }}
                  label="Efix Received"
                />
              </Tabs>
              <Tooltip title="Click to copy">
                <TextField
                  color="success"
                  onClick={handleOnCopy}
                  InputProps={{
                    size: "small",
                    style: {
                      fontSize: 12,
                      borderRadius: 2,
                    },
                  }}
                  fullWidth
                  value={
                    data[next][rowMsg[value]]
                      ? data[next][rowMsg[value]]
                      : "No data found"
                  }
                  id="fullWidth"
                  multiline
                  rows={3}
                />
              </Tooltip>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <ButtonGroup disableElevation>
                  <Button {...ModalBtnProps} color="info" onClick={handleFirst}>
                    First
                  </Button>
                  <Button {...ModalBtnProps} color="info" onClick={handlePrev}>
                    Previous
                  </Button>
                  <Button {...ModalBtnProps} color="info" onClick={handleNext}>
                    Next
                  </Button>
                  <Button {...ModalBtnProps} color="info" onClick={handleLast}>
                    Last
                  </Button>
                  <Button
                    sx={{ mt: 2 }}
                    size="small"
                    color="success"
                    onClick={downloadFixData}
                  >
                    <Download />
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Button
                  {...ModalBtnProps}
                  color="success"
                  onClick={() => (hide ? hide() : null)}
                >
                  Ok
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography color="error.main">No FIX Message Data Found</Typography>
        )}
      </Grid>
    );
  }
);

export { FixMessageModalProvider, useShowFixMessageModal };
