import { alpha, useTheme } from "@mui/material";

type ChipProps = {
  children: string;
  color: string;
};

function CustomChip({ children, color }: ChipProps) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  return (
    <span
      style={{
        borderRadius: "2px",
        fontSize: "12px",
        padding: "0px 6px",
        top: "-1.5px",
        position: "relative",
        display: "inline-flex",
        height: "18px",
        alignItems: "center",
        fontWeight: themeMode === "light" ? 500 : "normal",
        color: theme.palette[color].main,
        background: alpha(theme.palette[color].light, 0.1),
      }}
    >
      {children}
    </span>
  );
}

export default CustomChip;
