import { CreateMappingProvider } from "./autocompleteMapper";

const [
  BuyingPowerGroupsProvider,
  useBuyingPowerGroups,
  BuyingPowerGroupsContext,
] = CreateMappingProvider(
  "/v2/buying-power/mine/groups",
  (obj) => {
    return obj.reduce(
      (obj, val) => ({
        ...obj,
        [val.bpGroupId]: `${val.bpGroupName}`,
      }),
      {}
    );
  },
  "search"
);

export { BuyingPowerGroupsProvider, BuyingPowerGroupsContext };
