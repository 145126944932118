import React, { useCallback, useState } from "react";
import { useTheme, InputAdornment, IconButton } from "@mui/material";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Close from "@mui/icons-material/Close";
import { useArgumentField, useSetArgumentField } from "../../hooks/useQuery";
import { useSetSymbolArg } from "../../hooks/useSymbolLookup";
import { useColDef } from "./utils";
import { RoundedTextField } from "./StyledTextField";
import { ClearInputAdornment } from "./Clear";

export function CustomTextField() {
  const def = useColDef();
  const theme = useTheme();

  const value = useArgumentField() ?? "";
  const setValue = useSetArgumentField();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value.split(",").map((x) => x.trim()));
    },
    [setValue]
  );

  const clear = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const [focused, setFocused] = useState(false);

  return (
    <RoundedTextField
      id={`ch-txtField-${def.rdgColumn.key}`}
      label={def.rdgColumn.name}
      variant="filled"
      value={Array.isArray(value) ? value.join(", ") : value}
      onChange={onChange}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      sx={{
        width: "100%",
      }}
      InputProps={{
        endAdornment: (
          <ClearInputAdornment
            id={`btn-clear-${def.rdgColumn.key}`}
            visible={focused || (value?.length ? true : false)}
          />
        ),
      }}
    />
  );
}
