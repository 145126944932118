import {
  Tooltip,
  ToggleButton,
  Select,
  MenuItem,
  alpha,
  useTheme,
  ButtonGroup,
  SelectChangeEvent,
} from "@mui/material";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import { useArgs, UseQueryArgs } from "../../hooks/useQuery";
import { useCallback, useMemo, useState } from "react";

type Props = {
  disabled?: (args: UseQueryArgs) => boolean;
  autorefresh: { active: boolean; seconds: number };
  handleSetAutoRefresh: (autorefresh: boolean) => void;
  handleSelectSeconds: (seconds: number) => void;
};

export const AutoRefreshGridBtn = (props: Props) => {
  const theme = useTheme();
  const args = useArgs();
  const { handleSelectSeconds, handleSetAutoRefresh } = props;
  const { active, seconds } = props.autorefresh;

  const handleOnChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      handleSelectSeconds(parseInt(e.target.value));
    },
    [handleSelectSeconds]
  );

  const handleOnToggle = useCallback(() => {
    handleSetAutoRefresh(!active);
  }, [active, handleSetAutoRefresh]);

  const disabled = useMemo(() => {
    if (props.disabled) {
      if (props.disabled(args)) {
        handleSetAutoRefresh(false);
      }
      return props.disabled(args);
    } else {
      return false;
    }
  }, [args, props.disabled, handleSetAutoRefresh]);

  return (
    <ButtonGroup
      sx={{
        background: alpha(theme.palette.info.dark, 0.1),
      }}
    >
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={String(seconds)}
        label="seconds"
        onChange={handleOnChange}
        sx={{
          height: "24px",
          background: "none",
          borderTopRightRadius: 0,
          borderBottomLeftRadius: "3px",
          ".MuiInputBase-input": { paddingRight: "6px !important" },
          "&.Mui-disabled": {
            background: "none",
          },
        }}
        IconComponent={() => null}
        disabled={!active}
      >
        <MenuItem sx={{ fontSize: "12px" }} value={10000}>
          10s
        </MenuItem>
        <MenuItem sx={{ fontSize: "12px" }} value={30000}>
          30s
        </MenuItem>
      </Select>
      <Tooltip title={`Auto Refresh ${active ? " On" : " Off"}`}>
        <ToggleButton
          disableRipple
          disabled={disabled}
          value="check"
          onChange={handleOnToggle}
          selected={active}
          color="info"
          sx={{
            height: "24px",
            width: "28px",
            border: 0,
            background: "none",
            borderRadius: 0,
            borderTopRightRadius: "3px",
            borderBottomRightRadius: "3px",
            overflow: "hidden",
            "&.Mui-disabled": {
              border: 0,
            },
          }}
        >
          <AutorenewRoundedIcon
            fontSize="small"
            sx={{ transform: "rotate(90deg)" }}
          />
        </ToggleButton>
      </Tooltip>
    </ButtonGroup>
  );
};

export default AutoRefreshGridBtn;
