import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails,IconButton, Typography, Box, Grid, Tooltip } from '@mui/material';
import { ExpandMore,InfoOutlined, Warning } from '@mui/icons-material';
import { ColumnDef } from '../../types.d';
import { InputWrapper } from '../body/common/InputWrapper';
import { CustomInputField } from '../body/new-inputs/InputField';

type CustomAccordionProps = {
    title: string;
    definitions: Required<ColumnDef>[];
    children?: React.ReactNode; // 'children' is optional
    isValid?: boolean;
    validationMessage?: string;
    showHelpIcon?: boolean;
    helpText?: string;
    highlightKeys?: string[];
};

const CustomAccordion = ({
  title,
  definitions,
  children,
  isValid = true,
  validationMessage = 'This field is required!',
  showHelpIcon = false,
  helpText = "Helpful information about this section",
  highlightKeys = [], // Added highlightKeys to the props
}: CustomAccordionProps) => (
  <Box width="100%">
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{title}</Typography>
        {!isValid && (
          <Tooltip title={validationMessage} placement="right">
            <Warning color="error" sx={{ ml: 1 }} />
          </Tooltip>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ position: 'relative' }}>
        {showHelpIcon && (
          <Tooltip title={helpText}>
            <IconButton sx={{ position: 'absolute', top: 0, right: 0 }}>
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        )}

        <Grid container gap={2}>
          {definitions.map((def, i) => {
            // Determine if the current definition should be highlighted
            const isHighlighted = highlightKeys.includes(def.rdgColumn.key);
            const highlightStyles = isHighlighted ? {
              boxShadow: '0px 0px 8px 2px rgba(50, 150, 50, 0.7)',
              borderRadius: '4px',
              // Don't add padding or margin that could disrupt the layout
            } : undefined;

            return (
              <InputWrapper def={def} key={i} highlightStyle={highlightStyles}>
                <CustomInputField columnDefinition={def} noDecimals hideMode />
              </InputWrapper>
            );
          })}
          {children}
        </Grid>
      </AccordionDetails>
    </Accordion>
  </Box>
);

export default CustomAccordion;
