import React, { useCallback } from "react";
import Image from "next/image";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/MenuItem";
import { alpha, useTheme } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import * as Sentry from "@sentry/nextjs";

import limeLogoLight from "../../assets/lime-logo-light.png";
import limeLogoDark from "../../assets/lime-logo-dark.png";
import { useUser } from "@auth0/nextjs-auth0";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useShowMassCancelModal } from "../modal/massCancel";
import getConfig from "next/config";
import {
  useWorkspacesNames,
  useSetWorkspaceName,
  useWorkspaceName,
  useAddWorkspace,
  useDeleteWorkspace,
  DEFAULT_NAME,
} from "../hooks/useWorkspaces";
import { BorderRight, DataObjectRounded } from "@mui/icons-material";
import { useIsEmployee } from "../hooks/useAccounts";
import { Chip } from "@mui/material";
import { useToken } from "../hooks/useToken";

interface ThemeMode {
  toggleColorMode: () => void;
}

function DropdownItem(props: {
  name: string;
  icon: React.ReactNode;
  handleCloseUserMenu: () => void;
  href?: string;
}) {
  return (
    <MenuItem
      id={`btn-${props.name.toLowerCase()}`}
      href={props.href || "#"}
      component="a"
      disableRipple
      key={props.name}
      onClick={props.handleCloseUserMenu}
    >
      {props.icon}
      <Typography sx={{ ml: 1 }} variant="caption" textAlign="center">
        {props.name}
      </Typography>
    </MenuItem>
  );
}

DropdownItem.displayName = "standalone:DropdownItem";

function SelectWorkspace() {
  const workspaceNames = useWorkspacesNames();

  const workspaceName = useWorkspaceName();
  const setWorkspaceName = useSetWorkspaceName();

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setWorkspaceName(event.target.value);
    },
    [setWorkspaceName]
  );

  const addWorkspace = useAddWorkspace();

  const handleAdd = useCallback(() => {
    const name = prompt("Name your new workspace");
    if (name) {
      addWorkspace(name);
    }
  }, [addWorkspace]);

  const deleteWorkspace = useDeleteWorkspace();

  const handleDelete = useCallback(
    (name: string) => {
      return (e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (confirm(`Are you sure you want to delete ${name}`)) {
          deleteWorkspace(name);
        }
      };
    },
    [deleteWorkspace]
  );

  return (
    <Select
      value={workspaceName}
      onChange={handleChange}
      sx={{
        px: 0.5,
        fontSize: 12,
        borderRadius: 0,
        height: "28px",
      }}
      variant="filled"
      size="small"
      defaultValue={DEFAULT_NAME}
    >
      {workspaceNames.map((name, i) => (
        <MenuItem value={name} key={i}>
          <Typography variant="caption">{name}</Typography>
          {name === workspaceName ? null : (
            <IconButton
              disableRipple
              disableTouchRipple
              onClick={handleDelete(name)}
              sx={{
                width: 20,
                textAlign: "center",
                padding: 0,
                px: 1,
              }}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          )}
        </MenuItem>
      ))}
      <Button onClick={handleAdd} disableRipple>
        <Typography variant="caption">Add </Typography>
      </Button>
    </Select>
  );
}

function useCopyToken() {
  const token = useToken();
  return useCallback(() => {
    if ("clipboard" in navigator) {
      navigator.clipboard
        .writeText(token)
        .then(() => {
          alert("Copied your authentication code to your clipboard");
        })
        .catch((e) => {
          alert("Unable to copy token to your clipboard");
          console.error("Clipboard error", e);
          Sentry.captureException(e);
        });
    } else {
      alert("Unable to access clipboard due to security issues");
      console.error("Clipboard not in navigator");
    }
  }, [token]);
}

function CustomAppBar({ toggleColorMode }: ThemeMode) {
  const theme = useTheme();
  const { user } = useUser();
  const isEmployee = useIsEmployee();
  const copyToken = useCopyToken();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const showMassCancel = useShowMassCancelModal({});

  const showFeedback = useCallback(() => {
    throw new Error("feedback");
  }, [user]);

  const { publicRuntimeConfig } = getConfig();
  const version = publicRuntimeConfig.build_number;
  const server_name = publicRuntimeConfig.server_name;

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        background: theme.palette.background.default,
        borderBottom: `1px solid ${alpha(theme.palette.success.main, 0.1)}`,
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "28px",
        }}
      >
        <Box>
          <Button
            disableRipple
            sx={{
              "&:hover": {
                background: "none",
              },
            }}
            variant="text"
          >
            <Image
              src={
                theme.palette.mode === "light" ? limeLogoLight : limeLogoDark
              }
              alt="Lime Financial"
              width={74}
              height={24}
              quality={100}
            />
          </Button>

          <SelectWorkspace />
          <Typography fontSize={10} variant="caption" sx={{ pr: 1 }}>
            {version ? `v${version}` : ""}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {server_name ? (
            <Chip
              label={server_name}
              sx={{
                height: "28px",
                borderRadius: 0,
                color: theme.palette.warning.main,
              }}
            />
          ) : null}

          <Button
            disableTouchRipple
            size="small"
            color="inherit"
            onClick={showFeedback}
            sx={{
              borderRadius: 0,
              height: 28,
              padding: "0 14px",
              textTransform: "none",
              background: alpha(theme.palette.info.main, 0.15),
            }}
          >
            <Typography color="info.main" variant="caption">
              Feedback
            </Typography>
          </Button>
          <Button
            disableTouchRipple
            size="small"
            color="inherit"
            onClick={copyToken}
            sx={{
              borderRadius: 0,
              height: 28,
              padding: "0 14px",
              textTransform: "none",
              background: alpha(theme.palette.info.main, 0.15),
            }}
          >
            <Typography color="info.main" variant="caption">
              Copy Token
            </Typography>
          </Button>
          {publicRuntimeConfig.enable_cancel ? (
            <Button
              id="btn-username"
              disableTouchRipple
              size="small"
              color="inherit"
              onClick={showMassCancel}
              sx={{
                borderRadius: 0,
                height: 28,
                padding: "0 14px",
                textTransform: "none",
                background: alpha(theme.palette.error.main, 0.15),
              }}
            >
              <Typography color="error.main" variant="caption">
                Mass Cancel
              </Typography>
            </Button>
          ) : null}
          <Button
            disableTouchRipple
            size="small"
            color="inherit"
            onClick={handleOpenUserMenu}
            endIcon={
              Boolean(anchorElUser) ? <ExpandLessIcon /> : <ExpandMoreIcon />
            }
            sx={{
              borderRadius: 0,
              height: 28,
              paddingLeft: "14px",
              textTransform: "none",
              background: alpha(theme.palette.success.main, 0.15),
            }}
          >
            <Typography color="success.main" variant="caption" id="username">
              {user?.nickname || ""}
            </Typography>
          </Button>
        </Box>
        <Menu
          sx={{ marginLeft: "16px", top: 12 }}
          MenuListProps={{
            sx: { paddingTop: 0.3, paddingBottom: 0.3 },
          }}
          PaperProps={{
            elevation: 2,
            sx: { borderRadius: 0 },
          }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem
            disableRipple
            key={theme.palette.mode}
            onClick={toggleColorMode}
          >
            <Switch
              sx={{ transform: "scale(0.8)" }}
              checked={theme.palette.mode === "light" ? false : true}
              color="success"
              size="small"
              aria-label={theme.palette.mode}
            />
            <Typography variant="caption">
              {theme.palette.mode.toUpperCase()}
            </Typography>
          </MenuItem>

          {user ? (
            <Box>
              {/* <DropdownItem
                name="Profile"
                icon={<PersonIcon fontSize="small" />}
                handleCloseUserMenu={handleCloseUserMenu}
              />
              <DropdownItem
                name="Account"
                icon={<ManageAccountsIcon fontSize="small" />}
                handleCloseUserMenu={handleCloseUserMenu}
              /> */}
              {isEmployee ? (
                <MenuItem
                  disableRipple
                  key={theme.palette.mode}
                  href="/json-editor"
                  target="_blank"
                  component="a"
                >
                  <DataObjectRounded />
                  <Typography
                    sx={{ ml: 1 }}
                    variant="caption"
                    textAlign="center"
                  >
                    JSON Editor
                  </Typography>
                </MenuItem>
              ) : null}
              <DropdownItem
                name="Logout"
                icon={<LogoutIcon fontSize="small" />}
                handleCloseUserMenu={handleCloseUserMenu}
                href="/api/auth/logout"
              />
            </Box>
          ) : (
            <Box>
              <DropdownItem
                name="Login"
                icon={<LoginIcon fontSize="small" />}
                handleCloseUserMenu={handleCloseUserMenu}
                href="/api/auth/login"
              />
            </Box>
          )}
        </Menu>
      </Box>
    </AppBar>
  );
}

CustomAppBar.displayName = "standalone:CustomAppBar";
export default CustomAppBar;
