import { useCallback } from "react";
import {
  Grid,
  Button,
  ButtonProps,
  Typography,
  useTheme,
  Divider,
  Box,
  ToggleButton,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { useRestore } from "../../hooks/useColumns";
import { ModalFactory } from "../modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DnDChip from "./DnDChip";
import {
  CloseRounded,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  useToggleVisibleColumn,
  useColumnsForDatagrid,
  useChangeColumns,
  useToggleAll,
  useColumnConfigs,
} from "../../hooks/useColumns";
import { SetOfColumnDefs } from "../../../types.d";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: 1,
  width: 750,
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  padding: "16px",
};

const ModalBtnProps: ButtonProps = {
  disableTouchRipple: true,
  sx: { textTransform: "none", mr: 0.2, mt: 1.5 },
  disableElevation: true,
  variant: "outlined",
  size: "small",
};

const CustomToggleBtn = ({ columnName, columnKey }) => {
  const [visible, toggle] = useToggleVisibleColumn(columnKey);

  return (
    <ToggleButton
      disableTouchRipple
      size="small"
      color="success"
      sx={{
        padding: "0 6px",
        margin: 0.6,
        borderRadius: 2,
        textTransform: "none",
      }}
      value={columnKey}
      selected={visible}
      onChange={toggle}
    >
      <Typography
        variant="caption"
        sx={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {visible ? (
          <RadioButtonChecked
            sx={{ transform: "scale(0.7)", p: 0, ml: -0.5 }}
            fontSize="small"
          />
        ) : (
          <RadioButtonUnchecked
            sx={{ transform: "scale(0.7)", p: 0, ml: -0.5 }}
            fontSize="small"
          />
        )}
        {columnName}
      </Typography>
    </ToggleButton>
  );
};

const [DragNDropColsModalProvider, useShowDragNDropColsModal] = ModalFactory(
  "dndColumnFilter",
  ({ hide }) => {
    const theme = useTheme();
    const themeMode = theme.palette.mode;

    const [allChecked, toggleAll] = useToggleAll();
    // const restore = useRestoreColumns();
    const change = useChangeColumns();
    const restore = useCallback(() => {
      change({
        type: "restore",
      });
    }, [change]);

    const defaultColumns: SetOfColumnDefs = useColumnConfigs().map(
      (x) => x.column
    );

    const columns = useColumnsForDatagrid(false);

    return (
      <Grid
        container
        justifyContent="space-between"
        sx={{
          ...style,
          background: themeMode === "light" ? "#fff" : "#181D1F",
        }}
      >
        <Grid item xs={6}>
          <Box sx={{ ml: 1, mb: 1 }}>
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{ color: themeMode === "dark" ? "#ddd" : "inherit" }}
            >
              Select/Unselect Columns
            </Typography>
          </Box>
          <Box sx={{ height: "424px", overflow: "auto" }}>
            {defaultColumns.map((c) => (
              <CustomToggleBtn
                key={c.rdgColumn.key}
                columnKey={c.rdgColumn.key}
                columnName={c.rdgColumn.name}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Divider orientation="vertical" sx={{ float: "left", mr: 1 }} />
          <Box
            sx={{
              ml: 1,
              mb: 1,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ ml: 1 }}>
              <Typography
                variant="body2"
                fontWeight={500}
                sx={{ color: themeMode === "dark" ? "#ddd" : "inherit" }}
              >
                Reorder column by grabbing elements
              </Typography>
            </Box>
            <IconButton
              id="btn-close-choose-column"
              disableTouchRipple
              size="small"
              color="error"
              sx={{ height: 16, width: 16 }}
              onClick={() => (hide ? hide() : null)}
            >
              <CloseRounded fontSize="small" />
            </IconButton>
          </Box>
          <Box sx={{ ml: 1, height: "400px", overflow: "auto" }}>
            {columns.map((c, index) => (
              <DnDChip key={c.key} index={index + 1} column={c} />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <Button {...ModalBtnProps} color="warning" onClick={restore} id="btn-restore-columns">
              Restore Defaults
            </Button>
            <FormControlLabel
              control={
                <Checkbox
                  id="checked-all-columns"
                  size="small"
                  sx={{ p: "0 4px" }}
                  icon={<RadioButtonUnchecked color="success" />}
                  checkedIcon={<CheckCircleIcon color="success" />}
                  color="success"
                  disableRipple
                  checked={allChecked}
                  name="Checked All"
                  onChange={(checked) => toggleAll()}
                />
              }
              label={
                <Typography variant="body2" color="success.main">
                  Checked All
                </Typography>
              }
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
);

export { DragNDropColsModalProvider, useShowDragNDropColsModal };
