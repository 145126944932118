import { useRouter } from "next/router";
import { SetOfColumnDefs } from "../../../types.d";
import { useSetArgs } from "../useQuery";

const useValidateUrl = () => {
  const setArgs = useSetArgs();
  const router = useRouter();
  const queryParamArr = Object.entries(router.query).filter(
    ([key, value]) => key !== "key"
  );

  return (columnSet: SetOfColumnDefs) => {
    if (queryParamArr.length > 0) {
      let convertArrToArgObj = {};
      // validate all query params are equal to column def keys
      queryParamArr.forEach(([key, value]) => {
        columnSet.forEach((c) => {
          if (value && c.rdgColumn.key === key) {
            if (Array.isArray(value)) {
              convertArrToArgObj[key] = value;
            } else {
              convertArrToArgObj[key] = [value];
            }
          }
        });
      });
      setArgs(convertArrToArgObj);
    }
  };
};

export default useValidateUrl;
