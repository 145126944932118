import {
  CloseRounded,
  CopyAllRounded,
  FullscreenRounded,
  FullscreenExitRounded,
  Edit,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Actions, BorderNode, TabNode, TabSetNode } from "flexlayout-react";
import { useCallback } from "react";

type TabBtnType = {
  node: TabNode;
};
type TabSetBtnType = {
  node: TabSetNode | BorderNode;
};

const EditNameButton = ({ node }: TabBtnType) => {
  const editName = useCallback(() => {
    const newName = prompt("Rename this tab", node.getName());
    if (newName) {
      node.getModel().doAction(Actions.renameTab(node.getId(), newName));
    }
  }, [node]);

  return (
    <Tooltip title="Rename tab">
      <IconButton
        disableTouchRipple
        size="small"
        sx={{ zIndex: 1000, p: 0.1 }}
        onClick={editName}
      >
        <Edit fontSize="small" sx={{ fontSize: 16 }} />
      </IconButton>
    </Tooltip>
  );
};

const CloseTabIconButton = ({ node }: TabBtnType) => (
  <Tooltip title="Close">
    <IconButton
      id={`btn-close-${node.getName().split(" ").join("-").toLowerCase()}-tab`}
      disableTouchRipple
      size="small"
      sx={{ zIndex: 1000, p: 0.1 }}
      onClick={() => {
        node.getModel().doAction(Actions.deleteTab(node.getId()));
      }}
    >
      <CloseRounded fontSize="small" sx={{ fontSize: 16 }} />
    </IconButton>
  </Tooltip>
);

const FullScreenButton = ({ node }: TabSetBtnType) => (
  <Tooltip title={node.isMaximized() ? "Exit Fullscreen" : "Fullscreen"}>
    <IconButton
      size="small"
      disableTouchRipple
      sx={{ borderRadius: 0 }}
      onClick={() => {
        node.getModel().doAction(Actions.maximizeToggle(node.getId()));
      }}
    >
      {node.isMaximized() ? (
        <FullscreenExitRounded fontSize="small" />
      ) : (
        <FullscreenRounded fontSize="small" />
      )}
    </IconButton>
  </Tooltip>
);

const CloseTabSetIconButton = ({ node }: TabSetBtnType) => (
  <Tooltip title="Close">
    <IconButton
      size="small"
      disableTouchRipple
      sx={{ borderRadius: 0 }}
      onClick={() => {
        node.getModel().doAction(Actions.deleteTabset(node.getId()));
      }}
    >
      <CloseRounded fontSize="small" />
    </IconButton>
  </Tooltip>
);

export {
  FullScreenButton,
  CloseTabIconButton,
  CloseTabSetIconButton,
  EditNameButton,
};
