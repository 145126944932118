import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useRef,
  LegacyRef,
  useEffect,
} from "react";
import { IconButton, TextField, useTheme } from "@mui/material";
import { useColDef, Choice } from "./utils";
import {
  DatePicker,
  LocalizationProvider,
  DateTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
  EventRounded,
} from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useArgumentField, useSetArgumentField } from "../../hooks/useQuery";
import moment from "moment";

export function CustomDateField() {
  const def = useColDef();

  const date = useArgumentField();
  const setDate = useSetArgumentField();

  var Component: typeof DatePicker | typeof DateTimePicker | typeof TimePicker;
  var displayFormatter: string;

  if (def.type === "date") {
    Component = DatePicker;
    displayFormatter = "MM/DD/YYYY";
  } else if (def.type === "datetime") {
    Component = DateTimePicker;
    displayFormatter = "MM/DD/YYYY HH:mm:ss";
  } else if (def.type === "time") {
    Component = TimePicker;
    displayFormatter = "HH:mm:ss";
  } else {
    throw "not compatible with this def";
  }

  if (!date) {
    displayFormatter = " ";
  }

  const handleDateChange = (date: any) => {
    setDate(moment(date).toISOString());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Component
        componentsProps={{
          actionBar: {
            actions: ["clear"],
          },
        }}
        disableFuture
        reduceAnimations={true}
        label="Date desktop"
        inputFormat={displayFormatter}
        dayOfWeekFormatter={(day) => day}
        value={date}
        onChange={handleDateChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            sx={{
              ".MuiInputBase-root": {
                borderRadius: "3px",
                // width: "115px",
              },
            }}
          />
        )}
        OpenPickerButtonProps={{
          disableRipple: true,
          sx: { pr: 1.5, pl: 0 },
        }}
        components={{
          SwitchViewButton: ({ children }) => {
            const viewType = children.props.ownerState.openView;
            return (
              <IconButton size="small" sx={{ p: 0 }} disableTouchRipple>
                {viewType === "day" ? (
                  <ArrowDropDownRounded />
                ) : (
                  <ArrowDropUpRounded />
                )}
              </IconButton>
            );
          },
          LeftArrowButton: (props) => (
            <IconButton
              {...props}
              disableTouchRipple
              sx={{ p: 0 }}
              size="small"
            >
              <ChevronLeftRounded />
            </IconButton>
          ),
          RightArrowButton: (props) => (
            <IconButton
              {...props}
              disableTouchRipple
              sx={{ p: 0 }}
              size="small"
            >
              <ChevronRightRounded />
            </IconButton>
          ),
          OpenPickerIcon: (props) => <EventRounded fontSize="small" />,
        }}
      />
    </LocalizationProvider>
  );
}
