import React, {
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";
import { CreateStateContext, StateProviderProps } from "./stateContext";

export type VoidFunc = () => void;

/**
 * Creates a context that is able to call multiple hooks in different
 * components. Returns a Provider, registerHook, runAllHook. Use the
 * register hook to add a function to the run group, then call the callback
 * from the useRunAll hook to run all the registered functions
 */
export function CreateRunnerGroup(
  friendlyName: string
): [
  (
    props: React.PropsWithChildren<StateProviderProps<Record<string, VoidFunc>>>
  ) => JSX.Element,
  (runner: VoidFunc) => void,
  () => VoidFunc
] {
  const [RunnerProvider, useRunners, useSetRunners] = CreateStateContext<
    Record<string, VoidFunc>
  >(friendlyName, {}, false);
  const PresenceContext = createContext(false);

  function useRegisterRunner(runner: VoidFunc) {
    const set = useSetRunners();
    const run = useCallback(runner, [runner]);

    useEffect(() => {
      const k = `${Math.random()}`;
      set((prev) => ({
        ...prev,
        [k]: run,
      }));

      return () => {
        set((prev) => {
          if (prev) {
            delete prev[k];
          }
          return prev;
        });
      };
    }, [set, run]);
  }

  function useRunAll() {
    const runners = useRunners();

    return useCallback(() => {
      if (runners) {
        Object.values(runners).forEach((run) => run());
      }
    }, [runners]);
  }

  return [RunnerProvider, useRegisterRunner, useRunAll];
}
