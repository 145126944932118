import { useCallback, useMemo } from "react";
import { CSVLink } from "react-csv";
import { ColumnContentType } from "../types.d";
import { useResult } from "../components/hooks/useQuery";
import { writeFile, utils as excelUtils } from "xlsx";
import { useColumnsForDatagrid } from "../components/hooks/useColumns";
import { useThisNode } from "../components/windows/thisNode";
import moment from "moment";

const DateTimeKeys = ["recorded", "timestamp"]

const getFileName = () => {
  const date = new Date();
  const format = "MM-DD-YYYY_HH-mm-ss";
  return moment(date).format(format);
};

const useGetGridDataToExport = () => {
  const rows = useResult();
  const columns = useColumnsForDatagrid(false);
  
  const getFormatedDateTime = (dt: string) => {
    const milisec = dt.split(".")[1];
    return moment(dt).format("YYYY-MM-DD HH:mm:ss:") + milisec.substring(0, milisec.length - 1)
  }

  return useMemo(() => {
    let newList = new Array<Object>();
    rows.forEach((row: ColumnContentType) => {
      let newMap = new Object()
      columns.forEach(c => {
        newMap = {
          ...newMap,
          [c.name as string]: DateTimeKeys.includes(c.key) ? getFormatedDateTime(row[c.key] as string) : row[c.key]
        }
      })
      newList.push(newMap)
    })

    return newList;
  }, [columns, rows]);
}

const ExportToExcel = () => {
  const data = useGetGridDataToExport();

  const handleClick = useCallback(() => {
    const workBook = excelUtils.book_new();
    const workSheet = excelUtils.json_to_sheet(data);
    excelUtils.book_append_sheet(workBook, workSheet, "Sheet1");
    writeFile(workBook, `${getFileName()}.xlsx`);
  }, [data]);

  return <span onClick={handleClick}>Export as XLSX</span>;
};

const ExportToCSV = () => {
  const data = useGetGridDataToExport();

  return (
    <CSVLink
      data={data}
      filename={getFileName()}
      style={{ textDecoration: "none" }}
    >
      Export as CSV
    </CSVLink>
  );
};

export { ExportToCSV, ExportToExcel };
