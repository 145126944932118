import React, { useState, useCallback, useEffect } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Button,
  Modal,
  Typography,
  LinearProgress,
  FilledInput,
} from "@mui/material";

const CONFIRMATION_STRING = "cancel";

type ConfirmationProps = {
  onConfirmed: () => void;
};

export function Confirmation(props: ConfirmationProps) {
  const [confirmation, setConfirmation] = useState<string>();

  const confirmed = confirmation === CONFIRMATION_STRING;

  useEffect(() => {
    if (confirmed) {
      props.onConfirmed();
    }
  }, [confirmed, props]);

  const handleConfirmationChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        setConfirmation(e.target.value.toLowerCase());
      },
      [setConfirmation]
    );

  return (
    <>
      <FilledInput
        sx={{
          borderRadius: "5px",
        }}
        value={confirmation}
        error={confirmation !== CONFIRMATION_STRING}
        onChange={handleConfirmationChange}
        placeholder="Please type 'cancel' to cancel these orders"
        fullWidth
        disabled={confirmed}
      />
    </>
  );
}
