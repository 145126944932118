/* eslint-disable react/display-name */
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { VariableSizeList, ListChildComponentProps } from "react-window";

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const inlineStyle = {
    ...style,
    top: style.top as number,
  };
  return (
    <Typography noWrap style={inlineStyle}>
      {data[index]}
    </Typography>
  );
}

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactNode[] = [];
  (children as React.ReactNode[]).forEach((item: React.ReactNode) => {
    itemData.push(item);
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 28 : 20;

  const getChildSize = (child: React.ReactNode) => {
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          ref={gridRef}
          height={getHeight()}
          width="100%"
          itemData={itemData}
          itemCount={itemCount}
          itemSize={(index) => getChildSize(itemData[index])}
          outerElementType={OuterElementType}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.displayName = "standalone:ListboxComponent";
export default ListboxComponent;
