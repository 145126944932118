import moment, { Moment } from "moment";
import { useEffect, useRef } from "react";
import { useFetchThisGrid } from "../../body/grid/CustomDataGrid";
import { CreateStateContext } from "../stateContext";
import { useAutoRefreshData } from "./useAutoRefresh";

type DateTimeContextType = {
  defaultDateTimeLabel: string | null;
  startDate: Moment;
  endDate: Moment;
};

const INCREMENT_TIME_LABEL = [
  "Last 1 Minute",
  "Last 5 Minute",
  "Last 15 Minute",
];

export const [defaultStartDate, defaultEndDate] = [4, 20].map((hour: number) =>
  moment().hour(hour).minute(0).second(0).millisecond(0)
);

const [DateTimeRPProvider, useDateTimeRPData, useSetDateTimeRPData] =
  CreateStateContext<DateTimeContextType>(
    "datetime-local",
    {
      defaultDateTimeLabel: "Today",
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    },
    true
  );

const DateTimeRangePicker = () => {
  const doFetch = useFetchThisGrid();
  const autorefresh = useAutoRefreshData();
  const { defaultDateTimeLabel, startDate, endDate } = useDateTimeRPData();
  const setDateTimeRPData = useSetDateTimeRPData();

  // autorefresh code
  useEffect(() => {
    const inter = setInterval(() => {
      if (
        defaultDateTimeLabel &&
        INCREMENT_TIME_LABEL.includes(defaultDateTimeLabel)
      ) {
        setDateTimeRPData((prev) => {
          if (prev) {
            return {
              ...prev,
              startDate: moment(startDate).add(
                autorefresh.seconds,
                "millisecond"
              ),
              endDate: moment(endDate).add(autorefresh.seconds, "millisecond"),
            };
          }
        });
      }
      if (autorefresh.active) {
        doFetch();
      }
    }, autorefresh.seconds);
    return () => clearInterval(inter);
  }, [
    autorefresh.seconds,
    autorefresh.active,
    defaultDateTimeLabel,
    doFetch,
    endDate,
    setDateTimeRPData,
    startDate,
  ]);

  return <></>;
};

const DateTimeRangePickerProvider = (props: React.PropsWithChildren) => {
  return (
    <DateTimeRPProvider>
      <DateTimeRangePicker />
      {props.children}
    </DateTimeRPProvider>
  );
};

export { DateTimeRangePickerProvider, useDateTimeRPData, useSetDateTimeRPData };
