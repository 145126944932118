import { CreateStateContext } from "./stateContext";
import { useEffect } from "react";

const [TokenProvider, useToken, useSetToken] =
  CreateStateContext<string>("token");

export function TokenLoader(props: { token: string }) {
  const set = useSetToken();
  useEffect(() => {
    if (set) {
      set(props.token);
    } else {
      throw "no token context";
    }
  }, [props.token, set]);
  return <></>;
}

export { TokenProvider, useToken, useSetToken };
