import { OpenInNewRounded } from "@mui/icons-material";
import { Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { Actions } from "flexlayout-react";
import { useState } from "react";
import { fieldBorderRadius } from "../../styles/globalVar";

const TabContextMenu = (props) => {
  const theme = useTheme();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };

  const handleOnClick = () => {
    localStorage.setItem(`@${props.node.getId()}:popout`, "true");
    console.log("Set", `@${props.node.getId()}:popout`, true);
    window.open(
      `/${props.node.getComponent()}/${props.node.getId()}`,
      `${props.node.getName()}-${props.node.getId()}`,
      "width=400,height=300,left=100,top:100"
    );
    props.node.getModel().doAction(Actions.deleteTab(props.node.getId()));
  };

  const clsName = `tab-${props.node.getName().split().join("-").toLowerCase()}`

  return (
    <div onContextMenu={handleContextMenu} className={clsName}>
      {props.node.getName()}

      <Menu
        disableAutoFocus
        open={contextMenu !== null}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            padding: 0,
            background: theme.palette.background.paper,
          },
        }}
        PaperProps={{
          elevation: 2,
          sx: {
            borderRadius: fieldBorderRadius,
          },
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={handleOnClick}
          disableTouchRipple
          sx={{ padding: "4px 8px" }}
        >
          <OpenInNewRounded sx={{ fontSize: 12 }} />
          &nbsp;
          <Typography variant="caption">Open in new window</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TabContextMenu;
