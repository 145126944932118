import { ColumnContentType } from "../../../../types.d";
import CustomRowRenderer from "../CustomRowRenderer";
import DataGrid from "react-data-grid";
import { Typography, useTheme } from "@mui/material";
import { SetOfColumnsForMRTradeDetails } from "../../../hooks/columndefs";

interface TradeDetailsProps {
  data: ColumnContentType[];
}

function MDTradeDetails({ data }: TradeDetailsProps) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;

  const cols = SetOfColumnsForMRTradeDetails.map((x) => x.rdgColumn);

  const columns = cols.map((c) => {
    return {
      name: c.name,
      key: c.key,
      minWidth: c.minWidth,
      formatter: c.formatter,
      headerRenderer: (props) => (
        <Typography
          sx={{ pl: 1, pt: 0.2, display: "flex" }}
          variant="caption"
          fontWeight={500}
        >
          {c.name}
        </Typography>
      ),
    };
  });

  return (
    <DataGrid
      rowHeight={24}
      headerRowHeight={22}
      className={`rdg-${themeMode}`}
      columns={columns}
      rows={data}
      defaultColumnOptions={{
        resizable: true,
      }}
      style={{
        fontSize: "0.85rem",
      }}
      enableVirtualization={true}
      renderers={{
        rowRenderer: (key, props) => <CustomRowRenderer key={key} {...props} />,
      }}
    />
  );
}

MDTradeDetails.displayName = "standalone:MDTradeDetails";

export default MDTradeDetails;
