import {Button} from "@mui/material";
import {useCallback} from "react";
import {useResetArgs} from "../../hooks/useQuery";

export function ClearButton(props: {onClear: () => void}) {
  const reset = useResetArgs();
  const handleClear = useCallback(() => {
    reset();
    props.onClear();
  }, [reset, props]);

  // useEffect(() => handleClear(), [handleClear]);

  return (
    <Button variant="contained" color="info" onClick={handleClear}>
      Clear
    </Button>
  );
}
