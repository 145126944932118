import { ColumnDef } from "../../../types.d";
import { ArgumentFence } from "../../hooks/useQuery";

import { ColDefContext } from "./utils";
import { CustomTextField } from "./CustomTextField";
import { CustomNumberField, CustomNumberFieldProps } from "./CustomNumberField";
import { CustomChoiceField, CustomChoiceFieldProps } from "./CustomChoiceField";
import { CustomDateField } from "./CustomDateField";
import { CustomBooleanField } from "./CustomBooleanField";

type InputFieldProps = {
  columnDefinition: Required<ColumnDef>;
} & CustomNumberFieldProps &
  CustomChoiceFieldProps;

export function CustomInputField(props: InputFieldProps) {
  if (props.columnDefinition.enableHeaderFilter) {
    return (
      <ArgumentFence field={props.columnDefinition.rdgColumn.key}>
        <ColDefContext.Provider value={props.columnDefinition}>
          {props.columnDefinition.type === "text" ? <CustomTextField /> : null}
          {props.columnDefinition.type === "number" ? (
            <CustomNumberField {...props} />
          ) : null}
          {props.columnDefinition.type === "money" ? (
            <CustomNumberField {...props} />
          ) : null}
          {props.columnDefinition.type === "choice" ? (
            <CustomChoiceField {...props} />
          ) : null}
          {props.columnDefinition.type === "autocomplete" ? (
            <CustomChoiceField {...props} />
          ) : null}
          {props.columnDefinition.type === "datetime" ? (
            <CustomDateField />
          ) : null}
          {props.columnDefinition.type === "boolean" ? (
            <CustomBooleanField />
          ) : null}
          {props.columnDefinition.type === "date" ? <CustomDateField /> : null}
          {props.columnDefinition.type === "time" ? <CustomDateField /> : null}
        </ColDefContext.Provider>
      </ArgumentFence>
    );
  } else {
    return <></>;
  }
}
