export enum AccountField {
    ACCOUNT = "account",
    ID = "id",
    LEGAL_ENTITY = "legal_entity",
    CLEARING_FIRM = "clearing_firm",
    CLEARING_TYPE = "clearing_type",
    SALESPERSON = "salesperson",
    REFERRAL = "referral",
    ALGO_SERVICE_PROVIDER = "algo_service_provider",
    BUYING_POWER_GROUP = "buying_power_group",
    CLEARING_GROUP = "clearing_group",
    TRADING_DOMAIN = "trading_domain",
    CLUSTER = "cluster",
    TYPE = "type",
    FEE_GROUP = "fee_group",
    MPID = "mpid",
    CLEARING_ID = "clearing_id",
    GIVEUP = "giveup",
    CMTA = "cmta",
    REPORTING_DOMAIN = "reporting_domain",
    PARITY_GROUP = "parity_group",
    ENABLED = "enabled",
    ISO_ALLOWED = "iso_allowed",
    DATACENTER = "datacenter",
    CLIENT_ENTITY = "client_entity",
    PRIMARY_HOSTNAME = "primary_hostname",
    CLIENT_ENTITY_NAME = "client_entity_name"
  }

  export const getAccountFieldCSV = (selectedFieldsSet: Set<AccountField>): string => {
    if (selectedFieldsSet.size === 0) {
      return AccountField.ACCOUNT;
    }
  
    const orderedSelectedFields = Object.values(AccountField)
      .filter(field => selectedFieldsSet.has(field));
  
    return orderedSelectedFields.join(",");
  };
  
  